<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo"> Idioma</h2>
          <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
              
                <div class="col-lg-12">

                    <div class="row">
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Idioma<strong style="color:red">*</strong></label>
                            <select class="form-control mb-3 input-select" id="selectIdioma"
                                 v-model="idioma.idioma"  :state="stateValida.idioma"
                                >
                                <option value="0"></option>
                                <option  v-for="idioma in itemsTipoIdioma"
                                    :key="idioma.id" :value="idioma.id">{{
                                        idioma.nome
                                    }}</option>
                            </select>
                            <div class="invalid-feedback">Campo idioma é obrigatório!</div>
                        </div>
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Nível do idioma<strong style="color:red">*</strong></label>
                            <select class="form-control mb-3 input-select" id="selectNivelIdioma" 
                                v-model="idioma.dominio"  :state="stateValida.dominio"
                                >
                                <option value="0"></option>
                                <option v-for="nivelIdioma in itemsNivelIdioma" :key="nivelIdioma.id" :value="nivelIdioma.id">{{
                                    nivelIdioma.nome
                                }}</option>
                            </select>
                        </div>                      
                        
                    </div>
                </div>
            </div>
            <fieldset class="fieldset " >   
                <div class="row">
                    <div class="btn-group" role="group">


                        <div class="container-buttons-salvar">
                            <b-button @click="adicionarIdioma" variant="outline-success" id="btn-salvar">Salvar</b-button>
                        </div>

                        &nbsp;
                        <div class="container-buttons-salvar">

                            <b-button variant="outline-success" @click="limparObjetoIdioma()">Novo</b-button>

                        </div>
                        &nbsp;
                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </fieldset>
            
        </div>
        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas de Idioma</h2>
                    
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Idioma</th>
                                <th>Domínio</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr v-for="item in itemsIdioma" :key="item.id">
                                <td>{{ item.idioma }}</td>
                                <td>{{ item.dominio }}</td>

                                <td>
                                    
                                    <div>
                                       
                                        <b-button type="button" variant="outline-success" 
                                        @click="idioma = JSON.parse(JSON.stringify(item)), editarIdioma(item)">

                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import { constanteService } from "@/service/constanteService";
import { idiomaService } from "@/service/idiomaService";

export default {
    data() {
        return {

            
            usuarioPagina: {},
            usuarioLogado: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
          
           
            alert: {
                mensagem: "",
                variant: ""
            },
            stateValida: {
                idioma: null,
                dominio: null,
            },

            idioma:{
                idioma:"",
                dominio:"",
                idLogado:"",
                usuario:""
            },

            itemsTipoIdioma: [],
            itemsNivelIdioma: [],
            itemsIdioma:[],
            validado:"",
            
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            await this.verificaLocalStore();
            this.listarIdioma();
            this.listarNivelIdioma();
            this.listarTipoIdioma()
        },
       
        
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        validaCampo() {

            if (this.idioma.idioma) {
                this.stateValida.idioma = true;
                this.validado =  true;
            }
            else if (!this.idioma.idioma) {
                this.stateValida.idioma = false;
                this.validado =  false;
            }
            if (this.idioma.dominio) {
                this.stateValida.dominio = true;
                this.validado =  true;
            }
            else if (!this.idioma.dominio) {
                this.stateValida.dominio = false;
                this.validado =  false;
            }
            return  this.validado;
            
        },
      
        adicionarIdioma() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarIdioma();
            } else {

            this.idioma.idLogado = this.usuarioLogado.id;
            this.idioma.usuario = this.usuarioPagina.id;        

                    idiomaService
                        .salvarIdioma(this.idioma)
                        .then((res) => {
                            console.log(res);                           
                            
                            this.listarIdioma();
                          

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                        })
                        .catch((erro) => {
                            console.log(erro);
                        });
                    }
        },
        listarTipoIdioma() {         
            
            constanteService
                .listarTipoIdioma().then((res) => {
                    this.itemsTipoIdioma = res;
                    
                });
        },
        listarNivelIdioma() {         
            
            constanteService
                .listarNivelIdioma().then((res) => {
                    this.itemsNivelIdioma = res;
                    console.log("nivel idioma é", this.itemsNivelIdioma)
                });
        },
        listarIdioma() {
            let idUsuario = this.usuarioPagina.id;
            idiomaService
                .listarIdioma(idUsuario).then((res) => {
                    this.itemsIdioma = res;
                    console.log(res)
                });
        },
        editarIdioma(item){
            this.idioma.idioma = item.idioma;
            this.idioma.dominio = item.dominio;
            document.getElementById("btn-salvar").textContent = "Alterar"; 
            
        },
        limparObjetoIdioma(){
            this.idioma.idioma = "";
            this.idioma.dominio = "";
        },
        alterarIdioma() {
            this.alert = {};

          

            if (this.validaCampo() == true) {
            
                this.idioma.idLogado = this.usuarioLogado.id;
                this.idioma.usuario = this.usuarioPagina.id;   
               

                idiomaService
                    .alterarIdioma(this.idioma.id, this.idioma)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                        this.listarIdioma();
                    })
                    .catch(() => {

                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

    },
}

</script>
 <style scoped>



</style> 
