<template>
  <div class="container">
    <div class="card">
      <h2 class="card__titulo">Preceptoria</h2>
      <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class=" col-lg-6 ">
          <label for="nome" class="form-label">Curso<strong style="color:red">*</strong></label>
        
          <b-form-input type="text" class="form-control mb-3" required id="nome" v-model="preceptoria.curso"
            :state="stateValida.curso"></b-form-input>
          <div class="invalid-feedback">Campo Curso é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="nomSocial" class="form-label">Tipo de Trabalho: <strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control mb-3" id="nomSocial" v-model.trim="preceptoria.tipoTrabalho"
            :state="stateValida.tipoTrabalho"></b-form-input>
          <div class="invalid-feedback">Campo Tipo de Trabalho é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="cpf">Carga Horária<strong style="color:red">*</strong></label>
        
          <b-form-input type="text" class="form-control mb-3" id="cargaHoraria" v-model="preceptoria.cargaHoraria"
            :state="stateValida.cargaHoraria">
          </b-form-input>
          <div class="invalid-feedback">Campo Carga Horária é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="rg">Quantidade de Alunos<strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control mb-3" id="quantAlunos" v-model="preceptoria.quantAlunos"
            :state="stateValida.quantAlunos">
          </b-form-input>
          <div class="invalid-feedback">Campo Quantidade de Alunos é obrigatório!</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Início<strong style="color:red">*</strong></label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="preceptoria.inicio"
            :state="stateValida.inicio">
          </b-form-input>
          <div class="invalid-feedback">Campo Início é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="staticEmail2">Término</label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="preceptoria.termino">
          </b-form-input>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Área de Formação<strong style="color:red">*</strong></label>

        
          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.areaFormacao"
            v-model="preceptoria.areaFormacao">
            <option value="0"></option>
            <option v-for="area in itemAreaFormacao" :key="area.id" :value="area.id">{{
            area.nome
            }}

            </option>
          </select>
          <div class="invalid-feedback">Campo Área de Formação é obrigatório!</div>
        </div>

        <div class="col-lg-6 ">
          <label for="staticEmail2">Tipo<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.tipo" v-model="preceptoria.tipo">
            <option value="0"></option>
            <option v-for="tipoCurso in itemTipo" :key="tipoCurso.id" :value="tipoCurso.id">{{
            tipoCurso.nome
            }}

            </option>
          </select>

        
          <div class="invalid-feedback">Campo Tipo é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="staticEmail2">Modalidade<strong style="color:red">*</strong></label>
        
          <select class="form-control mb-3 input-select" id="modalidade" :state="stateValida.modalidade"
            v-model="preceptoria.modalidade">
            <option value="0"></option>
            <option v-for="mod in itemModalidade" :key="mod.id" :value="mod.id">{{
            mod.nome
            }}

            </option>
          </select>
          <div class="invalid-feedback">Campo Modalidade é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="input-live">Equipe<strong style="color:red">*</strong></label>
          <multiselect class="mb-3" id="refEquipe" v-model="equipe" :state="stateValida.equipe" :options="itemEquipe"
            :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
            placeholder="Informe Id, Nome ou Cpf do usuário" :show-no-results="false" :hide-selected="true"
            label="nomeSocial" track-by="nomeSocial" :preselect-first="true" :searchable="true" :loading="isLoading"
            :internal-search="false" @search-change="buscarUsuario">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Usuário(s) Selecionado(s)</span></template>
            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </multiselect>
          
        </div>
      </div>
      <fieldset class="row">
        <div class="col-md-4">
          <label for="input-live">Agravo<strong style="color:red">*</strong></label>
          <div>
            <treeselect v-model="preceptoria.agravos" :multiple="true" :options="itemAgravo" :state="stateValida.agravos" />
          </div>
        </div>
        
        <div class="col-lg-4 ">
          <label for="curriculo">Natureza</label>
          <!--  <input type="text" class="form-control mb-3" id="curriculo" v-model="preceptoria.naturezaOutras"> -->
          <select class="form-control mb-3 input-select" id="natureza" :state="stateValida.natureza"
            v-on:change="mostraCampoNaturezaOutras()" v-model="preceptoria.natureza">
            <option value="0"></option>
            <option v-for="nat in itemNatureza" :key="nat.id" :value="nat.id">{{
            nat.nome
            }}

            </option>
          </select>
        </div>
        <div class="col-lg-4" v-if="mostraNaturezaOutras">
          <label for="emailPessoal">Natureza Outras</label>
          <input type="text" class="form-control mb-3" id="emailPessoal" v-model="preceptoria.naturezaOutras">
        </div>


      </fieldset>

      <fieldset class="row">

     


        <div class="col-lg-4">
          <label for="input-live">Orientação<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.orientacao"
            v-on:change="mostraCampoOrientacaoOutras()" v-model="preceptoria.orientacao">
            <option value="0"></option>
            <option v-for="ort in itemOrientacao" :key="ort.id" :value="ort.id">{{
            ort.nome
            }}

            </option>
          </select>
        </div>
        <div class="col-lg-3" v-if="mostraOrientacaoOutras">
          <label for="curriculo">Orientação Outras<strong style="color:red">*</strong></label>
          <input type="text" class="form-control mb-3" id="orientacaoOutras" v-model="preceptoria.orientacaoOutras"
            :state="stateValida.orientacaoOutras">
        </div>
        
        <div class="col-lg-3">
          <label for="input-live">Instituição<strong style="color:red">*</strong></label>
          
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.instituicao"
            v-model="preceptoria.instituicao">
            <option value="0"></option>
            <option v-for="inst in itemInstituicao" :key="inst.id" :value="inst.id">{{
            inst.nome
            }}

            </option>

          </select>
        </div>
        <div class="col-lg-3">
          <label for="input-live">Situação<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.situacao"
            v-model="preceptoria.situacao">
            <option value="0"></option>
            <option v-for="sit in itemSituacaoPreceptoria" :key="sit.id" :value="sit.id">{{
            sit.nome
            }}

            </option>

          </select>
        </div>

        <div class="col-lg-3 ">
          <label for="processoSei">Processo SEI</label>
          <input type="text" class="form-control mb-3" id="processoSei" v-model="preceptoria.processoSEI">
        </div>
        <div class="col-lg-3 ">
          <label for="urlSei">UrlSEI</label>
          <input type="text" class="form-control mb-3" id="urlSei" v-model="preceptoria.urlSEI">
        </div>
      </fieldset>
      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="validaPreceptoria" variant="outline-success" id="btn-salvar">Salvar
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="limparObjetoPreceptoria" variant="outline-success" id="btn-salvar">Novo
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <router-link to="/inicio">
              <b-button variant="outline-success">Voltar</b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabela de visitas -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h2 class="card__titulo"> Lista de Preceptoria</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Curso</th>
                <th>Carga Horária</th>
                <th>Data Início</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="precep in itemPreceptoria" :key="precep.id">

                <td>{{ precep.curso }}</td>
                <td>{{ precep.cargaHoraria }}</td>
                <td>{{ precep.inicio }}</td>
                <td>
                  <div>
                    <b-button type="button" variant="outline-success"
                      @click="preceptoria = JSON.parse(JSON.stringify(precep)), editarPreceptoria(precep)">Editar
                    </b-button>
                  </div>
                </td>
              </tr>

            </tbody>

          </table>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import { constanteService } from "@/service/constanteService";
import { preceptoriaService } from "@/service/preceptoriaService";
import { agravoService } from "@/service/agravoService";
import { usuarioService } from "@/service/usuarioService";
import { instituicaoService } from "@/service/instituicaoService";
import { mask } from "vue-the-mask";
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  directives: { mask },
  data() {
    return {

      alert: {
        mensagem: "",
        variant: ""
      },
      dismissCountDown: 0,
      dismissSecs: 10,
      usuarioLogado: {},
      usuarioPagina: {},
      preceptoriaSelecionado: {},
      agravos: [],
      itemPreceptoria: [],
      itemModalidade: [],
      itemInstituicao: [],
      itemAreaFormacao: [],
      itemTipo: [],
      itemSituacaoPreceptoria: [],
      itemNatureza: [],
      
      itemOrientacao: [],
      itemAgravo: [],
      mostraOrientacaoOutras: "",
      mostraNaturezaOutras: "",
      preceptoriaClonada: {},
      idPreceptoriaClonado: "",
      equipe: [],
      itemEquipe: [],
      isLoading: false,
      preceptoria: {
        curso: "",
        inicio: "",
        termino: "",
        cargaHoraria: "",
        quantAlunos: "",
        tipoTrabalho: "",
        equipe: [],
        agravos: [],
        areaFormacao: "",
        tipo: "",
        situacao: "",
        natureza: "",
        naturezaOutras: "",
        orientacao: "",
        orientacaoOutras: "",
        modalidade: "",
        instituicao: "",
        processoSEI:"",
        urlSEI:"",
        idLogado: "",

      },

      stateValida: {
        curso: null,
        inicio: null,
        termino: null,
        cargaHoraria: null,
        quantAlunos: null,
        tipoTrabalho: null,
        equipe: null,
        agravos: null,
        areaFormacao: null,
        tipo: null,
        situacao: null,
        natureza: null,
        naturezaOutras: null,
        orientacao: null,
        orientacaoOutras: null,
        modalidade: null,
        instituicao: null,
      },
    }
  },
  components: {
    Multiselect,
    Treeselect
  },
  mounted() {
    this.inicializar();
  },
  methods: {
    validaPreceptoria(){
      if(this.preceptoria.orientacao == "OUTRAS" && this.preceptoria.orientacaoOutras == null ){
        this.alert.mensagem = "Atenção! Campo Orientação Outras é obrigatório.";
        this.alert.variant = "danger";
        this.showAlert();
       
               
      } if (this.preceptoria.natureza == "OUTRAS" && this.preceptoria.naturezaOutras == null ) {
        this.alert.mensagem = "Atenção! Campo Natureza Outras é obrigatório.";
        this.alert.variant = "danger";
        this.showAlert();
      } else {
        this.salvarPreceptoria();
      }

    },
    limparObjetoPreceptoria() {
      this.alerta = {};
      this.idPreceptoriaClonado = "";

      this.preceptoriaClonada = {}

      setTimeout(() => {
        document.getElementById("btn-salvar").textContent = "Salvar"

      }, 200)

      this.preceptoria = {};

    },
    formataObjeto(array) {
      var listaIdLabel = [];
      for (let i = 0; i < array.length; i++) {
        let idLabel = {}
        idLabel.id = array[i].id;
        idLabel.label = array[i].nome;
        listaIdLabel.push(idLabel);
      }
      console.log("Lista de Ids e Labels", listaIdLabel)
      return listaIdLabel;
    },

    async buscarUsuario() {
      this.isLoading = true;
      let palavraChave = await document.getElementById("refEquipe").value;
      console.log("campo", palavraChave)
      usuarioService
        .buscarUsuarioSimples(palavraChave, this.usuarioLogado.id).then((res) => {
          this.itemEquipe = [res];
          console.log("Usuario Pesquisado", this.itemEquipe)
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        });
    },
    formataMultiSelect(selecionados, listaTotal) {
      let resultado = [];
      for (let i = 0; i < listaTotal.length; i++) {
        for (let j = 0; j < selecionados.length; j++) {
          if (selecionados[j] == listaTotal[i].id)
            resultado.push(listaTotal[i]);
        }
      }
      console.log("Resultado", resultado)
      return resultado;

    },
    async editarPreceptoria(preceptoria) {
      this.alerta = {};
      this.preceptoria = JSON.parse(JSON.stringify(preceptoria))
      this.equipe = await this.formataMultiSelect(this.preceptoria.equipe, this.preceptoria.usuarios);
      this.agravos = await this.formataMultiSelect(this.preceptoria.agravos, this.itemAgravo);


      document.getElementById("btn-salvar").textContent = "Alterar"

      this.preceptoria.inicio = await this.inverterCampoData(preceptoria.inicio);
      this.preceptoria.termino = await this.inverterCampoData(preceptoria.termino);
      this.preceptoria.instituicao = preceptoria.instituicao;


      this.idPreceptoriaClonado = "";
      this.preceptoriaClonada = {};
    },
    async alterarPreceptoria() {
      this.alert = {};
      this.preceptoriaClonada.inicio = this.inverterCampoData(this.preceptoria.inicio);
      this.preceptoriaClonada.termino = this.inverterCampoData(this.preceptoria.termino);

      for (let index = 0; index < this.itemPreceptoria.length; index++) {

        if (this.preceptoriaClonada.curso === this.itemPreceptoria[index].curso) {
          this.idPreceptoriaClonado = this.itemPreceptoria[index].id

          break;
        }


      }


      if (this.idPreceptoriaClonado) {
        this.preceptoria.id = this.idPreceptoriaClonado;

      }

      if (this.validaCampo() == true) {
        // this.gerencia.diretoria = 1
        // como só existe uma diretoria, o idDiretoria colocamos na mão -> this.usuarioPagina.vinculos[0].setor.lotacao.diretoria; 

        this.preceptoria.idLogado = this.usuarioLogado.id
        this.preceptoria.equipe = await this.pegaId(this.equipe);


        preceptoriaService
          .alterarPreceptoria(this.preceptoria.id, this.preceptoria)
          .then((res) => {

            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert()
            this.listarPreceptoria();
          })
          .catch(() => {

          });
      } else {
        this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
        this.alert.variant = "danger";
        this.showAlert();
      }
    },

    mostraCampoNaturezaOutras() {
      if (this.preceptoria.natureza == "OUTRAS") {
        this.mostraNaturezaOutras = true;
      } else {
        this.mostraNaturezaOutras = false;
      }

    },
    mostraCampoOrientacaoOutras() {
      if (this.preceptoria.orientacao == "OUTRAS") {
        this.mostraOrientacaoOutras = true;
      } else {
        this.mostraOrientacaoOutras = false;
      }

    },
    validaCampo() {
      if (this.preceptoria.curso) {
        this.stateValida.curso = true;
        this.validado = true;

      }
      else if (!this.preceptoria.curso) {
        this.stateValida.curso = false;
        this.validado = false;
      }
      if (this.preceptoria.cargaHoraria) {
        this.stateValida.cargaHoraria = true;
        this.validado = true;

      }
      else if (!this.preceptoria.cargaHoraria) {
        this.stateValida.cargaHoraria = false;
        this.validado = false;
      }
      if (this.preceptoria.quantAlunos) {
        this.stateValida.quantAlunos = true;
        this.validado = true;

      }
      else if (!this.preceptoria.quantAlunos) {
        this.stateValida.quantAlunos = false;
        this.validado = false;
      }
      if (this.preceptoria.tipoTrabalho) {
        this.stateValida.tipoTrabalho = true;
        this.validado = true;

      }
      else if (!this.preceptoria.tipoTrabalho) {
        this.stateValida.tipoTrabalho = false;
        this.validado = false;
      }
      if (this.preceptoria.inicio) {
        this.stateValida.inicio = true;
        this.validado = true;

      }
      else if (!this.preceptoria.inicio) {
        this.stateValida.inicio = false;
        this.validado = false;
      }
      if (this.preceptoria.termino) {
        this.stateValida.termino = true;
        this.validado = true;

      }
      else if (!this.preceptoria.termino) {
        this.stateValida.termino = false;
        this.validado = false;
      }
      if (this.preceptoria.equipe) {
        this.stateValida.equipe = true;
        this.validado = true;

      }
      else if (!this.preceptoria.equipe) {
        this.stateValida.equipe = false;
        this.validado = false;
      }
      if (this.preceptoria.agravos) {
        this.stateValida.agravos = true;
        this.validado = true;

      }
      else if (!this.preceptoria.agravos) {
        this.stateValida.agravos = false;
        this.validado = false;
      }
      if (this.preceptoria.areaFormacao) {
        this.stateValida.areaFormacao = true;
        this.validado = true;

      }
      else if (!this.preceptoria.areaFormacao) {
        this.stateValida.areaFormacao = false;
        this.validado = false;
      }
      if (this.preceptoria.tipo) {
        this.stateValida.tipo = true;
        this.validado = true;

      }
      else if (!this.preceptoria.tipo) {
        this.stateValida.tipo = false;
        this.validado = false;
      }
      if (this.preceptoria.situacao) {
        this.stateValida.situacao = true;
        this.validado = true;

      }
      else if (!this.preceptoria.situacao) {
        this.stateValida.situacao = false;
        this.validado = false;
      }
      if (this.preceptoria.natureza) {
        this.stateValida.natureza = true;
        this.validado = true;

      }
      else if (!this.preceptoria.natureza) {
        this.stateValida.natureza = false;
        this.validado = false;
      }

      if (this.preceptoria.orientacao) {
        this.stateValida.orientacao = true;
        this.validado = true;

      }
      else if (!this.preceptoria.orientacao) {
        this.stateValida.orientacao = false;
        this.validado = false;
      }

      if (this.preceptoria.modalidade) {
        this.stateValida.modalidade = true;
        this.validado = true;

      }
      else if (!this.preceptoria.modalidade) {
        this.stateValida.modalidade = false;
        this.validado = false;
      }
      if (this.preceptoria.instituicao) {
        this.stateValida.instituicao = true;
        this.validado = true;

      }
      else if (!this.preceptoria.instituicao) {
        this.stateValida.instituicao = false;
        this.validado = false;
      }
      return this.validado

    },
    async inicializar() {
      await this.verificaLocalStore();
      this.listarModalidade();
      this.listarOrientacao();
      this.listarNatureza();
      this.listarSituacaoVisita();
      this.listarAgravo();
      this.listarPreceptoria();
      this.listarTipoCurso();
      this.listarInstituicao();
      this.listarAreaFormacao();
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inverterCampoData(data) {
      data = data
        .split("/")
        .reverse()
        .join("/");
      data = data.replaceAll("/", "-")

      return data;
    },
    pegaId(array) {
      var listaId = [];
      for (let i = 0; i < array.length; i++) {
        listaId.push(array[i].id);
      }
      console.log("Lista de Ids", listaId)
      return listaId;
    },

    async salvarPreceptoria() {
      if (document.getElementById("btn-salvar").textContent == "Alterar") {
        setTimeout(() => {
          this.alterarPreceptoria();
        }, 200)
      } else {

        if (this.validaCampo() == true) {

          this.preceptoria.idLogado = this.usuarioLogado.id
         
          this.preceptoria.agravos = await this.pegaId(this.agravos);
          this.preceptoria.equipe = await this.pegaId(this.equipe);

          preceptoriaService
            .salvarPreceptoria(this.preceptoria)
            .then((res) => {
              this.stateValida = {};
              this.alert.mensagem = res.mensagem;
              this.alert.variant = "success";
              this.showAlert();

              this.listarPreceptoria();

              this.preceptoriaClonado = JSON.parse(JSON.stringify(this.preceptoria));
              document.getElementById("btn-salvar").textContent = "Alterar"




            })
            .catch((erro) => {
              console.log(erro)
              this.alert.mensagem = "Atenção! Erro ao salvar Visita.";
              this.alert.variant = "danger";
              this.showAlert();
            });

        } else {
          this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
          this.alert.variant = "danger";
          this.showAlert();
        }
      }
    },
    listarPreceptoria() {
      preceptoriaService.listarTodasPreceptoria(this.usuarioLogado.id).then((res) => {
        this.itemPreceptoria = res;
        console.log("itemPreceptoria", this.itemPreceptoria)
      });
    },
    listarModalidade() {
      constanteService.listarModalidade().then((res) => {
        this.itemModalidade = res;

      });
    },
    listarAreaFormacao() {
      constanteService.listarAreaFormacao().then((res) => {
        this.itemAreaFormacao = res;

      });
    },
    listarInstituicao() {
      instituicaoService.listarInstituicao().then((res) => {
        this.itemInstituicao = res;

      });
    },
    listarTipoCurso() {
      constanteService.listarTipoCurso().then((res) => {
        this.itemTipo = res;
        console.log("tipo do evento", this.itemTipo)

      });
    },
    listarNatureza() {
      constanteService
        .listarNatureza().then((res) => {
          this.itemNatureza = res;

        });
    },
    listarAgravo() {
      agravoService
        .listarAgravo().then((res) => {
          this.itemAgravo = res;
          this.itemAgravo = this.formataObjeto(this.itemAgravo);

        });
    },
    listarSituacaoVisita() {
      constanteService
        .listarSituacaoVisita().then((res) => {
          this.itemSituacaoPreceptoria = res;

        });
    },
    listarOrientacao() {
      constanteService
        .listarOrientacao().then((res) => {
          this.itemOrientacao = res;

        });
    },

    verificaLocalStore() {
      this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
      if (localStorage.getItem("usuarioPagina")) {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

      } else {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
      }
      // Carrega o vinculo
      if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
        this.vinculoSelecionado.indexVinculo = 0;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
        localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
      } else { // pega vinculo caso já esteja carregado
        this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
      }
    }
  },

}
</script>

<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 10px;

}
</style>



