import axios from "axios";

export const telefoneService = {
  listarTelefone(idUsuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/telefone/listar/${idUsuario}`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  criarTelefone(telefone) {
    return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/telefone`, telefone)
          .then((ret) => {
            resolve(ret.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
  },
  alterarTelefone(idTelefone, telefone) {
    return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_URL_BASE_BACK_END}/telefone/${idTelefone}`, telefone)
          .then((ret) => {
            resolve(ret.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
  },
};
