import axios from "axios";

export const eventoService = {
  salvarEvento(evento) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/evento`,
          evento,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarEvento(id, evento) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/evento/${id}`,
          evento,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarEvento(idEvento) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/evento/listar/${idEvento}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTodosEventos(idLogado) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/evento/listar?idLogado=${idLogado}`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
};
