import axios from "axios";

export const preceptoriaService = {
  salvarPreceptoria(preceptoria) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preceptoria`,
          preceptoria,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarPreceptoria(id, preceptoria) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preceptoria/${id}`,
          preceptoria,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarPreceptoria(idPreceptoria) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preceptoria/listar/${idPreceptoria}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTodasPreceptoria(idLogado) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preceptoria/listar?idLogado=${idLogado}`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
};
