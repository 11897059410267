<template>
  <div class="container">
    <div class="card">
      <h2 class="card__titulo">Projeto</h2>
      <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class=" col-lg-6 ">
          <label for="nome" class="form-label">Nome<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" required id="nome" v-model.trim="infoPessoal.nomeCivil"> -->
          <b-form-input type="text" class="form-control mb-3" required id="nome" v-model="projeto.nome"
            :state="stateValida.nome"></b-form-input>
          <div class="invalid-feedback">Campo Nome é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="nomSocial" class="form-label">Descrição <strong style="color:red">*</strong></label>

          <b-form-input type="text" class="form-control mb-3" id="nomSocial" v-model.trim="projeto.descricao"
            :state="stateValida.descricao"></b-form-input>
          <div class="invalid-feedback">Campo Descrição é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Início<strong style="color:red">*</strong></label>
          
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="projeto.inicio"
            :state="stateValida.inicio">
          </b-form-input>
          <div class="invalid-feedback">Campo Início é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="staticEmail2">Término</label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="projeto.termino">
          </b-form-input>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="input-live">Instituição<strong style="color:red">*</strong></label>

          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.instituicao"
            v-model="projeto.instituicao">
            <option value="0"></option>
            <option v-for="inst in itemInstituicao" :key="inst.id" :value="inst.id">{{
            inst.nome
            }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 ">
          <label for="staticEmail2">Abrangência<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.abrangencia"
            v-model="projeto.abrangencia">
            <option value="0"></option>
            <option v-for="abr in itemAbrangencia" :key="abr.id" :value="abr.id">{{
            abr.nome
            }}

            </option>
          </select>


          <div class="invalid-feedback">Campo Abrangência é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="staticEmail2">Tipo<strong style="color:red">*</strong></label>

          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.tipo" v-model="projeto.tipo">
            <option value="0"></option>
            <option v-for="tipoCurso in itemTipo" :key="tipoCurso.id" :value="tipoCurso.id">{{
            tipoCurso.nome
            }}
            </option>
          </select>
          <div class="invalid-feedback">Campo Tipo é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="staticEmail2">Situação<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.situacao"
            v-model="projeto.situacao">
            <option value="0"></option>
            <option v-for="sit in itemSituacaoprojeto" :key="sit.id" :value="sit.id">{{
            sit.nome
            }}
            </option>
          </select>
          <div class="invalid-feedback">Campo Situação é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label for="input-live">Agravo<strong style="color:red">*</strong></label>
          <div>
            <div>
            <treeselect v-model="projeto.agravos" :multiple="true" :options="itemAgravo" :state="stateValida.agravos" />
          </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="input-live">RA<strong style="color:red">*</strong></label>
          <div>            
            <treeselect v-model="projeto.ras" :multiple="true" :options="itemRa" :state="stateValida.ras" />
          </div>
        </div>
        <div class="col-lg-4">
          <label for="input-live">Equipe<strong style="color:red">*</strong></label>
          <multiselect class="mb-3" id="refEquipe" v-model="equipe" :state="stateValida.equipe" :options="itemEquipe"
            :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
            placeholder="Informe Id, Nome ou Cpf do usuário" :show-no-results="false" :hide-selected="true"
            label="nomeSocial" track-by="nomeSocial" :preselect-first="true" :searchable="true" :loading="isLoading"
            :internal-search="false" @search-change="buscarUsuario">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Usuário(s)
                Selecionado(s)</span></template>
            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </multiselect>
        </div>
      </div>
      <fieldset class="row">
        <div class="col-lg-3 ">
          <label for="processoSei">Processo SEI</label>
          <input type="text" class="form-control mb-3" id="processoSei" v-model="projeto.processoSEI">
        </div>
        <div class="col-lg-3 ">
          <label for="urlSei">UrlSEI</label>
          <input type="text" class="form-control mb-3" id="urlSei" v-model="projeto.urlSEI">
        </div>
      </fieldset>

      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="salvarProjeto" variant="outline-success" id="btn-salvar">Salvar
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="limparObjetoProjeto" variant="outline-success" id="btn-salvar">Novo
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <router-link to="/inicio">
              <b-button variant="outline-success">Voltar</b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Tabela de visitas -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h2 class="card__titulo"> Lista de Projetos</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Título</th>
                <th>Carga Horária</th>
                <th>Data Início</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="proj in itemProjeto" :key="proj.id">

                <td>{{ proj.nome }}</td>
                <td>{{ proj.descricao }}</td>
                <td>{{ proj.inicio }}</td>
                <td>
                  <div>
                    <b-button type="button" variant="outline-success"
                      @click="projeto = JSON.parse(JSON.stringify(proj)), editarProjeto(proj)">Editar
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import { constanteService } from "@/service/constanteService";
import { projetoService } from "@/service/projetoService";
import { agravoService } from "@/service/agravoService";
import { usuarioService } from "@/service/usuarioService";
import { instituicaoService } from "@/service/instituicaoService";
import { regiaoAdministrativaService } from "@/service/regiaoAdministrativaService";
import { mask } from "vue-the-mask";
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  directives: { mask },
  data() {
    return {

      alert: {
        mensagem: "",
        variant: ""
      },
      dismissCountDown: 0,
      dismissSecs: 10,
      usuarioLogado: {},
      usuarioPagina: {},
      projetoSelecionado: {},
      agravos: [],
      ras: [],
      equipe: [],
      itemRa: [],
      itemProjeto: [],
      itemInstituicao: [],

      itemTipo: [],
      itemSituacaoProjeto: [],
      itemAgravo: [],
      projetoClonada: {},
      idProjetoClonado: "",
      itemEquipe: [],
      itemAbrangencia: [],
      isLoading: false,
      projeto: {
        nome: "",
        descricao: "",
        inicio: "",
        termino: "",
        abrangencia: "",
        tipo: "",
        situacao: "",
        agravos: [],
        ras: [],
        equipe: [],
        processoSEI: "",
        urlSEI: "",
        instituicao: "",
        idLogado: "",

      },

      stateValida: {
        nome: "",
        descricao: "",
        inicio: "",
        termino: "",
        abrangencia: "",
        tipo: "",
        situacao: "",
        instituicao: "",
        agravos: "",
        ras: "",
        equipe: "",
      },
    }
  },
  components: {
    Multiselect,
    Treeselect
  },
  mounted() {
    this.inicializar();
  },
  methods: {

    limparObjetoProjeto() {
      this.alerta = {};
      this.idProjetoClonado = "";

      this.projetoClonada = {}

      setTimeout(() => {
        document.getElementById("btn-salvar").textContent = "Salvar"

      }, 200)

      this.projeto = {};

    },
    formataObjeto(array) {
      var listaIdLabel = [];
      for (let i = 0; i < array.length; i++) {
        let idLabel = {}
        idLabel.id = array[i].id;
        idLabel.label = array[i].nome;
        listaIdLabel.push(idLabel);
      }
      console.log("Lista de Ids e Labels", listaIdLabel)
      return listaIdLabel;
    },

    async buscarUsuario() {
      this.isLoading = true;
      let palavraChave = await document.getElementById("refEquipe").value;
      console.log("campo", palavraChave)
      usuarioService
        .buscarUsuarioSimples(palavraChave, this.usuarioLogado.id).then((res) => {
          this.itemEquipe = [res];
          console.log("Usuario Pesquisado", this.itemEquipe)
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        });
    },
    formataMultiSelect(selecionados, listaTotal) {
      let resultado = [];
      for (let i = 0; i < listaTotal.length; i++) {
        for (let j = 0; j < selecionados.length; j++) {
          if (selecionados[j] == listaTotal[i].id)
            resultado.push(listaTotal[i]);
        }
      }
      console.log("Resultado", resultado)
      return resultado;

    },
    async editarProjeto(projeto) {
      this.alerta = {};
      this.projeto = JSON.parse(JSON.stringify(projeto))
      

      document.getElementById("btn-salvar").textContent = "Alterar"

      this.projeto.inicio = await this.inverterCampoData(projeto.inicio);
      this.projeto.termino = await this.inverterCampoData(projeto.termino);
      

      this.idProjetoClonado = "";
      this.projetoClonada = {};
    },
    async alterarProjeto() {
      this.alert = {};
      this.projetoClonada.inicio = this.inverterCampoData(this.projeto.inicio);
      this.projetoClonada.termino = this.inverterCampoData(this.projeto.termino);

      for (let index = 0; index < this.itemProjeto.length; index++) {

        if (this.projetoClonada.nome === this.itemProjeto[index].nome) {
          this.idProjetoClonado = this.itemProjeto[index].id

          break;
        }


      }


      if (this.idProjetoClonado) {
        this.projeto.id = this.idProjetoClonado;

      }

      if (this.validaCampo() == true) {


        this.projeto.idLogado = this.usuarioLogado.id
        this.projeto.equipe = await this.pegaId(this.equipe);


        projetoService
          .alterarProjeto(this.projeto.id, this.projeto)
          .then((res) => {

            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert()
            this.listarProjeto();
          })
          .catch(() => {

          });
      } else {
        this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
        this.alert.variant = "danger";
        this.showAlert();
      }
    },


    validaCampo() {
      if (this.projeto.nome) {
        this.stateValida.nome = true;
        this.validado = true;

      }
      else if (!this.projeto.nome) {
        this.stateValida.nome = false;
        this.validado = false;
      }
      if (this.projeto.descricao) {
        this.stateValida.descricao = true;
        this.validado = true;

      }
      else if (!this.projeto.descricao) {
        this.stateValida.descricao = false;
        this.validado = false;
      }

      if (this.projeto.tipo) {
        this.stateValida.tipo = true;
        this.validado = true;

      }
      else if (!this.projeto.tipo) {
        this.stateValida.tipo = false;
        this.validado = false;
      }
      if (this.projeto.inicio) {
        this.stateValida.inicio = true;
        this.validado = true;

      }
      else if (!this.projeto.inicio) {
        this.stateValida.inicio = false;
        this.validado = false;
      }

      if (this.projeto.equipe) {
        this.stateValida.equipe = true;
        this.validado = true;

      }
      else if (!this.projeto.equipe) {
        this.stateValida.equipe = false;
        this.validado = false;
      }
      if (this.projeto.agravos) {
        this.stateValida.agravos = true;
        this.validado = true;

      }
      else if (!this.projeto.agravos) {
        this.stateValida.agravos = false;
        this.validado = false;
      }
      if (this.projeto.abrangencia) {
        this.stateValida.abrangencia = true;
        this.validado = true;

      }
      else if (!this.projeto.abrangencia) {
        this.stateValida.abrangencia = false;
        this.validado = false;
      }

      if (this.projeto.situacao) {
        this.stateValida.situacao = true;
        this.validado = true;

      }
      else if (!this.projeto.situacao) {
        this.stateValida.situacao = false;
        this.validado = false;
      }
      if (this.projeto.modalidade) {
        this.stateValida.modalidade = true;
        this.validado = true;

      }
      else if (!this.projeto.modalidade) {
        this.stateValida.modalidade = false;
        this.validado = false;
      }
      if (this.projeto.instituicao) {
        this.stateValida.instituicao = true;
        this.validado = true;

      }
      else if (!this.projeto.instituicao) {
        this.stateValida.instituicao = false;
        this.validado = false;
      }
      if (this.projeto.ras) {
        this.stateValida.ras = true;
        this.validado = true;

      }
      else if (!this.projeto.ras) {
        this.stateValida.ras = false;
        this.validado = false;
      }

      return this.validado

    },
    async inicializar() {
      await this.verificaLocalStore();
      this.listarRa();
      this.listarSituacaoVisita();
      this.listarAgravo();
      this.listarProjeto();
      this.listarAbrangencia();
      this.listarInstituicao();

    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inverterCampoData(data) {
      data = data
        .split("/")
        .reverse()
        .join("/");
      data = data.replaceAll("/", "-")

      return data;
    },
    pegaId(array) {
      var listaId = [];
      for (let i = 0; i < array.length; i++) {
        listaId.push(array[i].id);
      }
      console.log("Lista de Ids", listaId)
      return listaId;
    },

    async salvarProjeto() {
      if (document.getElementById("btn-salvar").textContent == "Alterar") {
        setTimeout(() => {
          this.alterarProjeto();
        }, 200)
      } else {

        this.projeto.agravos = await this.pegaId(this.agravos);
        this.projeto.ras = await this.pegaId(this.ras);
        this.projeto.equipe = await this.pegaId(this.equipe);

        if (this.validaCampo() == true) {

          this.projeto.idLogado = this.usuarioLogado.id


          projetoService
            .salvarProjeto(this.projeto)
            .then((res) => {
              this.stateValida = {};
              this.alert.mensagem = res.mensagem;
              this.alert.variant = "success";
              this.showAlert();

              this.listarProjeto();

              this.projetoClonado = JSON.parse(JSON.stringify(this.projeto));
              document.getElementById("btn-salvar").textContent = "Alterar"




            })
            .catch((erro) => {
              console.log(erro)
              this.alert.mensagem = "Atenção! Erro ao salvar projeto.";
              this.alert.variant = "danger";
              this.showAlert();
            });

        } else {
          this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
          this.alert.variant = "danger";
          this.showAlert();
        }
      }
    },
    listarAbrangencia() {
      constanteService
        .listarAbrangencia().then((res) => {
          this.itemAbrangencia = res;
        });
    },
    listarRa() {
      regiaoAdministrativaService.listarTodasRas().then((res) => {
        this.itemRa = res;
        this.itemRa = this.formataObjeto(this.itemRa);

      });
    },
    listarProjeto() {
      projetoService.listarTodosProjeto(this.usuarioLogado.id).then((res) => {
        this.itemProjeto = res;

      });
    },

    listarInstituicao() {
      instituicaoService.listarInstituicao().then((res) => {
        this.itemInstituicao = res;

      });
    },

    listarAgravo() {
      agravoService
        .listarAgravo().then((res) => {
          this.itemAgravo = res;
          this.itemAgravo = this.formataObjeto(this.itemAgravo);

        });
    },
    listarSituacaoVisita() {
      constanteService
        .listarSituacaoVisita().then((res) => {
          this.itemSituacaoprojeto = res;

        });
    },

    verificaLocalStore() {
      this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
      if (localStorage.getItem("usuarioPagina")) {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

      } else {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
      }
      // Carrega o vinculo
      if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
        this.vinculoSelecionado.indexVinculo = 0;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
        localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
      } else { // pega vinculo caso já esteja carregado
        this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
      }
    }
  },

}
</script>

<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 10px;

}
</style>



