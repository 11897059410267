<template>
  <div class="container">
    <div class="card">
      <h2 class="card__titulo"> Informações Pessoais</h2>
      <b-alert :show="dismissCountDownDadosPessoais" :variant="alert.variant"
        @dismissed="dismissCountDownDadosPessoais = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDownDadosPessoais" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class=" col-lg-6 ">
          <label for="nome" class="form-label">Nome do Servidor<strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control" required id="nome" v-model="infoPessoal.nomeCivil"
            :state="stateValida.nomeCivil"></b-form-input>
          <div class="invalid-feedback">Campo Nome Cívil é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="nomSocial" class="form-label">Nome Social ou Apelido<strong style="color:red">*</strong></label>
          
          <b-form-input type="text" class="form-control" id="nomSocial" v-model.trim="infoPessoal.nomeSocial"
            :state="stateValida.nomeSocial"></b-form-input>
          <div class="invalid-feedback">Campo Nome Social é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <label for="cpf">CPF<strong style="color:red">*</strong></label>
         
          <b-form-input v-mask="'###.###.###-##'" type="text" class="form-control" id="cpf" v-model="infoPessoal.cpf"
            :state="stateValida.cpf">
          </b-form-input>
          <div class="invalid-feedback">Campo CPF é obrigatório!</div>
        </div>
        <div class="col-lg-3">
          <label for="rg">RG</label>
          
          <b-form-input type="text" class="form-control" id="rg" v-model="infoPessoal.identidade">
          </b-form-input>
        </div>
        <div class="col-lg-3 ">
          <label for="staticEmail2">Data de Nascimento<strong style="color:red">*</strong></label>
          
          <b-form-input type="date" class="form-control" id="staticEmail2" v-model="infoPessoal.nascimento"
            :state="stateValida.nascimento">
          </b-form-input>
          <div class="invalid-feedback">Campo de Nascimento é obrigatório!</div>
        </div>
        <div class="col-md-3">
          <label for="input-live">Escolaridade</label>
          <select class="form-control mb-3 input-select" id="selectEscolaridade">
            <option :selected="esc.id == infoPessoal.escolaridade" v-for="esc in itemEscolaridade" :key="esc.id"
              :value="esc.id">{{
                  esc.nome
              }}</option>
          </select>
        </div>
      </div>
      <fieldset class="row">
        <div class="col-md-3">
          <label for="input-live">Tipo sanguíneo</label>
          <select class="form-control mb-3 input-select" id="selectTipoSanguineo">
            <option :selected="sanguineo.id == infoPessoal.tipoSanguineo" v-for="sanguineo in itemTipoSanguineo"
              :key="sanguineo.id" :value="sanguineo.id">{{
                  sanguineo.nome
              }}</option>
          </select>
        </div>
        <div class="col-lg-3">
          <label for="input-live">Sexo</label>
          <select class="form-control mb-3 input-select" id="selectSexo">
            <option :selected="sexo.id == infoPessoal.sexo" v-for="sexo in itemSexo" :key="sexo.id" :value="sexo.id">{{
                sexo.nome
            }}</option>
          </select>
        </div>
        <div class="col-lg-3">
          <label for="input-live">Gênero</label>
          <select class="form-control mb-3 input-select" id="selectGenero">
            <option :selected="genero.id == infoPessoal.genero" v-for="genero in itemTipoGenero" :key="genero.id"
              :value="genero.id">{{
                  genero.nome
              }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="input-live">Pessoa com Deficiência?</label>

          <select class="form-control mb-3 input-select" id="selectPessoasDeficiencia">
            <option :selected="deficiencia.id == infoPessoal.tipoDeficiencia" v-for="deficiencia in itemTipoDeficiencia"
              :key="deficiencia.id" :value="deficiencia.id">{{
                  deficiencia.nome
              }}</option>
          </select>
        </div>
        
      </fieldset>

      <fieldset class="row">
        <div class="col-sm-4 mb-2 ">
          <label for="emailPessoal">E-mail pessoal</label>
          <input type="text" class="form-control" id="emailPessoal" v-model="infoPessoal.emailPessoal">
        </div>
        <div class="col-sm-6 mb-2 ">
          <label for="curriculo">Link do Currículo Lattes</label>
          <input type="text" class="form-control" id="curriculo" v-model="infoPessoal.curriculoLattes">
        </div>
        <div class="col-sm-2 mb-2 borda">
          <label for="btnRestaurarSenha"> Restaurar Senha</label>
          <b-button id="btnRestaurarSenha" variant="outline-success" :disabled="usuarioPagina.id == usuarioLogado.id"
            @click="restaurarSenha">Restaurar
          </b-button>
        </div>
      </fieldset>
      

      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="salvarInfoPessoais" variant="outline-success">Alterar</b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <router-link to="/inicio">
              <b-button variant="outline-success">Voltar</b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="row">
        <h2 class="card__titulo">Informações de Endereços</h2>
        <b-alert :show="dismissCountDownEndereco" :variant="alert.variant" @dismissed="dismissCountDownEndereco = 0">
          <p>{{ alert.mensagem }}</p>
          <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDownEndereco" height="4px">
          </b-progress>
        </b-alert>
      </div>
      <div class="row">
        <div class="col-sm-4 mb-2">
          <label for="">Endereços Cadastrados</label>
          <select class="form-control mb-3 input-select" id="idEnderecoSelecionado" v-on:change="trocaEndereco()">
            <option value="0"></option>
            <option  v-for="end in itemEndereco" :key="end.id"
              :value="end.id">
              {{ end.endereco }}
            </option>
          </select>
        </div>
        <div class="col-sm-4 mb-2 ">
          <label for="cep">CEP</label>
          <!-- <input type="text" class="form-control" id="cep" v-model="endereco.cep"> -->
          <b-form-input type="text" class="form-control" id="cep" v-model="endereco.cep"
          SIZE="8" MAXLENGTH="8">
          </b-form-input>
          <div class="invalid-feedback">Campo CEP é obrigatório!</div>
        </div>
        <div class="col-sm-4 mb-2 ">
          <label for="endereco">Endereço<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" id="endereco" v-model="endereco.endereco"> -->
          <b-form-input type="text" class="form-control" id="endereco" v-model="endereco.endereco"
            :state="stateValida.endereco"></b-form-input>
          <div class="invalid-feedback">Campo Endereço é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 mb-2 ">
          <label for="bairro">Bairro<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" id="bairro" v-model="endereco.bairro"> -->
          <b-form-input type="text" class="form-control" id="bairro" v-model="endereco.bairro"
            :state="stateValida.bairro"></b-form-input>
          <div class="invalid-feedback">Campo Bairro é obrigatório!</div>
        </div>
        <div class="col-sm-3 mb-2">
          <label for="estado">Estado</label>
          <select class="form-control mb-3 input-select" id="estado" v-model="endereco.uf">
            <option v-for="uf in itemUF" :key="uf.id" :value="uf.id">{{
                uf.nome
            }}</option>
          </select>
        </div>
        <div class="col-sm-3 mb-2 ">
          <label for="cidade">Cidade<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" id="cidade" v-model="endereco.cidade"> -->
          <b-form-input type="text" class="form-control" id="cidade" v-model="endereco.cidade"
            :state="stateValida.cidade"></b-form-input>
          <div class="invalid-feedback">Campo Cidade é obrigatório!</div>
        </div>
        <div class="col-sm-3 mb-2 ">
          <label for="ra">Ra</label>
          <select class="form-control mb-3 input-select" id="selectRa" v-model="endereco.ra">
            <option :selected="ra.id == infoPessoal.ra" v-for="ra in itemRa" :key="ra.id" :value="ra.id">{{
                ra.nome
            }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button id="btnSalvarEndereco" @click="criarEndereco()" variant="outline-success">Salvar</b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button variant="outline-success" @click="limpaEndereco()">Novo</b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <h2 class="card__titulo"> Informações de Contato</h2>
      <b-alert :show="dismissCountDownTelefone" :variant="alert.variant" @dismissed="dismissCountDownTelefone = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDownTelefone" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class="col-sm-3 mb-2">
          <label for="">Telefones Cadastrados</label>
          <select class="form-control mb-3 input-select" id="idTelefoneSelecionado" v-on:change="trocaTelefone()">
            <option value="0"></option>
            <option v-for="tel in itemTelefone" :key="tel.id"
              :value="tel.id">
              {{ tel.numero }}
            </option>
          </select>
        </div>
        <div class="col-sm-2 mb-2 ">
          <label for="idContato">Tipo de Telefone<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="idContato" ref="idContato"
            :state="stateValida.tipoTelefone" v-model="telefone.tipoTelefone">
            <option v-for="tipoTelefone in itemTipoTelefone" :key="tipoTelefone.id" :value="tipoTelefone.id">{{
                tipoTelefone.nome
            }}</option>
          </select>
          <div class="invalid-feedback">Campo Tipo de Telefone é obrigatório!</div>
        </div>
        <div class="col-sm-1 mb-2 ">
          <label for="ra">DDD:<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" id="ra" v-model="telefone.ddd" :state="stateValida.ddd"> -->
          <b-form-input type="text" class="form-control" id="ra" v-model="telefone.ddd" :state="stateValida.ddd"
          SIZE="2" MAXLENGTH="2">
          </b-form-input>
          <div class="invalid-feedback">Campo DDD é obrigatório!</div>
        </div>

        <div class="col-sm-2 mb-2 ">
          <label for="ra">Telefone:<strong style="color:red">*</strong></label>
          <!-- <input type="text" class="form-control" id="ra" v-model="telefone.numero" :state="stateValida.numero"> -->
          <b-form-input type="text" class="form-control" id="ra" v-model="telefone.numero" :state="stateValida.numero"
          SIZE="9" MAXLENGTH="9">
          </b-form-input>
          <div class="invalid-feedback">Campo Telefone é obrigatório!</div>
        </div>
        <div class="col-sm-4 mb-2 ">
          <label for="ra">Observações:</label>
          
          <b-form-input type="text" class="form-control" id="ra" v-model="telefone.obs">
          </b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button id="btnSalvarTelefone" @click="salvarTelefone()" variant="outline-success">Salvar</b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button variant="outline-success" @click="limpaTelefone()">Novo</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { usuarioService } from "@/service/usuarioService";
import { constanteService } from "@/service/constanteService";
import { enderecoService } from "@/service/enderecoService";
import { telefoneService } from "@/service/telefoneService";
import { mask } from "vue-the-mask";

export default {
/*   beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  }, */
  directives: { mask },
  data() {
    return {
      alert: {
        mensagem: "",
        variant: ""
      },
      dismissCountDownDadosPessoais: 0,
      dismissCountDownEndereco: 0,
      dismissCountDownTelefone: 0,
      dismissSecs: 10,
      usuarioLogado: {},
      usuarioPagina: {},
      vinculoSelecionado: {},
      enderecoCadastrado: "",
      infoPessoal: {
        cpf: "",
        emailPessoal: "",
        genero: "",
        identidade: "",
        nascimento: "",
        nomeCivil: "",
        nomeSocial: "",
        sexo: "",
        tipoDeficiencia: "",
        tipoSanguineo: "",
        curriculoLattes: "",
        escolaridade: "",
        idLogado: "",
      },
      endereco: {
        cep: "",
        bairro: "",
        endereco: "",
        cidade: "",
        uf: "",
        ra: "",
        usuario: "",
        idLogado: ""
      },
      telefone: {
        ddd: "",
        numero: "",
        obs: "",
        tipoTelefone: "",
        usuario: "",
        idLogado: ""
      },
      itemTipoSanguineo: [],
      itemSexo: [],
      itemTipoGenero: [],
      itemTipoDeficiencia: [],
      itemEndereco: [],
      itemUF: [],
      itemEscolaridade: [],
      itemTipoTelefone: [],
      itemRa: [],
      itemTelefone: [],
      stateValida: {
        valida: null,
        ddd: null,
        numero: null,
        tipoTelefone: null,

        endereco: null,
        bairro: null,
        cidade: null,

        cpf: null,
        nomeCivil: null,
        nomeSocial: null,
        nascimento: null
      },
    }
  },
  mounted() {
    this.inicializar();
  },
  methods: {
    validaCampo(tipoValidacao) {
      this.stateValida.valida = true;
      if (tipoValidacao == "dadosPessoais") {
        if (this.infoPessoal.cpf) {
          this.stateValida.cpf = true;
        }
        else if (!this.infoPessoal.cpf) {
          this.stateValida.cpf = false;
          this.stateValida.valida = false;
        }
        if (this.infoPessoal.nomeSocial) {
          this.stateValida.nomeSocial = true;
        }
        else if (!this.infoPessoal.nomeSocial) {
          this.stateValida.nomeSocial = false;
          this.stateValida.valida = false;
        }
        if (this.infoPessoal.nomeCivil) {
          this.stateValida.nomeCivil = true;
        }
        else if (!this.infoPessoal.nomeCivil) {
          this.stateValida.nomeCivil = false;
          this.stateValida.valida = false;
        }
        if (this.infoPessoal.nascimento) {
          this.stateValida.nascimento = true;
        }
        else if (!this.infoPessoal.nascimento) {
          this.stateValida.nascimento = false;
          this.stateValida.valida = false;
        }
      }
      if (tipoValidacao == "endereco") {
        if (this.endereco.bairro) {
          this.stateValida.bairro = true;
          this.stateValida.valida = true;
        }
        else if (!this.endereco.bairro) {
          this.stateValida.bairro = false;
          this.stateValida.valida = false;
        }
        if (this.endereco.endereco) {
          this.stateValida.endereco = true;
          this.stateValida.valida = true;
        }
        else if (!this.endereco.endereco) {
          this.stateValida.endereco = false;
          this.stateValida.valida = false;
        }
        if (this.endereco.cidade) {
          this.stateValida.cidade = true;
          this.stateValida.valida = true;
        }
        else if (!this.endereco.cidade) {
          this.stateValida.cidade = false;
          this.stateValida.valida = false;
        }
      }
      if (tipoValidacao == "telefone") {
        if (this.telefone.ddd) {
          this.stateValida.ddd = true;
          this.stateValida.valida = true;
        }
        else if (!this.telefone.ddd) {
          this.stateValida.ddd = false;
          this.stateValida.valida = false;
        }
        if (this.telefone.numero) {
          this.stateValida.numero = true;
          this.stateValida.valida = true;
        }
        else if (!this.telefone.numero) {
          this.stateValida.numero = false;
          this.stateValida.valida = false;
        }
        if (this.telefone.tipoTelefone) {
          this.stateValida.tipoTelefone = true;
          this.stateValida.valida = true;
        }
        else if (!this.telefone.tipoTelefone) {
          this.stateValida.tipoTelefone = false;
          this.stateValida.valida = false;
        }
      }
    },
    async inicializar() {
      await this.verificaLocalStore();
      this.carregarDadosIniciais();
      this.listarTipoSanguineo();
      this.listarSexo();
      this.listarTipoGenero();
      this.listarTipoDeficiencia();
      this.listarEndereco();
      this.listarTipoUF();
      this.listarEscolaridade()
      this.listarTipoTelefone();
      this.listarTelefone();
      this.listarRa();
    },
    showAlert(tipoAlerta) {
      if (tipoAlerta == "dadosPessoais") {
        this.dismissCountDownDadosPessoais = this.dismissSecs;
      }
      if (tipoAlerta == "endereco") {
        this.dismissCountDownEndereco = this.dismissSecs;
      }
      if (tipoAlerta == "telefone") {
        this.dismissCountDownTelefone = this.dismissSecs;
      }
    },
    carregarDadosIniciais() {
      let idUsuario = this.usuarioPagina.id
      let idLogado = this.usuarioLogado.id
      console.log("IDS", this.usuarioLogado.id, this.usuarioPagina.id)
      usuarioService.buscarUsuarioPorChave(idUsuario, idLogado).then((res) => {
        let usuarioPesquisado = res;
        console.log("Usuario Pesquisado", usuarioPesquisado)
        this.infoPessoal.cpf = usuarioPesquisado.cpf;
        this.infoPessoal.emailPessoal = usuarioPesquisado.emailPessoal;
        this.infoPessoal.genero = usuarioPesquisado.genero;
        this.infoPessoal.identidade = usuarioPesquisado.identidade;
        if (usuarioPesquisado.nascimento) {
          this.infoPessoal.nascimento = this.inverterCampoData(usuarioPesquisado.nascimento);
        } else {
          this.infoPessoal.nascimento = usuarioPesquisado.nascimento
        }
        this.infoPessoal.nomeCivil = usuarioPesquisado.nomeCivil;
        this.infoPessoal.nomeSocial = usuarioPesquisado.nomeSocial;
        this.infoPessoal.sexo = usuarioPesquisado.sexo;
        this.infoPessoal.tipoDeficiencia = usuarioPesquisado.tipoDeficiencia;
        this.infoPessoal.tipoSanguineo = usuarioPesquisado.tipoSanguineo;
        this.infoPessoal.curriculoLattes = usuarioPesquisado.curriculoLattes;
        this.infoPessoal.escolaridade = usuarioPesquisado.escolaridade;
        console.log("Dados infoPessoais", this.infoPessoal)
      }).catch(erro => {
        console.log(erro);
      }).finally(() => {
      })
    },
    removePontuacao(str) {
      return str.replace(/[^0-9]/g, '');
    },
    inverterCampoData(data) {
      data = data
        .split("/")
        .reverse()
        .join("/");
      data = data.replaceAll("/", "-")

      return data;
    },
    retiraMascaraCpf(cpf) {
      cpf = cpf.charAt(0) + cpf.charAt(1) + cpf.charAt(2) +
        cpf.charAt(4) + cpf.charAt(5) + cpf.charAt(6) +
        cpf.charAt(8) + cpf.charAt(9) + cpf.charAt(10) +
        cpf.charAt(12) + cpf.charAt(13);
      return cpf;
    },
    salvarInfoPessoais() {
      this.validaCampo("dadosPessoais");
      if (this.stateValida.valida) {
        if (this.infoPessoal.nascimento) {
          this.infoPessoal.nascimento = this.inverterCampoData(this.infoPessoal.nascimento);
        }
        this.infoPessoal.escolaridade = document.getElementById("selectEscolaridade").value;
        this.infoPessoal.tipoSanguineo = document.getElementById("selectTipoSanguineo").value;
        this.infoPessoal.sexo = document.getElementById("selectSexo").value;
        this.infoPessoal.genero = document.getElementById("selectGenero").value;
        this.infoPessoal.tipoDeficiencia = document.getElementById("selectPessoasDeficiencia").value;
        this.infoPessoal.idLogado = this.usuarioLogado.id;
        if (this.infoPessoal.cpf.length == 14) {
          this.infoPessoal.cpf = this.retiraMascaraCpf(this.infoPessoal.cpf);

          usuarioService
            .alterarDadosPessoais(this.usuarioPagina.id, this.infoPessoal)
            .then((res) => {
              this.stateValida = {};
              this.alert.mensagem = res.mensagem;
              this.alert.variant = "success";
              this.showAlert("dadosPessoais");
              
            })
            .catch((erro) => {
              console.log(erro)
              this.alert.mensagem = "Atenção! Erro ao alterar dados pessoais do usuário.";
              this.alert.variant = "danger";
              this.showAlert("dadosPessoais");
            });
        } else {
          this.alert.mensagem = "Atenção! O CPF deve conter os 11 digitos.";
          this.alert.variant = "danger";
          this.showAlert("dadosPessoais");
        }
      }
    },
    restaurarSenha() {
      this.alert = {};
      let idUsuarioLogado = {
        "idLogado": this.usuarioLogado.id
      }
      usuarioService.restaurarSenha(this.usuarioPagina.id, idUsuarioLogado).then((res) => {
        console.log("Senha Restaurada", res);
        this.alert.mensagem = res.mensagem;
        this.alert.variant = "success";
        this.showAlert("dadosPessoais");
      }).catch((erro) => {
        console.log(erro)
        if (this.usuarioPagina.id == this.usuarioLogado.id) {
          this.alert.mensagem = "Atenção! Não é possível restaura a sua própria senha.";
          this.alert.variant = "danger";
          this.showAlert("dadosPessoais");
        } else {
          this.alert.mensagem = "Atenção! Erro ao restaurar senha.";
          this.alert.variant = "danger";
          this.showAlert("dadosPessoais");
        }
      })
    },
    listarEndereco() {
      enderecoService.listarEndereco(this.usuarioPagina.id).then((res) => {
        this.itemEndereco = res;
        
        console.log("Endereços", this.itemEndereco)
      }).catch((erro) => {
        console.log(erro)
        if (erro.response && erro.response.status == 404) {
          this.itemEndereco = []
        }
      })
    },
    criarEndereco() {
      this.validaCampo("endereco");
      if (this.stateValida.valida) {
        this.alert = {};
        if (document.getElementById("btnSalvarEndereco").textContent == "Alterar") {
          this.alterarEndereco();
        } else {
          this.endereco.usuario = this.usuarioPagina.id;
          this.endereco.idLogado = this.usuarioLogado.id;
          console.log("Novo Endereço", this.endereco)
          enderecoService.criarEndereco(this.endereco).then((res) => {
            document.getElementById("btnSalvarEndereco").textContent = "Alterar";
            this.listarEndereco();
            this.stateValida = {};
            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert("endereco");
          }).catch((erro) => {
            console.log(erro)
            this.alert.mensagem = "Atenção! Erro ao criar um endereço.";
            this.alert.variant = "danger";
            this.showAlert("endereco");
          })
        }
      }
    },
    alterarEndereco() {
      this.validaCampo("endereco");
      if (this.stateValida.valida) {
        this.alert = {};
        this.endereco.usuario = this.usuarioPagina.id;
        this.endereco.idLogado = this.usuarioLogado.id;
        console.log("Telefone id", this.telefone.id)
        enderecoService.alterarEndereco(this.endereco.id, this.endereco).then((res) => {
          this.alert.mensagem = res.mensagem;
          this.alert.variant = "success";
          this.showAlert("endereco");
          this.listarEndereco();
          this.stateValida = {};
          console.log("deu certo", res)
        }).catch((erro) => {
          console.log(erro)
          this.alert.mensagem = "Atenção! Erro ao alterar um endereço.";
          this.alert.variant = "danger";
          this.showAlert("endereco");
        })
      }
    },
    async trocaEndereco() {
      let idEnderecoSelecionado = document.getElementById("idEnderecoSelecionado").value;
      if (idEnderecoSelecionado == 0) {
        this.limpaEndereco();
      } else {
        await this.listarEndereco();
        for (let index = 0; index < this.itemEndereco.length; index++) {
          if (this.itemEndereco[index].id == idEnderecoSelecionado) {
            document.getElementById("btnSalvarEndereco").textContent = "Alterar";
            this.endereco = this.itemEndereco[index];
            this.enderecoCadastrado = this.itemEndereco[index].endereco
            break;
          }
        }
      }
    },
    limpaEndereco() {
      this.endereco = {};
      document.getElementById("idEnderecoSelecionado").value = 0;
      document.getElementById("btnSalvarEndereco").textContent = "Salvar";
    },
    limpaTelefone() {
      this.telefone = {};
      this.telefone.numero = "";
      document.getElementById("idTelefoneSelecionado").value = 0;
      document.getElementById("btnSalvarTelefone").textContent = "Salvar";
    },
    async trocaTelefone() {
      let idTelefoneSelecionado = document.getElementById("idTelefoneSelecionado").value;
      if (idTelefoneSelecionado == 0) {
        this.limpaTelefone();
      } else {
        await this.listarTelefone();
        for (let index = 0; index < this.itemTelefone.length; index++) {
          if (this.itemTelefone[index].id == idTelefoneSelecionado) {
            document.getElementById("btnSalvarTelefone").textContent = "Alterar";
            this.telefone = this.itemTelefone[index];
            break;
          }
        }
      }
    },
    listarTelefone() {
      telefoneService.listarTelefone(this.usuarioPagina.id).then((res) => {
        this.itemTelefone = res;
        console.log("Telefones", this.itemTelefone)
      }).catch((erro) => {
        console.log(erro)
        if (erro.response && erro.response.status == 404) {
          this.itemTelefone = []
        }
        console.log("Telefones", this.itemTelefone)
      })
    },
    listarRa() {
      constanteService
        .listarRa().then((res) => {
          this.itemRa = res;
          console.log("Ra's", this.itemRa)
        });
    },
    listarTipoTelefone() {
      constanteService
        .tipoTelefone().then((res) => {
          this.itemTipoTelefone = res;
          console.log("Tipos Telefones", this.itemTipoTelefone)
        });
    },
    listarEscolaridade() {
      constanteService
        .listarEscolaridade().then((res) => {
          this.itemEscolaridade = res;
          console.log("itemEscolaridade", this.itemEscolaridade)
        });
    },
    salvarTelefone() {
      this.validaCampo("telefone");
      if (this.stateValida.valida) {
        this.alert = {};
        if (document.getElementById("btnSalvarTelefone").textContent == "Alterar") {
          this.alterarTelefone();
        } else {
          this.telefone.ddd = parseInt(this.telefone.ddd);
          this.telefone.numero = parseInt(this.telefone.numero);
          this.telefone.usuario = this.usuarioPagina.id;
          this.telefone.idLogado = this.usuarioLogado.id;
          console.log("Salvar Telefone", this.telefone)
          telefoneService.criarTelefone(this.telefone).then((res) => {
            this.stateValida = {};
            document.getElementById("btnSalvarTelefone").textContent = "Alterar";
            //this.telefone.numero = this.telefone.numerotoString()
            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert("telefone");
            this.listarTelefone();
            console.log("deu certo", res)
          }).catch((erro) => {
            console.log(erro)
            this.alert.mensagem = "Atenção! Erro ao salvar um telefone.";
            this.alert.variant = "danger";
            this.showAlert("telefone");
          })
        }
      }
    },
    alterarTelefone() {
      this.validaCampo("telefone");
      if (this.stateValida.valida) {
        this.alert = {};
        this.telefone.ddd = parseInt(this.telefone.ddd);
        this.telefone.numero = parseInt(this.telefone.numero);
        this.telefone.usuario = this.usuarioPagina.id;
        this.telefone.idLogado = this.usuarioLogado.id;
        console.log("Telefone id", this.telefone.id)
        telefoneService.alterarTelefone(this.telefone.id, this.telefone).then((res) => {
          this.alert.mensagem = res.mensagem;
          this.listarTelefone();
          this.stateValida = {};
          this.alert.variant = "success";
          this.showAlert("telefone");
          console.log("deu certo", res)
        }).catch((erro) => {
          console.log(erro)
          this.alert.mensagem = "Atenção! Erro ao alterar um telefone.";
          this.alert.variant = "danger";
          this.showAlert("telefone");
        })
      }
    },
    listarTipoSanguineo() {
      constanteService
        .listarTipoSanguineo().then((res) => {
          this.itemTipoSanguineo = res;
        });
    },
    listarSexo() {
      constanteService
        .listarSexo().then((res) => {
          this.itemSexo = res;
        });
    },
    listarTipoGenero() {
      constanteService
        .listarTipoGenero().then((res) => {
          this.itemTipoGenero = res;
        });
    },
    listarTipoDeficiencia() {
      constanteService
        .listarTipoDeficiencia().then((res) => {
          this.itemTipoDeficiencia = res;
        });
    },
    listarTipoUF() {
      constanteService
        .listarTipoUF().then((res) => {
          this.itemUF = res;
        });
    },
    verificaLocalStore() {
      this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
      if (localStorage.getItem("usuarioPagina")) {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

      } else {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
      }
      // Carrega o vinculo
      if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
        this.vinculoSelecionado.indexVinculo = 0;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
        localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
      } else { // pega vinculo caso já esteja carregado
        this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
      }
    }
  },

}
</script>

<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 10px;
  padding: 5px;

}
</style>



