<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Núcleo</h2>
          <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
               
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4" role="group">

                            <div role="group">
                                <label for="input-live">Gerência<strong style="color:red">*</strong></label>
                                
                                <select class="form-control mb-3 input-select" id="refGerenciaDrop"
                                    :state="stateValida.gerencia"
                                    v-on:change="validaCampo, listarNucleo(), habilitarCampo()"
                                    v-model="nucleo.gerencia">
                                    <option value="0"></option>
                                    <option v-for="gerencia in tipoGerencia" :key="gerencia.id" :value="gerencia.id">{{
                                            gerencia.sigla
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo Gerência é obrigatório!</div>

                        </div>
                        <div class="col-lg-4" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>
                            <b-form-input id="nomeNucleo" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome do Núcleo" v-model="nucleo.nome" SIZE="60" MAXLENGTH = "60" disabled></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-4" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="siglaNucleo" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla do Núcleo" v-model="nucleo.sigla" SIZE="20" MAXLENGTH = "20" disabled></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>

                    </div>
                    <fieldset class="fieldset " >  
                        <div class="row">
                        <div class="btn-group" role="group">
                            <div class="container-buttons-salvar">
                                <b-button @click="adicionarNucleo" variant="outline-success" id="btn-salvar">Salvar
                                </b-button>
                            </div>

                            &nbsp;
                            <div class="container-buttons-salvar">

                                <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoNucleo()">Novo
                                </b-button>

                            </div>
                            &nbsp;
                            <div class="container-buttons-salvar">
                                <router-link to="/inicio">
                                    <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                </router-link>
                            </div>
                        </div>

                    </div>
                    </fieldset>

                    

                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas dos Núcleos</h2>

                   

                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>

                          
                            <tr v-for="n in itemsNucleo" :key="n.id">

                                <td>{{ n.nome }}</td>
                                <td>{{ n.sigla }}</td>



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="nucleo = JSON.parse(JSON.stringify(n)), editarNucleo()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { nucleoService } from "@/service/nucleoService";
import { gerenciaService } from "@/service/gerenciaService";

export default {
    data() {
        return {
            infoNucleo: {},
            infoGerencia: {},
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            nucleoErro: false,
            stateValida: {
                nome: null,
                sigla: null,
                gerencia: "",
            },
            listaNucleoAuxiliar: [],

            nucleo: {
                nome: "",
                sigla: "",
                gerencia: "",
                idLogado: "",
            },
                alert: {
                mensagem: "",
                variant: ""
            },
            nucleoClonado: {},
            idNucleoClonado: "",
            stateNome: null,
            stateSigla: null,
            stateGerencia: null,
            listaDiretorias: [],
            validado:"",

            mensagem: "",
            tipoGerencia: [],
            fields: ['nome', 'sigla'],
           


            itemsNucleo: []

        }
    },
    mounted() {
        this.inicialiazar();

    },
    methods: {
        async inicialiazar() {
            await this.verificaLocalStore();

            this.listarGerencia()
            this.alerta = {};
            
        },
        limparObjetoNucleo() {
        this.alerta = {};
       
        this.nucleoClonado= {};
        this.idNucleoClonado= "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
            }, 200)
            this.nucleo = {};
        },
        alterarNucleo() {        
            this.alert = {};

         for (let index = 0; index < this.itemsNucleo.length; index++) {

            if(this.nucleoClonado.nome === this.itemsNucleo[index].nome){
                this.idNucleoClonado = this.itemsNucleo[index].id
            console.log(this.idNucleoClonado)
                break;
            }
               
                
          }

        
        if(this.idNucleoClonado){ 
            this.nucleo.id = this.idNucleoClonado;
            
        } 


            if (this.validaCampo() == true) {
                 this.nucleo.idLogado = this.usuarioLogado.id
                nucleoService
                    .alterarNucleo(this.nucleo.id, this.nucleo)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                       
                        this.listarNucleo();
                    })
                    .catch(() => {
                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },

      
        async buscaNucleo(idNucleo) {

            await nucleoService
                .pegaNucleo(idNucleo)
                .then((res) => {

                    this.infoNucleo = res;
                    console.log("objeto é ", this.infoNucleo)


                })
                .catch(() => {

                });
        },
        async buscaGerencia(idGerencia) {

            await gerenciaService
                .pegaGerencia(idGerencia)
                .then((res) => {

                    this.infoGerencia = res;
                    console.log("objeto da gerencia é ", this.infoGerencia)

                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        editarNucleo() {
        this.alerta = {};
        this.nucleoClonado= {};
        this.idNucleoClonado= "";
            document.getElementById("btn-salvar").textContent = "Alterar"
          

        },

        validaCampo() {

            if (this.nucleo.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.nucleo.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.nucleo.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;

            }
            else if (!this.nucleo.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
            if (this.nucleo.gerencia) {
                this.stateValida.gerencia = true;
                this.validado =  true;

            }
            else if (!this.nucleo.gerencia) {
                this.stateValida.gerencia = false;
                this.validado =  false;
            }
            return this.validado;

        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        addNucleo() {
            let itemAbono = {};
            itemAbono.Período = ` ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataInicio)))} a ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataFim)))}`;
            setTimeout(() => {
                this.items.push(itemAbono)
                localStorage.setItem("abono", JSON.stringify(this.items));
            }, 900)

        },

        adicionarNucleo() {
            this.alert = {};
        
       
        

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarNucleo();
            } else {
                if (this.validaCampo() == true) {
                    this.nucleo.idLogado = this.usuarioLogado.id


                    nucleoService
                        .salvarNucleo(this.nucleo)
                        .then((res) => {

                            
                            this.alert.mensagem = res.mensagem;
                             this.alert.variant = "success";
                             this.showAlert()

                            this.nucleoClonado = JSON.parse(JSON.stringify(this.nucleo));                           
                            document.getElementById("btn-salvar").textContent = "Alterar"
                            this.listarNucleo(this.nucleo.gerencia);
                            this.listarGerencia();

                        })
                        .catch(() => {

                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }

        },
        listarGerencia() {
            let idDiretoria = 1 // é o código da divep
            gerenciaService
                .listarGerencia(idDiretoria).then((res) => {
                    this.tipoGerencia = res;
                    console.log("id da gerencia é", this.tipoGerencia)                    

                });
        },
        async listarNucleo(idGerencia) {

            if (!idGerencia) {
                idGerencia = document.getElementById("refGerenciaDrop").value
                console.log("o ide gerencia da combo é", idGerencia)
                if (idGerencia != 0) {
                    await nucleoService
                        .listarNucleo(idGerencia).then((res) => {
                            console.log("res do nucleo", res)
                        
                        if(res.length > 0) {
                             this.itemsNucleo = res;

                        } else {
                            this.itemsNucleo = {}
                        }
                           


                        }).catch((erro) => {
                            console.log(erro)
                             this.itemsNucleo = {}


                        });

                } else {
                    setTimeout(() => {
                        this.itemsNucleo = {};
                        this.nucleo.nome = "";
                        this.nucleo.sigla = "";
                        document.getElementById('nomeNucleo').disabled = true;
                        document.getElementById('siglaNucleo').disabled = true;

                    }, 200)
                }

            } else {
                await nucleoService
                    .listarNucleo(idGerencia).then((res) => {
                        console.log("res do nucleo", res)
                        this.itemsNucleo = res;
                        idGerencia = "";

                    }).catch((erro) => {
                        console.log(erro)
                    });
            }

        },
        habilitarCampo() {
            if (this.nucleo.gerencia) {
                document.getElementById('nomeNucleo').disabled = false;
                document.getElementById('siglaNucleo').disabled = false;
                 this.nucleo.nome = "";
                this.nucleo.sigla = "";
                document.getElementById("btn-salvar").textContent = "Salvar"

            } else {
                this.nucleo.nome = "";
                this.nucleo.sigla = "";
                this.itemsNucleo = {};
                document.getElementById('nomeNucleo').disabled = true;
                document.getElementById('siglaNucleo').disabled = true;
            }

        },

    },


}

</script>
<style scoped>



</style>
