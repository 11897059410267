import axios from "axios";

export const abonoService = {

    salvarAbono(abono) {
        return new Promise((resolve, reject) => {

          
          axios.post(`${process.env.VUE_APP_URL_BASE_BACK_END}/abono`, abono,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
      listarAbono(vinculo) {
        return new Promise((resolve, reject) => {
          
          axios.get(`${process.env.VUE_APP_URL_BASE_BACK_END}/abono/listar/${vinculo}`,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
      alterarAbono(id,abono) {
        return new Promise((resolve, reject) => {
         
          axios.put(`${process.env.VUE_APP_URL_BASE_BACK_END}/abono/${id}`, abono,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
  
}