import axios from "axios";

export const nucleoService = {
  salvarNucleo(nucleo) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/nucleo`, nucleo,)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarNucleo(id, nucleo) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/nucleo/${id}`,
          nucleo,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarNucleo(idGerencia) {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/nucleo/listar/${idGerencia}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pegaNucleo(idNucleo){
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/nucleo/${idNucleo}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
