import axios from "axios";

export const feriasService = {
  
  salvarFerias(ferias) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/ferias`, ferias,)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarFerias(id, ferias) {
    return new Promise((resolve, reject) => {
      
      console.log("objeto de férias", ferias);
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/ferias/${id}`,
          ferias,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarFerias(vinculo) {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/ferias/listar/${vinculo}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
