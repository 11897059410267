import axios from "axios";

export const diretoriaService = {
  salvarDiretoria(diretoria) {
    return new Promise((resolve, reject) => {
      
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/diretoria`,
          diretoria,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarDiretoria(idLogado) {
    return new Promise((resolve, reject) => {
      
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/diretoria?idLogado=${idLogado}`,)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
