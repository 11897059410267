<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo" v-if="!$route.params.parametro">Abono</h2>
            <h2 class="card__titulo" v-else>Abono</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
                <div class="col-lg-12">

                    <div class="row">

                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Ano de exercicio<strong style="color:red">*</strong></label>
                               
                                <select class="form-control mb-3 input-select" id="selectAnoExercicio"
                                    v-model="abono.exercicio" :state="stateValida.exercicio" v-on:change="validaCampo">
                                    <option value="0"></option>
                                    <option v-for="ano in itemsAnoExercicio" :key="ano.id" :value="ano.id">{{
                                            ano.nome
                                    }}</option>
                                </select>
                                <div class="invalid-feedback">Campo ano exercício é obrigatório!</div>

                            </div>
                        </div>


                        <div class="col-lg-4" role="group">
                            <label for="input-live">Data Início<strong style="color:red">*</strong></label>
                            <b-form-input v-model="abono.inicio" id="input-live" type="date" :state="stateValida.inicio"
                                v-on:change="validaCampo, calculaDiasAfastamento()"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Insira a data de inicio do abono" trim></b-form-input>
                            <div class="invalid-feedback">Campo data início é obrigatório!</div>
                        </div>

                        <div class="col-lg-4" role="group">
                            <label for="input-live">Data Fim<strong style="color:red">*</strong></label>
                            <b-form-input v-model="abono.termino" id="input-live" type="date"
                                :state="stateValida.termino" v-on:change="validaCampo, calculaDiasAfastamento()"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Insira a data de fim do abono" trim></b-form-input>
                            <div class="invalid-feedback">Campo data fim é obrigatório!</div>
                        </div>
                        <div class="col-lg-1" role="group">
                            <label for="input-live">Dias:</label>
                            <b-form-input v-model="abono.dias" id="input-live" type="text"
                                aria-describedby="input-live-help input-live-feedback" placeholder="Dias" trim disabled>
                            </b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>
                    </div>

                    <div class="row">


                        <div class="col-lg-6">
                            <div role="group">
                                <label for="input-live">Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe o Processo" trim v-model="abono.processoSEI" SIZE="30" MAXLENGTH = "30"
                                    :state="stateValida" v-on:change="validaCampo" ></b-form-input>
                               
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div role="group">
                                <label for="input-live">Url do Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe a url" trim v-model="abono.urlSEI" :state="stateValida"
                                    v-on:change="validaCampo"></b-form-input>
                                <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="btn-group" role="group">

                        <div class="container-buttons-salvar">
                            <b-button id="btn-salvarAbono" @click="adicionarAbono" variant="outline-success"
                                >Salvar</b-button>
                            <!-- <b-button id="btn-salvarAbono" @click="adicionarAbono" variant="outline-success"
                                v-if="!($route.params.parametro || abono.id)">Salvar</b-button> -->
                            <!-- <b-button @click="alterarAbono" variant="outline-success" v-else>Alterar</b-button> -->
                        </div>
                        &nbsp;
                        <div class="col-lg-1">

                            <div class="container-buttons-salvar">

                                <b-button variant="outline-success" @click="limparObjeto()">Novo</b-button>

                            </div>
                        </div> &nbsp;
                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo">Lista de Abonos</h2>

                    <table class="table table-striped">
                        <thead>
                            <tr>

                                <th>Ano de Exercício</th>
                                <th>Período</th>
                                <th>Dias</th>
                                <th>Ação</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="a in itemsAbono" :key="a.id">

                                <td> {{ a.exercicio }}</td>
                                <td>{{ a.inicio }} a {{ a.termino }}</td>
                                <td> {{ a.dias }}</td>
                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success" @click="editarAbono(a)">Editar
                                        </b-button>
                                    </div>
                                   
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { abonoService } from "@/service/abonoService";
import { constanteService } from "@/service/constanteService";

export default {
    beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  },
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                exercicio: null,
                inicio: null,
                termino: null,
                vinculo: null,
                dias: null,
            },
            abono: {
                exercicio: null,
                inicio: null,
                termino: null,
                dias: null,
                vinculo: null,
                tipoAfastamento: "NAO_INFORMADO",
                processoSEI: null,
                urlSEI: null,
                idLogado: "",
            },
            validado:"",
            mensagem: "",
            itemsAbono: [],
            itemsAnoExercicio: [],
            alert: {
                mensagem: "",
                variant: ""
            },
        }
    },
    mounted() {
        this.inicializar();
    },

    methods: {
        async inicializar() {
            await this.verificaLocalStore();
            this.listarAnosExercicio();
            this.listarAbono();
            if (this.$route.params.parametro) {
            document.getElementById("btn-salvarAbono").textContent = "Alterar"

                this.abono = JSON.parse(this.$route.params.parametro)
            }
            this.abono.inicio = this.inverterCampoData(this.abono.inicio)
            this.abono.termino = this.inverterCampoData(this.abono.termino)
        },
        limparObjeto() {       
            this.abono = {};
         document.getElementById("btn-salvarAbono").textContent = "Salvar"
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        listarAnosExercicio() {
            constanteService
                .listarAnosExercicio().then((res) => {
                    this.itemsAnoExercicio = res;
                });
        },
        calculaDiasAfastamento() {

            let start = this.abono.inicio;
            let and = this.abono.termino;
            if (start && and) {
                start = new Date(start)
                and = new Date(and)

                let diffInTime = Math.abs(and - start)
                let timeInOneDay = 1000 * 60 * 60 * 24
                let diffInDays = diffInTime / timeInOneDay + 1

                this.abono.dias = diffInDays;


                return diffInDays

            }
            this.abono.dias = "";

        },
        validaCampo() {

            if (this.abono.exercicio) {
                this.stateValida.exercicio = true;
                this.validado =  true;

            }
            else if (!this.abono.exercicio) {
                this.stateValida.exercicio = false;
                this.validado =  false;
            }
            if (this.abono.inicio) {
                this.stateValida.inicio = true;
                this.validado =  true;

            }
            else if (!this.abono.inicio) {
                this.stateValida.inicio = false;
                this.validado =  false;
            }
            if (this.abono.termino) {
                this.stateValida.termino = true;
                this.validado =  true;

            }
            else if (!this.abono.termino) {
                this.stateValida.termino = false;
                this.validado =  false;
            }
            if (this.abono.dias) {
                this.stateValida.dias = true;
                this.validado =  true;

            }
            else if (!this.abono.dias) {
                this.stateValida.dias = false;
                this.validado =  false;
            }
            return this.validado

        },
        editarAbono(a) {
         document.getElementById("btn-salvarAbono").textContent = "Alterar"

            this.abono = JSON.parse(JSON.stringify(a))

            setTimeout(() => {
                this.abono.inicio = this.inverterCampoData(a.inicio);
                this.abono.termino = this.inverterCampoData(a.termino);

            }, 200)

        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        addAbono() {
            let itemAbono = {};
            itemAbono.Período = ` ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataInicio)))} a ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataFim)))}`;
            setTimeout(() => {
                this.items.push(itemAbono)
                localStorage.setItem("abono", JSON.stringify(this.items));
            }, 900)

        },
        adicionarAbono() {
            this.alert = {};

         if (document.getElementById("btn-salvarAbono").textContent == "Alterar") {
                this.alterarAbono();
            } else {
            this.abono.idLogado = this.usuarioPagina.id;

            if (this.validaCampo() == true) {
                this.abono.vinculo = this.usuarioPagina.vinculos[0].id;
                abonoService
                    .salvarAbono(this.abono)
                    .then((res) => {
                        console.log(res);
                        this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                        this.listarAbono();
                        
                        document.getElementById("btn-salvarAbono").textContent = "Alterar"
                    })
                    .catch(() => {

                        
                    });
                }else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }

        },
        alterarAbono() {

            if (this.validaCampo() == true) {

                this.abono.inicio = this.inverterCampoData(this.abono.inicio)
                this.abono.termino = this.inverterCampoData(this.abono.termino)

                abonoService
                    .alterarAbono(this.abono.id, this.abono)
                    .then((res) => {
                        setTimeout(() => { this.listarAbono() }, 200)
                      
                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                    })
                    .catch(() => {
                        console.log("deu erro no serviço")
                       
                    });
            } else {
                this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
                this.alert.variant = "danger";
                this.showAlert();

            }
        },
        limparObjetoAbono() {

            this.abono.exercicio = "";
            this.abono.inicio = "";
            this.abono.termino = "";
            this.abono.dias = "";
            this.abono.vinculo = "";
            // this.abono.tipoAfastamento = "";
            this.abono.processoSEI = "";
            this.abono.urlSEI = "";
        },
        listarAbono() {
            
            let vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            abonoService
                .listarAbono(vinculo).then((res) => {
                    this.itemsAbono = res;
                    console.log("Abono  é", this.itemsAbono)
                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
    },


}

</script>
