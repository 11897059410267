<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo" v-if="!$route.params.parametro"> Publicação </h2>
            <h2 class="card__titulo" v-else> Publicação</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
           
            <div class="row">

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Título<strong style="color:red">*</strong></label>
                                <b-form-input id="input-live" :state="stateValida.titulo" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    trim v-model="publicacao.titulo">
                                </b-form-input>
                                <div class="invalid-feedback">Campo Título é obrigatório!</div>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    trim v-model="publicacao.processoSEI" SIZE="30" MAXLENGTH = "30">
                                </b-form-input>
                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Url do Processo SEI</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                  trim v-model="publicacao.urlSEI">
                                </b-form-input>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Local Publicação</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                   v-model="publicacao.localPublicacao">
                                </b-form-input>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Data Publicação</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback" type="date"
                                   class="datepicker"
                                    data-date-format="mm/dd/yyyy" trim v-model="publicacao.dataPublicacao">
                                </b-form-input>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Tipo Publicação<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.tipoPublicacao" v-on:change="validaCampo"
                                    v-model="publicacao.tipoPublicacao" >
                                    <option value=""></option>
                                    <option v-for="tp in itensTipoPublicacao" :key="tp.id"
                                        :value="tp.id">{{
                                            tp.nome
                                        }}</option>
                                </select>
                                <div class="invalid-feedback">Campo tipo publicação é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Tipo Participação<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.tipoParticipacao" v-on:change="validaCampo"
                                    v-model="publicacao.tipoParticipacao" >
                                    <option value=""></option>
                                    <option v-for="tpc in itensTipoParticipacao" :key="tpc.id"
                                        :value="tpc.id">{{
                                            tpc.nome
                                        }}</option>
                                </select>
                                <div class="invalid-feedback">Campo tipo participacão é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Status<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.status" v-on:change="validaCampo"
                                    v-model="publicacao.status">
                                    <option value=""></option>
                                    <option v-for="st in itensStatusPublicacao" :key="st.id"
                                        :value="st.id">{{
                                            st.nome
                                        }}</option>
                                </select>
                                <div class="invalid-feedback">Campo tipo de status é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Agravo<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.agravos" v-on:change="validaCampo"
                                    v-model="publicacao.agravos">
                                    <option value=""></option>
                                    <option v-for="ag in itensAgravo" :key="ag.id"
                                        :value="ag.id">{{
                                            ag.nome
                                        }}</option>
                                </select>
                                <div class="invalid-feedback">Campo agravo é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Região Administrativa<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.ra" v-on:change="validaCampo"
                                    v-model="publicacao.ra">
                                    <option value=""></option>
                                    <option v-for="ra in itensRa" :key="ra.id"
                                        :value="ra.id">{{
                                            ra.nome
                                        }}</option>
                                </select>
                                <div class="invalid-feedback">Campo região administrativa é obrigatório!</div>
                            </div>
                        </div>
                        

                    </div>
                    <div class="row">  
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-1">
                            <!--   v-if="!($route.params.parametro || curso.id)" -->
                            <div class="container-buttons-salvar">
                                <b-button id="btn-salvarCurso" @click="adicionarPublicacao" variant="outline-success">Salvar
                                </b-button>
                                <!-- <b-button @click="alterarCurso" variant="outline-success" v-else>Alterar</b-button> -->
                            </div>
                        </div>
                        <div class="col-lg-1">

                            <div class="container-buttons-salvar">

                                <b-button variant="outline-success" @click="limparObjetoPublicacao()">Novo</b-button>

                            </div>
                        </div>
                        <div class="col-lg-1">
                            <div class="container-buttons-salvar">
                                <router-link to="/inicio">
                                    <b-button variant="outline-success">Voltar</b-button>
                                </router-link>
                            </div>

                        </div>
                    </div>


                </div>



            </div>
        </div>
        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Lista de Publicações</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Título</th>                                                             
                                <th>Status</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>
                            <!--     <td><router-link to="/ferias">{{ ferias.exercicio }}</router-link></td> -->
                            <tr v-for="p in itensPublicacao" :key="p.id">

                                <td>{{ p.titulo }}</td>                               
                                <td> {{ p.status }}</td>
                                


                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="editarPublicacao(p)">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { publicacaoService } from "@/service/publicacaoService";
import { agravoService } from "@/service/agravoService";

import { constanteService } from "@/service/constanteService";
import { regionalSaudeService } from "@/service/regionalSaudeService";
import { regiaoAdministrativaService } from "@/service/regiaoAdministrativaService";

export default {
   /*  beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  }, */
    data() {
        return {
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            menssagem: "",
            stateValida: {
                titulo: null,                
                localPublicacao:"",
                dataPublicacao:"",
                tipoPublicacao:"",
                tipoParticipacao:"",
                status:"",
                agravos:"",
                ra:"", 
            },
            publicacao: {
                titulo: null,
                processoSEI:"",
                urlSEI:"",
                localPublicacao:"",
                dataPublicacao:"",
                tipoPublicacao:"",
                tipoParticipacao:"",
                status:"",
                agravos:[],
                ra:"",                
                usuario: null,               
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            publicacaoClonado:{},
            idPublicacaoClonado: "",
           
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            mensagem: "",
            itensPublicacao: [],
            itensTipoParticipacao: [],
            itensTipoPublicacao: [],
            itensStatusPublicacao: [],
            itensAgravo: {},
            itensRa: [],
            itensRegionalSaude:[],
            validado:"",
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            await this.verificaLocalStore()                     
            this.listarTipoPublicacao();
            this.listarTipoParticipacao();
            
            this.listarStatusPublicacao();
            this.listarAgravo();
            this.listarRa();
            this.listarPublicacao(); 
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        validaCampo() {

            if (this.publicacao.titulo) {
                this.stateValida.titulo = true;
                this.validado =  true;

            }
            else if (!this.publicacao.titulo) {
                this.stateValida.titulo = false;
                this.validado =  false;
            }
            if (this.publicacao.localPublicacao) {
                this.stateValida.localPublicacao = true;
                this.validado =  true;

            }
            else if (!this.publicacao.localPublicacao) {
                this.stateValida.localPublicacao = false;
                this.validado =  false;
            }
            if (this.publicacao.dataPublicacao) {
                this.stateValida.dataPublicacao = true;
                this.validado =  true;

            }
            else if (!this.publicacao.dataPublicacao) {
                this.stateValida.dataPublicacao = false;
                this.validado =  false;
            }
            if (this.publicacao.tipoPublicacao) {
                this.stateValida.tipoPublicacao = true;
                this.validado =  true;

            }
            else if (!this.publicacao.tipoPublicacao) {
                this.stateValida.tipoPublicacao = false;
                this.validado =  false;
            }
            if (this.publicacao.tipoParticipacao) {
                this.stateValida.tipoParticipacao = true;
                this.validado =  true;

            }
            else if (!this.publicacao.tipoParticipacao) {
                this.stateValida.tipoParticipacao = false;
                this.validado =  false;
            }
            if (this.publicacao.status) {
                this.stateValida.status = true;
                this.validado =  true;

            }
            else if (!this.publicacao.status) {
                this.stateValida.status = false;
                this.validado =  false;
            }
            if (this.publicacao.agravos) {
                this.stateValida.agravos = true;
                this.validado =  true;

            }
            else if (!this.publicacao.agravos) {
                this.stateValida.agravos = false;
                this.validado =  false;
            }
            if (this.publicacao.ra) {
                this.stateValida.ra = true;
                this.validado =  true;

            }
            else if (!this.publicacao.ra) {
                this.stateValida. ra = false;
                this.validado =  false;
            }
            return  this.validado;


        },
        editarPublicacao(p) {
           // this.publlicacaoClonado = {};

           this.publicacao = JSON.parse(JSON.stringify(p))

            setTimeout(() => {
                
                this.publicacao.dataPublicacao =  this.inverterCampoData(this.publicacao.dataPublicacao)

            }, 200)


            this.idPublicacaoClonado = "";
            document.getElementById("btn-salvarCurso").textContent = "Alterar"
            this.alerta = {};
            this.publlicacaoClonado.titulo = "",
            this.publlicacaoClonado.processoSEI = "",
            this.publlicacaoClonado.urlSEI = "",
            this.publlicacaoClonado.localPublicacao = "",
            this.publlicacaoClonado.dataPublicacao = "",
            this.publlicacaoClonado.tipoPublicacao = "",
            this.publlicacaoClonado.tipoParticipacao = "",
            this.publlicacaoClonado.status = "",
            this.publlicacaoClonado.agravos = "",
            this.publlicacaoClonado.ra = "",                
            this.publlicacaoClonado.usuario = "",             
            this.publlicacaoClonado.idLogado = ""

        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        
        adicionarPublicacao() {
            this.alert = {};

            if (document.getElementById("btn-salvarCurso").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarPublicacao();
                }, 200)
            } else {

                if (this.validaCampo() == true) {
                    this.publicacao.usuario = this.usuarioPagina.id;
                    this.publicacao.idLogado = this.usuarioLogado.id
                    this.publicacao.agravos = [this.publicacao.agravos];
                    
                    console.log("qual é o usuário", this.publicacao)
                    publicacaoService
                        .salvarPublicacao(this.publicacao)
                        .then((res) => {
                           

                            this.publicacaoClonado = JSON.parse(JSON.stringify(this.publicacao));
                            console.log("nova publicacao clonado", this.publicacaoClonado )
                           
                            this.listarPublicacao();
                           
                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                            document.getElementById("btn-salvarCurso").textContent = "Alterar";
                        })
                        .catch(() => {

                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarPublicacao() {
            this.alert = {};
            this.publicacao.usuario = this.usuarioPagina.id;

          
            for (let index = 0; index < this.itensPublicacao.length; index++) {

            if(this.publicacaoClonado.titulo === this.itensPublicacao[index].titulo){
                this.idPublicacaoClonado = this.itensPublicacao[index].id
            console.log(this.idPublicacaoClonado)
                break;
            }
               
                
          }

        
        if(this.idPublicacaoClonado){ 
            this.publicacao.id = this.idPublicacaoClonado;
            
        } 
        this.publicacao.dataPublicacao =  this.inverterCampoData(this.publicacao.dataPublicacao)
            this.publicacao.idLogado = this.usuarioLogado.id;
            this.publicacao.usuario = this.usuarioPagina.id;
            publicacaoService
                .alterarPublicacao(this.publicacao.id, this.publicacao)
                .then((res) => {
                    // this.limparObjetoCurso();

                 

                    this.listarPublicacao();
                  this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                })
                .catch(() => {
                    console.log("deu erro no serviço")
                    console.log("data inicio das ferias 2", this.publicacao)
                    
                });
        },
        listarPublicacao() {
            let usuario = this.usuarioPagina.id;

            publicacaoService
                .listarPublicacao(usuario).then((res) => {
                    this.itensPublicacao = res;
                    console.log("cursos é", this.itensPublicacao)
                });
        },
        limparObjetoPublicacao() {
            this.publicacaoClonado= {};
            this.idPublicacaoClonado= "";
            setTimeout(() => {
                document.getElementById("btn-salvarCurso").textContent = "Salvar"

            }, 200)

            this.publicacao = {};
            this.alerta = {};
        },
       /*  listarUF() {
            publicacaoService
                .listarUF().then((res) => {
                    this.UF = res;
                });
        }, */
        listarTipoPublicacao() {
            constanteService
                .listarTipoPublicacao().then((res) => {
                    this.itensTipoPublicacao = res;
                });
        },
        listarTipoParticipacao() {
            constanteService
                .listarTipoParticipacao().then((res) => {
                    this.itensTipoParticipacao = res;
                    console.log("itens da participacao",  this.itensTipoParticipacao)
                });
        },
        listarStatusPublicacao() {
            constanteService
                .listarStatusPublicacao().then((res) => {
                    this.itensStatusPublicacao = res;
                    console.log("itens da publicacao",  this.itensStatusPublicacao)
                });

        },
        listarAgravo() {
           agravoService
                .listarAgravo().then((res) => {
                    this.itensAgravo = res;
                    console.log("itens do agravo",  this.itensAgravo)
                });

        },
     
        listarRa() {
        regiaoAdministrativaService
        .listarTodasRas().then((res) => {
          this.itensRa = res;
          
        });
    },
       listarRegionalSaude() {
            regionalSaudeService
                .listarRegionalSaude().then((res) => {
                    this.itensRegionalSaude = res;
                });

        },
      
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

    },
}

</script>