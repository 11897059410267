<template>
    <div class="container">
        <!-- Formulários de entrada-->
        <div class="card">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3">
                            <label for="input-live">Pesquisar:</label>
                            <div>

                                <Autocomplete :delay="500" :disabled="!flagNovo" v-model="termoDaPesquisa"
                                    :options="listaDeUsuariosPesquisados" label-key="label" value-key="id"
                                    placeholder="Informe Cpf, Id ou Nome" ref="pesquisar" class="campo1" />

                                <button :disabled="!flagNovo" class="btn btn-outline-success campo2" type="button"
                                    @click="carregarUsuarioPesquisado"><i class="fa fa-search"></i></button>

                            </div>

                        </div>
                        <div class="col-lg-1">
                            <div class="container-buttons container-buttons--colunas">
                                <b-button id="btn-pequisa-novo" variant="outline-success" @click="limpaCampos">Novo
                                </b-button>

                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div role="group">
                                <label for="input-live">Diretoria:</label>
                                <select class="form-control mb-3 input-select">
                                    <option
                                        :selected="diretoria.id == usuarioPagina.vinculos[vinculoSelecionado.indexVinculo].setor.lotacao.diretoria"
                                        v-for="diretoria in listaDiretoria" :key="diretoria.id" :value="diretoria.id">{{
                                        diretoria.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div role="group">
                                <label for="input-live">Gerência:</label>
                                <select class="form-control mb-3 input-select" id="refGerenciaDrop"
                                    v-on:change="pegaValorCampo">
                                    <option value="0"></option>
                                    <option
                                        :selected="gerencia.id == usuarioPagina.vinculos[vinculoSelecionado.indexVinculo].setor.lotacao.gerencia"
                                        v-for="gerencia in listaGerencia" :key="gerencia.id" :value="gerencia.id">{{
                                        gerencia.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div role="group">
                                <label for="input-live">Núcleo:</label>
                                <select class="form-control mb-3 input-select" id="refNucleoDrop"
                                    v-on:change="pegaValorCampo">
                                    <option value="0"></option>
                                    <option
                                        :selected="nucleo.id == usuarioPagina.vinculos[vinculoSelecionado.indexVinculo].setor.lotacao.nucleo"
                                        v-for="nucleo in listaNucleo" :key="nucleo.id" :value="nucleo.id">{{
                                        nucleo.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div role="group">
                                <label for="input-live">Área Técnica:</label>
                                <select class="form-control mb-3 input-select" id="refSetorDrop"
                                    v-on:change="setorSelecionadoLocalStore()">
                                    <option value="0"></option>
                                    <option
                                        :selected="setor.id == usuarioPagina.vinculos[vinculoSelecionado.indexVinculo].setor.id"
                                        v-for="setor in listaSetor" :key="setor.id" :value="setor.id">{{
                                        setor.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2">
                            <div role="group">
                                <label for="input-live">Vínculo:</label>
                                <select :disabled="flagNovo" class="form-control mb-3 input-select" id="DropdownVinculo"
                                    v-on:change="trocaVinculo">
                                    <option :selected="vinculo.idVinculo == vinculoSelecionado.idVinculo"
                                        v-for="vinculo in itemsVinculo" :key="vinculo.indexVinculo"
                                        :value="vinculo.indexVinculo">{{ vinculo.matricula }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Nome Servidor:</label>
                                <b-form-input :disabled="!flagNovo" v-model="usuarioPagina.nomeSocial" id="input-live"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira o nome completo" trim></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div role="group">
                                <label id="cpf" for="input-live">CPF:</label>
                                <b-form-input :disabled="!flagNovo" v-model="usuarioPagina.cpf" id="input-live"
                                    aria-describedby="input-live-help input-live-feedback" placeholder="Insira o CPF"
                                    trim></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div role="group">
                                <label for="input-live">Matricula:</label>
                                <b-form-input :disabled="!flagNovo"
                                    v-model="usuarioPagina.vinculos[vinculoSelecionado.indexVinculo].matricula"
                                    id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Matricula" SIZE="5" MAXLENGTH="12">
                                </b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="container-buttons container-buttons--colunas">
                                <b-button v-if="!flagNovo" id="btn-salvar-alterar-lotacao" @click="alterarLotacao"
                                    variant="outline-success">Alterar Lotação
                                </b-button>
                                <b-button v-show="flagNovo" id="btn-salvar-alterar" @click="cadastrarUsuario"
                                    variant="outline-success">
                                    Cadastrar usuário
                                </b-button>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
            <p>{{ alert.mensagem }}</p>
            <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
            </b-progress>
        </b-alert>

        <!-- Primeira parte -->
        <div class="row">
            <div class="col-lg-4">
                <div class="card">
                    <h3 class="card__titulo">Férias</h3>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Exercício</th>
                                <th>Período</th>
                                <th>Dias</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            <tr v-for="ferias in itemsFerias" :key="ferias.id">
                                <td>
                                    <router-link
                                        :to="{ name: 'ferias', params: { parametro: JSON.stringify(ferias) } }">{{
                                        ferias.exercicio
                                        }}</router-link>
                                </td>
                                <td>{{ ferias.inicio }} a {{ ferias.termino }} </td>
                                <td> {{ ferias.dias }}</td>
                               
                            </tr>
                        </tbody>
                    </table>
                    <div class="container-buttons">
                        <b-button type="button" @click="incluirFeriasServidor" variant="outline-success">Incluir
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <h3 class="card__titulo">Abonos</h3>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Período</th>
                                <th>Dias</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="abono in itemsAbono" :key="abono.id">
                                <td>
                                    <router-link :to="{ name: 'abono', params: { parametro: JSON.stringify(abono) } }">
                                        {{
                                        abono.inicio
                                        }} a {{ abono.termino }}</router-link>
                                </td>
                                <td> {{ abono.dias }}</td>
                               
                            </tr>
                        </tbody>
                    </table>
                    <div class="container-buttons">
                        <b-button type="button" @click="incluirAbonoServidor" variant="outline-success">Incluir
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <h3 class="card__titulo">Licenças e outros afastamentos</h3>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Período</th>
                                <th>Dias</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="afastamento in itemsAfastamento" :key="afastamento.id">
                                <td> {{
                                afastamento.tipoAfastamento
                                }}</td>
                                <td>
                                    <router-link
                                        :to="{ name: 'afastamento', params: { parametro: JSON.stringify(afastamento) } }">
                                        {{
                                        afastamento.inicio
                                        }} a {{ afastamento.termino }}</router-link>
                                </td>
                                <td>{{ afastamento.dias }}</td>


                               
                            </tr>

                        </tbody>
                    </table>

                    <div class="container-buttons">
                        <b-button type="button" @click="incluirAfastamentoServidor" variant="outline-success">Incluir
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Última parte-->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-8">
                            <h3 class="card__titulo">Relação de Servidores</h3>
                        </div>
                        <div class="col-lg-4">
                            <div>
                                <b-button type="button" @click="consultarServidor" variant="outline-success">Consultar
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Nº</th>
                                <th>CPF</th>
                                <th>Nome do Servidor</th>
                                <th>Próximo Afastamento</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="servidores in listarServidores" :key="servidores.id">
                                <td>{{ servidores.ordem }}</td>
                                <td>{{ servidores.cpf }} </td>
                                <td><a href="#" @click="buscarUsuario(servidores.id), flagNovo = false"> {{
                                servidores.nomeSocial
                                }} </a>
                                </td>
                                <td> {{ servidores.proximoAfastamento }}</td>

                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { informacaoFuncionalService } from "@/service/informacaoFuncionalService";
import { diretoriaService } from "@/service/diretoriaService";
import { gerenciaService } from "@/service/gerenciaService";
import { nucleoService } from "@/service/nucleoService";
import { setorService } from "@/service/setorService";
import { usuarioService } from "@/service/usuarioService";
import { feriasService } from "@/service/feriasService";
import { abonoService } from "@/service/abonoService";
import { afastamentoService } from "@/service/afastamentoService";
import Autocomplete from "@/components/Autocomplete";

export default {
    data() {
        return {
            alert: {
                mensagem: "",
                variant: ""
            },
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            usuarioLogado: {},
            usuarioPagina: {},
            novoUsuario: {
                nomeSocial: "",
                cpf: "",
                matricula: "",
                setor: null,
                idLogado: "",
            },

            vinculoSelecionado: {
                indexVinculo: "",
                idVinculo: "",
                matricula: ""
            },
            setorSelecionado: {
                idSetor: "",
            },

            termoDaPesquisa: "",
            listaDePesquisados: [],
            listaDeUsuariosPesquisados: [],
            usuarioPesquisado: {},

            flagNovo: false,

            listaDiretoria: [],
            listaGerencia: [],
            listaNucleo: [],
            listaSetor: [],

            itemsFerias: [],
            itemsAbono: [],
            itemsAfastamento: [],
            itemsVinculo: [],
            listarServidores: [],
        }
    },
    components: {
        Autocomplete,
    },
    methods: {
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        consultarServidor() {
            var idDiretoria = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.diretoria;
            var idGerencia = document.getElementById("refGerenciaDrop").value;
            var idNucleo = document.getElementById("refNucleoDrop").value;
            var idSetor = document.getElementById("refSetorDrop").value;
            let idLogado = this.usuarioLogado.id;
            console.log("IDS de pesquisa", idDiretoria, idGerencia, idNucleo, idSetor);
            usuarioService
                .buscarRelacaoServidores(idDiretoria, idGerencia, idNucleo, idSetor, idLogado).then((res) => {
                    this.listarServidores = res;
                    console.log("setor é", this.listarServidores)
                });
        },
        async inicialiazar() {
            await this.verificaLocalStore();
            await this.listarVinculo();
            await this.listarDiretoria();
            await this.listarGerencia();
            await this.listarNucleo(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.gerencia);
            var idDiretoria = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.diretoria;
            var idGerencia = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.gerencia;
            var idNucleo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.nucleo;
            await this.listarSetor(idDiretoria, idGerencia, idNucleo);
            this.listarFerias();
            this.listarAbono();
            this.listarAfastamento();
            await this.setorSelecionadoLocalStore();
        },
        limpaAlert() {
            setTimeout(function () {
                document.getElementsByName(".alert").alert('close');
                console.log("Entrei aqui")
            }, 5000)
        },
        pegaValorCampo() {
            var novoIdGerencia = document.getElementById("refGerenciaDrop").value;
            console.log("NOVO ID GERENCIA", novoIdGerencia)
            this.listarNucleo(novoIdGerencia);
            var novoIdNucleo = document.getElementById("refNucleoDrop").value;
            var idDiretoria = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.diretoria;
            this.listarSetor(idDiretoria, novoIdGerencia, novoIdNucleo);
        },
        limpaCampos() {
            this.alert = {};
            this.flagNovo = true;
            this.usuarioPagina.cpf = "";
            this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id = "";
            this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].matricula = "";
            this.usuarioPagina.nomeSocial = "";
            this.usuarioPagina.id = "";
            this.itemsFerias = [];
            this.itemsAbono = [];
            this.itemsAfastamento = [];
            this.itemsVinculo = [];
            localStorage.removeItem("vinculoSelecionado");
            this.listarServidores = [];
            this.termoDaPesquisa = "";
        },
        async listarDiretoria() {
            await diretoriaService
                .listarDiretoria(this.usuarioLogado.id).then((res) => {
                    this.listaDiretoria = res;
                    console.log("diretoria", this.listaDiretoria)
                });
        },
        async listarGerencia() {
            var idDiretoria = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.diretoria;
            await gerenciaService
                .listarGerencia(idDiretoria).then((res) => {
                    this.listaGerencia = res;
                    console.log("gerencias da combo", this.listaGerencia)
                });
        },
        async listarNucleo(idGerencia) {
            await nucleoService
                .listarNucleo(idGerencia).then((res) => {
                    this.listaNucleo = res;
                    console.log("nucleos", this.listaNucleo)
                }).catch(erro => {
                    console.log(erro);
                    if (erro.response && erro.response.status == 404) {
                        this.listaNucleo = []
                    }
                });
        },
        async listarSetor(idDiretoria, idGerencia, idNucleo) {
            await setorService
                .listarSetor(idDiretoria, idGerencia, idNucleo).then((res) => {
                    this.listaSetor = res;
                    console.log("setores", this.listaSetor)
                }).catch(erro => {
                    console.log(erro);
                    if (erro.response && erro.response.status == 404) {
                        this.listaSetor = []
                    }
                });
        },
        listarFerias() {
            let vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            feriasService
                .listarFerias(vinculo).then((res) => {
                    this.itemsFerias = res;


                }).catch(erro => {
                    console.log("ERRO", erro);
                    if (erro.response.status == 404) this.itemsFerias = [];
                });
        },
        listarAbono() {
            let vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            abonoService
                .listarAbono(vinculo).then((res) => {
                    this.itemsAbono = res;
                    console.log("abono é", this.itemsAbono)
                }).catch(erro => {
                    console.log("ERRO", erro);
                    if (erro.response.status == 404) this.itemsAbono = [];
                });
        },
        listarAfastamento() {
            let vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            afastamentoService
                .listarAfastamento(vinculo).then((res) => {
                    this.itemsAfastamento = res;
                    console.log("afastamento é", this.itemsAfastamento)
                }).catch(erro => {
                    console.log("ERRO", erro);
                    if (erro.response.status == 404) this.itemsAfastamento = [];
                });
        },
        incluirFeriasServidor() {
            this.alert = {};
            if (this.vinculoSelecionado.idVinculo && document.getElementById("DropdownVinculo").value) {
                this.$router.push({
                    path: "ferias",
                    params: {
                        parametros: "",
                    },
                })
            } else {
                this.alert.mensagem = "O vínculo deve estar selecionado.";
                this.alert.variant = "warning";
                this.showAlert();
            }
        },
        incluirAbonoServidor() {
            this.alert = {};
            if (this.vinculoSelecionado.idVinculo && document.getElementById("DropdownVinculo").value) {
                this.$router.push({
                    path: "abono",
                    params: {
                        parametros: ""
                    },
                })
            } else {
                this.alert.mensagem = "O vínculo deve estar selecionado.";
                this.alert.variant = "warning";
                this.showAlert();
            }
        },
        incluirAfastamentoServidor() {
            this.alert = {};
            if (this.vinculoSelecionado.idVinculo && document.getElementById("DropdownVinculo").value) {
                this.$router.push({
                    path: "afastamento",
                    params: {
                        parametros: ""
                    },
                })
            } else {
                this.alert.mensagem = "O vínculo deve estar selecionado.";
                this.alert.variant = "warning";
                this.showAlert();
            }
        },
        alterarLotacao() {
            this.alert = {};
            let idVinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            let lotacao = {};
            lotacao.idLogado = this.usuarioLogado.id;
            lotacao.setor = this.setorSelecionado.idSetor;
            if (lotacao.setor != 0) {
                informacaoFuncionalService
                    .alterarLotacao(idVinculo, lotacao).then((res) => {
                        console.log("Lotação alterada", res)
                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                    }).catch(erro => {
                        console.log("ERRO", erro);
                        this.alert.mensagem = erro.response.data.mensagem;
                        this.alert.variant = "danger";
                        this.showAlert();
                    });
            } else {
                this.alert.mensagem = "A Área Técnica deve ser selecionada.";
                this.alert.variant = "danger";
                this.showAlert()
            }
        },
        listarVinculo() {
            this.itemsVinculo = [];

            for (let index = 0; index < this.usuarioPagina.vinculos.length; index++) {
                let objetoVinculo = {};
                objetoVinculo.idVinculo = this.usuarioPagina.vinculos[index].id;
                objetoVinculo.indexVinculo = index;
                objetoVinculo.matricula = this.usuarioPagina.vinculos[index].matricula;
                this.itemsVinculo.push(objetoVinculo);
            }
        },
        listaVinculoAoBuscar() {
            this.itemsVinculo = [];

            for (let index = 0; index < this.usuarioPesquisado.vinculos.length; index++) {
                let objetoVinculo = {};
                objetoVinculo.idVinculo = this.usuarioPesquisado.vinculos[index].id;
                objetoVinculo.indexVinculo = index;
                objetoVinculo.matricula = this.usuarioPesquisado.vinculos[index].matricula;
                this.itemsVinculo.push(objetoVinculo);
            }
        },
        trocaVinculo() {
            let novoVinculo = {};
            novoVinculo.indexVinculo = document.getElementById("DropdownVinculo").value;
            novoVinculo.idVinculo = this.usuarioPagina.vinculos[novoVinculo.indexVinculo].id;
            novoVinculo.matricula = this.usuarioPagina.vinculos[novoVinculo.indexVinculo].matricula;
            localStorage.removeItem("vinculoSelecionado");
            localStorage.setItem("vinculoSelecionado", JSON.stringify(novoVinculo));
            this.verificaLocalStore();
            this.listarFerias();
            this.listarAbono();
            this.listarAfastamento();
        },
        atualizaVinculo() {
            let novoVinculo = {};
            novoVinculo.indexVinculo = document.getElementById("DropdownVinculo").value;
            novoVinculo.idVinculo = this.usuarioPesquisado.vinculos[novoVinculo.indexVinculo].id;
            novoVinculo.matricula = this.usuarioPesquisado.vinculos[novoVinculo.indexVinculo].matricula;
            localStorage.removeItem("vinculoSelecionado");
            localStorage.setItem("vinculoSelecionado", JSON.stringify(novoVinculo));

        },
        setorSelecionadoLocalStore() {
            this.setorSelecionado.idSetor = document.getElementById("refSetorDrop").value;
            localStorage.removeItem("setorSelecionado");
            localStorage.setItem("setorSelecionado", JSON.stringify(this.setorSelecionado));
            this.setorSelecionado = JSON.parse(localStorage.getItem("setorSelecionado"));
        },
        async cadastrarUsuario() {
            this.alert = {};
            this.novoUsuario.nomeSocial = this.usuarioPagina.nomeSocial;
            this.novoUsuario.cpf = this.usuarioPagina.cpf;
            this.novoUsuario.matricula = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].matricula;
            this.novoUsuario.setor = document.getElementById("refSetorDrop").value;
            this.novoUsuario.idLogado = this.usuarioLogado.id;
            if (!this.novoUsuario.setor || this.novoUsuario.setor == 0) {
                this.alert.mensagem = "Erro ao cadastrar um novo usuário. Indique a Área Técnica do novo usuário.";
                this.alert.variant = "danger";
                this.showAlert()
                return
            }
            if (!this.novoUsuario.nomeSocial || !this.novoUsuario.cpf || !this.novoUsuario.matricula) {
                this.alert.mensagem = "Erro ao cadastrar um novo usuário. Informe o nome, cpf e matrícula do novo usuário.";
                this.alert.variant = "danger";
                this.showAlert()
                return
            }
            await usuarioService.salvarUsuario(this.novoUsuario).then((res) => {
                let resposta = res;
                console.log("MENSAGEM NOVO USUARIO", resposta)
                if (resposta.sucesso) {
                    this.alert.mensagem = res.mensagem;
                    this.alert.variant = "success";
                    this.showAlert()
                    this.flagNovo = false;

                    this.buscarUsuario(resposta.id);

                } else {
                    this.alert.mensagem = res.mensagem;
                    this.alert.variant = "danger";
                    this.showAlert()

                }
                // mandar mensagem de retorno na tela
            }).catch(error => {
                console.log("Deu erro aqui", error);

                this.alert.mensagem = error.response.data.mensagem;
                this.alert.variant = "danger";
                this.showAlert();
            }).finally(() => {
            })
        },
        async buscarUsuario(idUsuario) {
            let idLogado = this.usuarioLogado.id
            await usuarioService.buscarUsuarioPorChave(idUsuario, idLogado).then(async (res) => {
                this.usuarioPesquisado = {};
                this.usuarioPesquisado = res;
                console.log("NOVO USUARIO", this.usuarioPesquisado)

                await this.listaVinculoAoBuscar();

                await this.atualizaVinculo();
                this.usuarioPagina = this.usuarioPesquisado;
                localStorage.removeItem("usuarioPagina");
                localStorage.setItem("usuarioPagina", JSON.stringify(this.usuarioPagina));
                this.verificaLocalStore();

                this.listarFerias();
                this.listarAbono();
                this.listarAfastamento();
                this.termoDaPesquisa = "";
            }).catch(erro => {
                console.log(erro);
            }).finally(() => {
            })
        },
        buscarUsuarioParteNome(termo) {
            usuarioService.buscarUsuarioPorParteNome(termo).then((res) => {
                let usuarioPesquisado = res;
                if (usuarioPesquisado.length) {
                    console.log("Pesquisa do USUARIO", usuarioPesquisado)
                    this.listaDePesquisados = usuarioPesquisado;
                }
                else {
                    /* return "Usúario não encontrado." */
                }
            }).catch(erro => {
                console.log(erro);
            }).finally(() => {
            })
        },
        async PesquisarUsuario() {
            this.listaDeUsuariosPesquisados = [];
            if (!isNaN(this.termoDaPesquisa)) { //se for número

            } else {
                await this.buscarUsuarioParteNome(this.termoDaPesquisa);
                console.log("termosPesquisados", this.termosPesquisados)
                for (let index = 0; index < this.listaDePesquisados.length; index++) {
                    let objetoPesquisado = {};
                    objetoPesquisado.id = this.listaDePesquisados[index].id;
                    objetoPesquisado.name = this.listaDePesquisados[index].nomeSocial;
                    objetoPesquisado.label = this.listaDePesquisados[index].nomeSocial;

                    this.listaDeUsuariosPesquisados.push(objetoPesquisado)
                }
            }
        },

        async carregarUsuarioPesquisado() {// Fazer validação de filtro vazio e invalido
            this.flagNovo = false;
            await this.buscarUsuario(parseInt(this.termoDaPesquisa));
        },

        alterarDadosUsuario() {
            this.alert = {};
            this.novoUsuario.nomeSocial = this.usuarioPagina.nomeSocial;
            this.novoUsuario.nomeCivil = this.usuarioPagina.nomeSocial;
            this.novoUsuario.cpf = this.usuarioPagina.cpf;
            this.novoUsuario.matricula = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].matricula;
            this.novoUsuario.setor = document.getElementById("refSetorDrop").value;
            this.novoUsuario.idLogado = this.usuarioLogado.id;
            usuarioService.editarUsuario(this.usuarioPagina.id, this.novoUsuario).then((res) => {
                let resposta = res;
                console.log("MENSAGEM NOVO USUARIO", resposta);
                if (resposta.sucesso) {
                    this.alerta.msnSuccess = resposta.mensagem;
                } else {
                    this.alerta.msnError = resposta.mensagem;
                }
                this.buscarUsuario(resposta.id);
                // mandar mensagem de retorno na tela
            }).catch(erro => {
                console.log(erro);
                this.alerta.msnError = "Não foi possível alterar os dados do usuário."
            }).finally(() => {
                this.flagNovo = false;
            })
        },
        async verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else { // pega vinculo caso já esteja carregado
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
            }
        }
    },
    watch: {
        termoDaPesquisa() {
            this.PesquisarUsuario();
        }
    },
    mounted() {
        this.inicialiazar();
    },
    created() {
        this.verificaLocalStore();
    },
}
</script>

<style scoped>
.campo1 {
    float: left;
}

.campo2 {
    float: left;
}

.teste {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
</style>