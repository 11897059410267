<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo"> Licenças e outros afastamentos</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">

                        <div class="col-lg-3">
                            <div role="group">
                                <label for="reftipoAfastamento">Tipo de Afastamento<strong style="color:red">*</strong></label>
                                <select class="form-control mb-3 input-select" id="reftipoAfastamento"
                                    :state="stateValida.tipoAfastamento" v-on:change="validaCampo"
                                    v-model="afastamento.tipoAfastamento">

                                    <option value="0"></option>
                                    <option v-for="tipo in tipoAfastamento" :key="tipo.id" :value="tipo.id">{{
                                            tipo.nome
                                    }}</option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo tipo afastamento é obrigatório!</div>
                        </div>

                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Data inicio<strong style="color:red">*</strong></label>
                                <b-form-input id="input-live" type="date" required="" v-model="afastamento.inicio"
                                    :state="stateValida.inicio" v-on:change="validaCampo, calculaDiasAfastamento()"
                                    aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <div class="invalid-feedback">Campo data início é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Data Fim<strong style="color:red">*</strong></label>
                                <b-form-input id="input-live" type="date" v-model="afastamento.termino"
                                    :state="stateValida.termino" v-on:change="validaCampo, calculaDiasAfastamento()"
                                    aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <div class="invalid-feedback">Campo data fim é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-1">
                            <div role="group">
                                <label for="input-live">Dias</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    trim v-model="afastamento.dias" disabled>
                                </b-form-input>
                                <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                            </div>
                        </div>

                    </div>
                    <div class="row">


                        <div class="col-lg-6">
                            <div role="group">
                                <label for="input-live">Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    v-model="afastamento.processoSEI" SIZE="30" MAXLENGTH = "30"></b-form-input>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div role="group">
                                <label for="input-live">Url do Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    v-model="afastamento.urlSEI"></b-form-input>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-lg-1">
                        <div class="container-buttons-salvar">
                            <b-button id="btn-salvarAfastamento" @click="adicionarAfastamento" variant="outline-success"
                                >Salvar</b-button>
                            
                        </div>
                    </div>&nbsp;
                    <div class="col-lg-1">

                        <div class="container-buttons-salvar">

                            <b-button variant="outline-success" @click="limparObjeto()">Novo</b-button>

                        </div>
                    </div> &nbsp;
                    <div class="col-lg-1">
                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Lista de Licenças e outros afastamentos </h2>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Tipo de Afastamento</th>
                                <th>Período</th>
                                <th>Dias</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="aft in itemsAfastamento" :key="aft.id">

                                <td>{{ aft.tipoAfastamento }}</td>
                                <td>{{ aft.inicio }} a {{ aft.termino }}</td>
                                <td>{{ aft.dias }}</td>
                                <td>
                                   
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="editarAfastamento(aft)">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { afastamentoService } from "@/service/afastamentoService";

export default {
    beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  },
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                exercicio: null,
                inicio: null,
                termino: null,
                vinculo: null,
                dias: null,
                tipoAfastamento: null,
            },


            afastamento: { 
                exercicio: "",
                inicio: null,
                termino: null,
                dias: null,
                vinculo: null,
                tipoAfastamento: null,
                processoSEI: null,
                urlSEI: null,
                idLogado: "", 

            },
            ferias: [],
            alert: {
                mensagem: "",
                variant: ""
            },
            mensagem: "",
            validado:"",

            options: [{ value: 1, text: 'LTS' }, { value: 3, text: 'MATERNIDADE' }],

            fields: ['tipoAfastamento', 'inicio', 'termino'],


            itemsAfastamento: [],
            tipoAfastamento: []
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        limparObjeto() {
            document.getElementById("btn-salvarAfastamento").textContent = "Salvar"
            this.afastamento = {};
        },
       
        async inicializar() {
            await this.verificaLocalStore();
            this.listarAfastamento();
            this.listarTipoAfastamento();
          
              if(this.$route.params.parametro){
                document.getElementById("btn-salvarAfastamento").textContent = "Alterar"
                
                this.afastamento =   JSON.parse(this.$route.params.parametro)
            }
            console.log("valor ",JSON.parse(this.$route.params.parametro))

           
            setTimeout(() => {
                this.afastamento.inicio = this.inverterCampoData(this.afastamento.inicio);
                this.afastamento.termino = this.inverterCampoData(this.afastamento.termino)
            }, 200)
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },

        calculaDiasAfastamento() {

            let start = this.afastamento.inicio;
            let and = this.afastamento.termino;
            if (start && and) {
                start = new Date(start)
                and = new Date(and)

                let diffInTime = Math.abs(and - start)
                let timeInOneDay = 1000 * 60 * 60 * 24
                let diffInDays = diffInTime / timeInOneDay + 1

                this.afastamento.dias = diffInDays;

                console.log("dias de afastamento é", diffInDays)
                console.log("campo do data de afastamento é", this.afastamento.dias)

                return diffInDays

            }
            this.afastamento.dias = "";




        },
        validaCampo() {

            if (this.afastamento.exercicio) {
                this.stateValida.exercicio = true;
                this.validado =  true;

            }
            else if (!this.afastamento.exercicio) {
                this.stateValida.exercicio = false;
                this.validado =  false;
            }
            if (this.afastamento.inicio) {
                this.stateValida.inicio = true;
                this.validado =  true;

            }
            else if (!this.afastamento.inicio) {
                this.stateValida.inicio = false;
                this.validado =  false;
            }
            if (this.afastamento.termino) {
                this.stateValida.termino = true;
                this.validado =  true;

            }
            else if (!this.afastamento.termino) {
                this.stateValida.termino = false;
                this.validado =  false;
            }
            if (this.afastamento.dias) {
                this.stateValida.dias = true;
                this.validado =  true;

            }
            else if (!this.afastamento.dias) {
                this.stateValida.dias = false;
                this.validado =  false;
            }
            if (this.afastamento.tipoAfastamento) {
                this.stateValida.tipoAfastamento = true;
                this.validado =  true;

            }
            else if (!this.afastamento.tipoAfastamento) {
                this.stateValida.tipoAfastamento = false;
                this.validado =  false;
            }
            return this.validado


        },
        editarAfastamento(aft) {
        document.getElementById("btn-salvarAfastamento").textContent = "Alterar"

            this.afastamento = JSON.parse(JSON.stringify(aft))

            setTimeout(() => {
                this.afastamento.inicio = this.inverterCampoData(aft.inicio);
                this.afastamento.termino = this.inverterCampoData(aft.termino);

            }, 200)

        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        addFerias() {
            let itemFerias = {};
            itemFerias.isActive = "true";
            itemFerias.Dias = this.feriasSelecionadas.diasFerias;
            itemFerias.Período = ` ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.feriasSelecionadas.dataInicio)))} a ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.feriasSelecionadas.dataFim)))}`;
            itemFerias.Exercicio = this.feriasSelecionadas.anoExercicio;
            console.log("Ferias Aqui", this.feriasSelecionadas)

            setTimeout(() => {
                this.items.push(itemFerias)
                localStorage.setItem("ferias", JSON.stringify(this.items));
            }, 900)
           
        },
        adicionarAfastamento() {

        if (document.getElementById("btn-salvarAfastamento").textContent == "Alterar") {
                this.alterarAfastamento();
            } else {
            if (this.validaCampo() == true) {
                this.afastamento.vinculo = this.usuarioPagina.vinculos[0].id;
                this.afastamento.idLogado = this.usuarioLogado.id
                afastamentoService
                    .salvarAfastamento(this.afastamento)
                    .then((res) => {

                        console.log(res);
                        this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                        this.listarAfastamento();
                       
                        document.getElementById("btn-salvarAfastamento").textContent = "Alterar"
                    })
                    .catch(() => {

                    });
                 }else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }
        },
        alterarAfastamento() {

            if (this.validaCampo() == true) {

                this.afastamento.inicio = this.inverterCampoData(this.afastamento.inicio)
                this.afastamento.termino = this.inverterCampoData(this.afastamento.termino)
                this.afastamento.idLogado = this.usuarioLogado.id

                afastamentoService
                    .alterarAfastamento(this.afastamento.id, this.afastamento)
                    .then((res) => {
                      
                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                        

                        this.listarAfastamento();
                    })
                    .catch(() => {
                        console.log("deu erro no serviço")

                       
                    });
            }else {
                this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
                this.alert.variant = "danger";
                this.showAlert();

            }
        },
        limparObjetoafastamento() {
           
            this.afastamento.tipoAfastamento = "";              
            this.afastamento.inicio = "";
            this.afastamento.termino = "";
            this.afastamento.dias = "";
            this.afastamento.vinculo = "";
         
            this.afastamento.processoSEI = "";
            this.afastamento.urlSEI = "";
        },
        listarAfastamento() {
               
            let vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            afastamentoService
                .listarAfastamento(vinculo).then((res) => {
                    this.itemsAfastamento = res;
                    console.log("Gerencia é", this.itemsAfastamento)
                });
        },
        listarTipoAfastamento() {

            afastamentoService
                .listarTipoAfastamento().then((res) => {
                    this.tipoAfastamento = res;
                    console.log("afastamento  é", this.tipoAfastamento)
                });
        },
        calculaDataFim() {
            let dataInicio = this.feriasSelecionadas.dataInicio
            let dias = this.feriasSelecionadas.diasFerias
            let dataFinal = dataInicio + dias;
            this.feriasSelecionadas.dataFim = this.formatarObjetoData(dataFinal);
            console.log("data final é ", dataFinal); // 19
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },        

    },


}

</script>
<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 5%;

} 
.fieldset
{
  
  max-width:250px;
  padding:8px;	
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 5%;
  float: right; 
  margin-right: 10px;

}
.legend1
{
  max-width:250px;
  padding:8px;	 
  float: right; 
  margin-right: 10px;
}


</style>