<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Gerência</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
             
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>

                            <b-form-input id="input-live" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Gerência" trim v-model="gerencia.nome" SIZE="60"
                                MAXLENGTH="60"></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="input-live" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla da Gerência" trim v-model="gerencia.sigla" SIZE="20"
                                MAXLENGTH="20"></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>

                      
                    </div>

                </div>
                <div class="legend1 ">

                    <fieldset class="fieldset ">

                        <div class="row">
                            <div class="btn-group" role="group">

                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarGerencia" variant="outline-success" id="btn-salvar">
                                        Salvar
                                    </b-button>
                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoGerencia()">
                                        Novo</b-button>

                                </div>&nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas das Gerências</h2>

                   

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>

                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr v-for="ge in itemsGerencia" :key="ge.id">

                                <td>{{ ge.nome }}</td>
                                <td>{{ ge.sigla }}</td>

                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="gerencia = JSON.parse(JSON.stringify(ge)), editarGerencia(ge)">
                                           
                                            Editar
                                        </b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { gerenciaService } from "@/service/gerenciaService";


export default {
   
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                nome: null,
                sigla: null,
            },
          
            gerencia: {
                nome: "",
                sigla: "",
                diretoria: "",
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },

            gerenciaClonada: {},

            tipoDiretoria: [],
            infoGerencia: {},

            mensagem: "",
            stateNome: null,
            stateSigla: null,
            fields: ['nome', 'sigla'],



            itemsGerencia: [],
            idGerenciaClonado: "",
            validado:"",

        }
    },
    props: {
        parametros: Object,
    },
    mounted() {
        this.inicializar();


    },
    methods: {

       
        async inicializar() {
            await this.verificaLocalStore();
            
            this.listarGerencia();
            this.alerta = {};
        },

        limparObjetoGerencia() {
            this.alerta = {};
            this.idGerenciaClonado = "";

            this.gerenciaClonada.nome = "";
            this.gerenciaClonada.sigla = "";
            this.gerenciaClonada.diretoria = "";
            this.gerenciaClonada.idLogado = "";
            this.gerenciaClonada.id = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"

            }, 200)

            this.gerencia = {};

        },
        async buscarValorParametro() {

            let idDiretoria = 1//this.usuarioPagina.vinculos[0].setor.lotacao.diretoria;          
            

            this.listarGerencia(idDiretoria)




        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        async buscaGerencia(idGerencia) {

            await gerenciaService
                .pegaGerencia(idGerencia)
                .then((res) => {

                    this.infoGerencia = res;
                    console.log("pegou gerencial legal", this.infoGerencia)


                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        validaCampo() {

            if (this.gerencia.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.gerencia.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.gerencia.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;

            }
            else if (!this.gerencia.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
            return this.validado



        },
        editarGerencia() {
            this.alerta = {};
            document.getElementById("btn-salvar").textContent = "Alterar"
            this.idGerenciaClonado = "";

            this.gerenciaClonada.nome = "";
            this.gerenciaClonada.sigla = "";
            this.gerenciaClonada.diretoria = "";
            this.gerenciaClonada.idLogado = "";
            this.gerenciaClonada.id = "";




        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        addGerencia() {
            let itemAbono = {};
            itemAbono.Período = ` ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataInicio)))} a ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataFim)))}`;
            setTimeout(() => {
                this.items.push(itemAbono)
                localStorage.setItem("abono", JSON.stringify(this.items));
            }, 900)

        },
        adicionarGerencia() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarGerencia();
                }, 200)
            } else {
                if (this.validaCampo() == true) {
                    this.gerencia.diretoria = 1
                    this.gerencia.idLogado = this.usuarioLogado.id
                    this.idGerenciaClonado = "";

                    this.gerenciaClonada.nome = "";
                    this.gerenciaClonada.sigla = "";
                    this.gerenciaClonada.diretoria = "";
                    this.gerenciaClonada.idLogado = "";
                    this.gerenciaClonada.id = "";

                    gerenciaService
                        .salvarGerencia(this.gerencia)
                        .then((res) => {

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()



                            setTimeout(() => {
                                this.alerta.msnSuccess = "";
                                this.gerenciaClonada = JSON.parse(JSON.stringify(this.gerencia));

                            }, 3000)


                            this.showAlert();
                            document.getElementById("btn-salvar").textContent = "Alterar"


                        })
                        .catch(() => {

                        }).
                        finally(() => {
                            this.listarGerencia();
                            
                        })
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarGerencia() {
            this.alert = {};

            for (let index = 0; index < this.itemsGerencia.length; index++) {

                if (this.gerenciaClonada.nome === this.itemsGerencia[index].nome) {
                    this.idGerenciaClonado = this.itemsGerencia[index].id
                    console.log(this.idGerenciaClonado)
                    break;
                }


            }


            if (this.idGerenciaClonado) {
                this.gerencia.id = this.idGerenciaClonado;

            }

            if (this.validaCampo() == true) {
                this.gerencia.diretoria = 1
                // como só existe uma diretoria, o idDiretoria colocamos na mão -> this.usuarioPagina.vinculos[0].setor.lotacao.diretoria; 

                this.gerencia.idLogado = this.usuarioLogado.id


                gerenciaService
                    .alterarGerencia(this.gerencia.id, this.gerencia)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                        this.listarGerencia();
                    })
                    .catch(() => {

                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        listarGerencia() {
            let diretoria = 1//this.usuarioPagina.vinculos[0].setor.lotacao.diretoria;

            gerenciaService
                .listarGerencia(diretoria).then((res) => {
                    this.itemsGerencia = res;
                    console.log("Gerencia é", this.itemsGerencia)
                    
                   
                });
        },
    },


}

</script>
<style scoped>

</style>