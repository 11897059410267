import axios from "axios";

export const aprovacaoCadastroService = {

  salvarAprovacaoCadastro(aprovacaoCadastro) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preCadastro`,
          aprovacaoCadastro,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarAprovacaoCadastro(id, aprovacaoCadastro) {
    return new Promise((resolve, reject) => {
     

      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preCadastro/${id}`,
          aprovacaoCadastro,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarAprovacaoCadastro() {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/aprovacaoCadastro/listar`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarSituacaoCadastro() {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/constante/situacaoCadastro`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
/*   listarInstituicao() {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/instituicao`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, */
};
