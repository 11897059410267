import axios from "axios";

export const cursoService = {
  salvarCurso(curso) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/curso`,
          curso,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarCurso(id, curso) {
    return new Promise((resolve, reject) => {
     

      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/curso/${id}`,
          curso,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarCurso(usuario) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/curso/listar/${usuario}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarUF() {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/constante/uf`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarInstituicao() {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/instituicao`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
