import axios from "axios";

export const projetoService = {
  salvarMentoria(projeto) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/projeto`,
          projeto,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarProjeto(id, projeto) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/projeto/${id}`,
          projeto,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarProjeto(idProjeto) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/projeto/listar/${idProjeto}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTodosProjeto(idLogado) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/projeto/listar?idLogado=${idLogado}`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
};
