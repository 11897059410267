<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Região Administrativa</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4" role="group">

                            <div role="group">
                                <label for="input-live">Regional<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="refGerenciaDrop"
                                    :state="stateValida.regional"
                                    v-on:change="validaCampo, listarRa(), habilitarCampo()" v-model="ra.regional">
                                    <option value="0"></option>
                                    <option v-for="rg in tipoRegional" :key="rg.id" :value="rg.id">{{
                                    rg.sigla
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo regional é obrigatório!</div>

                        </div>
                        <div class="col-lg-4" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>
                            <b-form-input id="nomeRa" :state="stateValida.nome" v-on:change="validaCampo" type="text"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da RA" v-model="ra.nome" SIZE="60" MAXLENGTH="60" disabled>
                            </b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-4" role="group">
                            <label for="input-live">SubDistrito<strong style="color:red">*</strong></label>
                            <div class="input-group">
                                <div class="input-group-append">
                                    <h5 class="alinhar-texto">530010805</h5>
                                </div>
                                <div class="col-lg-5">

                                    <b-form-input id="siglasubdistrito" :state="stateValida.subdistrito"
                                    v-on:change="validaCampo" type="text"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe a sigla do subdistrito" v-model="ra.subdistrito" SIZE="3"
                                    MAXLENGTH="3" disabled></b-form-input>
                                    <div class="invalid-feedback">Campo subdistrito é obrigatório!</div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <fieldset class="fieldset ">
                        <div class="row">
                            <div class="btn-group" role="group">
                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarRa" variant="outline-success" id="btn-salvar">Salvar
                                    </b-button>
                                </div>

                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoRa()">Novo
                                    </b-button>

                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                    </fieldset>



                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Lista das Regiões Administrativas</h2>



                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Nome</th>
                                <th>SubDistrito</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>

                           
                            <tr v-for="rad in itemsRa" :key="rad.id">

                                <td>{{ rad.nome }}</td>
                                <td>{{ rad.subdistrito }}</td>



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="ra = JSON.parse(JSON.stringify(rad)), editarRa()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { regiaoAdministrativaService } from "@/service/regiaoAdministrativaService";
import { regionalSaudeService } from "@/service/regionalSaudeService";

export default {
    data() {
        return {
            infoRa: {},
            infoRegional: {},
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            raErro: false,
            stateValida: {
                nome: null,
                subdistrito: "",
                regional: "",
            },
            listaRaAuxiliar: [],

            ra: {
                nome: "",
                subdistrito: "",
                regional: "",
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            raClonado: {},
            idRaClonado: "",
            stateNome: null,
            statesubdistrito: null,
            
            validado: "",

            mensagem: "",
            tipoRegional: [],
            fields: ['nome', 'sigla'],



            itemsRa: []

        }
    },
    mounted() {
        this.inicialiazar();

    },
    methods: {
        async inicialiazar() {
            await this.verificaLocalStore();

            this.listarRegionalSaude()
            this.alerta = {};

        },
        limparObjetoRa() {
            this.alerta = {};
          
            this.raClonado = {};
            this.idRaClonado = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
            }, 200)
            this.ra = {};
        },
        alterarRa() {
            this.alert = {};

            for (let index = 0; index < this.itemsRa.length; index++) {

                if (this.raClonado.nome === this.itemsRa[index].nome) {
                    this.idRaClonado = this.itemsRa[index].id
                    console.log(this.idRaClonado)
                    break;
                }


            }


            if (this.idRaClonado) {
                this.ra.id = this.idRaClonado;

            }


            if (this.validaCampo() == true) {
                this.ra.idLogado = this.usuarioLogado.id
                this.ra.usuario = this.usuarioPagina.id;
                regiaoAdministrativaService
                    .alterarRa(this.ra.id, this.ra)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()

                        this.listarRa();
                    })
                    .catch(() => {
                    });
            } else {
                this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
                this.alert.variant = "danger";
                this.showAlert();
            }
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },

        async buscaRa(idRa) {

            await regiaoAdministrativaService
                .pegaRa(idRa)
                .then((res) => {

                    this.infoRa = res;
                    console.log("objeto é ", this.infoRa)


                })
                .catch(() => {

                });
        },
        async buscaRegional(idRegional) {

            await regionalSaudeService
                .pegaGerencia(idRegional)
                .then((res) => {

                    this.infoRegional = res;
                    console.log("objeto da gerencia é ", this.infoRegional)

                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        editarRa() {
            this.alerta = {};
            this.raClonado = {};
            this.idRaClonado = "";
            document.getElementById("btn-salvar").textContent = "Alterar"
            

        },

        validaCampo() {

            if (this.ra.nome) {
                this.stateValida.nome = true;
                this.validado = true;

            }
            else if (!this.ra.nome) {
                this.stateValida.nome = false;
                this.validado = false;
            }
            if (this.ra.subdistrito) {
                this.stateValida.subdistrito = true;
                this.validado = true;

            }
            else if (!this.ra.subdistrito) {
                this.stateValida.subdistrito = false;
                this.validado = false;
            }
            if (this.ra.regional) {
                this.stateValida.regional = true;
                this.validado = true;

            }
            else if (!this.ra.regional) {
                this.stateValida.regional = false;
                this.validado = false;
            }
            return this.validado;

        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },


        adicionarRa() {
            this.alert = {};

           


            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarRa();
            } else {
                if (this.validaCampo() == true) {
                    this.ra.idLogado = this.usuarioLogado.id
                    this.ra.subdistrito = parseInt(this.ra.subdistrito);


                    regiaoAdministrativaService
                        .salvarRa(this.ra)
                        .then((res) => {


                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            this.raClonado = JSON.parse(JSON.stringify(this.ra));
                            document.getElementById("btn-salvar").textContent = "Alterar"
                            this.listarRa(this.ra.regional);
                            this.listarRegionalSaude();

                        })
                        .catch(error => {
                            this.alert.mensagem = error.response.data.mensagem;
                             this.alert.variant = "danger";
                             this.showAlert()

                        });
                } else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }

        },
        listarRegionalSaude() {
            regionalSaudeService
                .listarRegionalSaude().then((res) => {
                    this.tipoRegional = res;
                    console.log("id da gerencia é", this.tipoRegional)

                });
        },
        async listarRa(idRegional) {

            if (!idRegional) {
                idRegional = document.getElementById("refGerenciaDrop").value
                console.log("o ide regional da combo é", idRegional)
                if (idRegional != 0) {
                    await regiaoAdministrativaService
                        .listarRa(idRegional).then((res) => {
                            console.log("res do ra", res)

                            if (res.length > 0) {
                                this.itemsRa = res;

                            } else {
                                this.itemsRa = {}
                            }



                        }).catch((erro) => {
                            console.log(erro)
                            this.itemsRa = {}


                        });

                } else {
                    setTimeout(() => {
                        this.itemsRa = {};
                        this.ra.nome = "";
                        this.ra.subdistrito = "";
                        document.getElementById('nomeRa').disabled = true;
                        document.getElementById('siglasubdistrito').disabled = true;

                    }, 200)
                }

            } else {
                await regiaoAdministrativaService
                    .listarRa(idRegional).then((res) => {
                        console.log("res do ra", res)
                        this.itemsRa = res;
                        idRegional = "";

                    }).catch((erro) => {
                        console.log(erro)
                    });
            }

        },
        habilitarCampo() {
          
            document.getElementById('nomeRa').disabled = false;
            document.getElementById('siglasubdistrito').disabled = false;
         

        },

    },


}

</script>
<style scoped>

.alinhar-texto {
    line-height: 2 !important;
}

</style>
