<template>
  <div class="container">
    <div class="card">
      <h2 class="card__titulo">Evento</h2>
      <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class=" col-lg-6 ">
          <label for="nome" class="form-label">Titulo<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" required id="nome" v-model.trim="infoPessoal.nomeCivil"> -->
          <b-form-input type="text" class="form-control mb-3" required id="nome" v-model="evento.titulo"
            :state="stateValida.titulo"></b-form-input>
          <div class="invalid-feedback">Campo Título é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="nomSocial" class="form-label">Objetivo: <strong style="color:red">*</strong></label>
          
          <b-form-input type="text" class="form-control mb-3" id="nomSocial" v-model.trim="evento.objetivo"
            :state="stateValida.objetivo"></b-form-input>
          <div class="invalid-feedback">Campo Objetivo é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Início<strong style="color:red">*</strong></label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="evento.inicio"
            :state="stateValida.inicio">
          </b-form-input>
          <div class="invalid-feedback">Campo Início é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="staticEmail2">Término</label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="evento.termino">
          </b-form-input>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="cpf">Carga Horária<strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control mb-3" id="cargaHoraria" v-model="evento.cargaHoraria"
            :state="stateValida.cargaHoraria">
          </b-form-input>
          <div class="invalid-feedback">Campo Carga Horária é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="rg">Quantidade de Participantes<strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control mb-3" id="quantAlunos" v-model="evento.quantParticipantes"
            :state="stateValida.quantParticipantes">
          </b-form-input>
          <div class="invalid-feedback">Campo Quantidade de Participantes é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Público Alvo<strong style="color:red">*</strong></label>

          <b-form-input type="text" class="form-control mb-3" id="staticEmail2" v-model="evento.publicoAlvo"
            :state="stateValida.publicoAlvo">
          </b-form-input>
          
          <div class="invalid-feedback">Campo Publico Alvo é obrigatório!</div>
        </div>

        <div class="col-lg-6 ">
          <label for="staticEmail2">Abrangência<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.abrangencia"
            v-model="evento.abrangencia">
            <option value="0"></option>
            <option v-for="abr in itemAbrangencia" :key="abr.id" :value="abr.id">{{
            abr.nome
            }}

            </option>
          </select>


          <div class="invalid-feedback">Campo Abrangência é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="staticEmail2">Tipo<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.tipo" v-model="evento.tipo">
            <option value="0"></option>
            <option v-for="tipoEvento in itemTipo" :key="tipoEvento.id" :value="tipoEvento.id">{{
            tipoEvento.nome
            }}
            </option>
          </select>
          <div class="invalid-feedback">Campo Tipo é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="staticEmail2">Situação<strong style="color:red">*</strong></label>

          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.situacao"
            v-model="evento.situacao">
            <option value="0"></option>
            <option v-for="sit in itemSituacaoEvento" :key="sit.id" :value="sit.id">{{
            sit.nome
            }}

            </option>

          </select>
          <div class="invalid-feedback">Campo Situação é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="staticEmail2">Modalidade<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="modalidade" :state="stateValida.modalidade"
            v-model="evento.modalidade">
            <option value="0"></option>
            <option v-for="mod in itemModalidade" :key="mod.id" :value="mod.id">{{
            mod.nome
            }}
            </option>
          </select>
          <div class="invalid-feedback">Campo Modalidade é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="input-live">Equipe<strong style="color:red">*</strong></label>
          <multiselect class="mb-3" id="refEquipe" v-model="equipe" :state="stateValida.equipe" :options="itemEquipe"
            :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
            placeholder="Informe Id, Nome ou Cpf do usuário" :show-no-results="false" :hide-selected="true"
            label="nomeSocial" track-by="nomeSocial" :preselect-first="true" :searchable="true" :loading="isLoading"
            :internal-search="false" @search-change="buscarUsuario">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Usuário(s)
                Selecionado(s)</span></template>
            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </multiselect>
        </div>
      </div>
      <fieldset class="row">
        <div class="col-lg-3">
          <label for="input-live">RA<strong style="color:red">*</strong></label>
          <div>            
            <treeselect v-model="evento.ras" :multiple="true" :options="itemRa" :state="stateValida.ras" />
          </div>
        </div>       

        <div class="col-lg-3">
          <label for="input-live">Agravo<strong style="color:red">*</strong></label>
          <div>
            <treeselect v-model="evento.agravos" :multiple="true" :options="itemAgravo" :state="stateValida.agravos" />
          </div>
        </div>
        <div class="col-lg-3 ">
          <label for="processoSei">Processo SEI</label>
          <input type="text" class="form-control mb-3" id="processoSei" v-model="evento.processoSEI">
        </div>
        <div class="col-lg-3 ">
          <label for="urlSei">UrlSEI</label>
          <input type="text" class="form-control mb-3" id="urlSei" v-model="evento.urlSEI">
        </div>
      </fieldset>

      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="salvarEvento" variant="outline-success" id="btn-salvar">Salvar
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="limparObjetoEvento" variant="outline-success" id="btn-salvar">Novo
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <router-link to="/inicio">
              <b-button variant="outline-success">Voltar</b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabela de visitas -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h2 class="card__titulo"> Lista de Evento</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Título</th>
                <th>Carga Horária</th>
                <th>Data Início</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in itemEvento" :key="event.id">

                <td>{{ event.titulo }}</td>
                <td>{{ event.cargaHoraria }}</td>
                <td>{{ event.inicio }}</td>
                <td>
                  <div>
                    <b-button type="button" variant="outline-success"
                      @click="evento = JSON.parse(JSON.stringify(event)), editarEvento(event)">Editar
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


</template>

<script>


import { constanteService } from "@/service/constanteService";
import { eventoService } from "@/service/eventoService";
import { agravoService } from "@/service/agravoService";
import { usuarioService } from "@/service/usuarioService";
import { regiaoAdministrativaService } from "@/service/regiaoAdministrativaService";
import { mask } from "vue-the-mask";
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
//import vueMultiSelect from 'vue-multi-select';

export default {

  directives: { mask },
  data() {
    return {

      alert: {
        mensagem: "",
        variant: ""
      },
      
      dismissCountDown: 0,
      dismissSecs: 10,
      usuarioLogado: {},
      usuarioPagina: {},
      eventoSelecionado: {},
      agravos: [],
      ras: [],
      equipe: [],
      itemRa: [],
      itemEvento: [],
      itemModalidade: [],
      itemTipo: [],
      itemSituacaoEvento: [],
      itemAgravo: [],
      eventoClonada: {},
      idEventoClonado: "",
      itemEquipe: [],
      itemAbrangencia: [],
      isLoading: false,
      evento: {
        titulo: "",
        objetivo: "",
        inicio: "",
        termino: "",
        cargaHoraria: "",
        quantParticipantes: "",
        publicoAlvo: "",
        abrangencia: "",
        tipo: "",
        situacao: "",
        modalidade: "",
        agravos: [],
        ras: [],
        equipe: [],
        processoSEI: "",
        urlSEI: "",
        idLogado: "",

      },

      stateValida: {
        titulo: "",
        objetivo: "",
        inicio: "",
        termino: "",
        cargaHoraria: "",
        quantParticipantes: "",
        publicoAlvo: "",
        abrangencia: "",
        tipo: "",
        situacao: "",
        modalidade: "",
        agravos: "",
        ras: "",
        equipe: "",
      },
    }
  },
  components: {
    Multiselect,
   
  
   // vueMultiSelect,
    Treeselect
  },
  mounted() {
    this.inicializar();
  },
  methods: {

    limparObjetoEvento() {
      this.alerta = {};
      this.idEventoClonado = "";

      this.eventoClonada = {}

      setTimeout(() => {
        document.getElementById("btn-salvar").textContent = "Salvar"

      }, 200)

      this.evento = {};

    },
    formataObjeto(array) {
      var listaIdLabel = [];
      for (let i = 0; i < array.length; i++) {
        let idLabel = {}
        idLabel.id = array[i].id;
        idLabel.label = array[i].nome;
        listaIdLabel.push(idLabel);
      }
      console.log("Lista de Ids e Labels", listaIdLabel)
      return listaIdLabel;
    },

    async buscarUsuario() {
      this.isLoading = true;
      let palavraChave = await document.getElementById("refEquipe").value;
      console.log("campo", palavraChave)
      usuarioService
        .buscarUsuarioSimples(palavraChave, this.usuarioLogado.id).then((res) => {
          this.itemEquipe = [res];
          console.log("Usuario Pesquisado", this.itemEquipe)
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        });
    },
    formataMultiSelect(selecionados, listaTotal) {
      let resultado = [];
      for (let i = 0; i < listaTotal.length; i++) {
        for (let j = 0; j < selecionados.length; j++) {
          if (selecionados[j] == listaTotal[i].id)
            resultado.push(listaTotal[i]);
        }
      }
      console.log("Resultado", resultado)
      return resultado;

    },
    async editarEvento(evento) {
      console.log("evento é", evento)
      this.alerta = {};
      this.evento = JSON.parse(JSON.stringify(evento))
      console.log("evento é",  this.evento)
     


      document.getElementById("btn-salvar").textContent = "Alterar"

      this.evento.inicio = await this.inverterCampoData(evento.inicio);
      this.evento.termino = await this.inverterCampoData(evento.termino);
      this.evento.instituicao = evento.instituicao;


      this.idEventoClonado = "";
      this.eventoClonada = {};
    },
    async alterarEvento() {
      this.alert = {};
      this.eventoClonada.inicio = this.inverterCampoData(this.evento.inicio);
      this.eventoClonada.termino = this.inverterCampoData(this.evento.termino);

      for (let index = 0; index < this.itemEvento.length; index++) {

        if (this.eventoClonada.titulo === this.itemEvento[index].titulo) {
          this.idEventoClonado = this.itemEvento[index].id

          break;
        }


      }


      if (this.idEventoClonado) {
        this.evento.id = this.idEventoClonado;

      }

      if (this.validaCampo() == true) {


        this.evento.idLogado = this.usuarioLogado.id
        this.evento.equipe = await this.pegaId(this.equipe);


        eventoService
          .alterarEvento(this.evento.id, this.evento)
          .then((res) => {

            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert()
            this.listarEvento();
          })
          .catch(() => {

          });
      } else {
        this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
        this.alert.variant = "danger";
        this.showAlert();
      }
    },


    validaCampo() {
      if (this.evento.titulo) {
        this.stateValida.titulo = true;
        this.validado = true;

      }
      else if (!this.evento.titulo) {
        this.stateValida.titulo = false;
        this.validado = false;
      }
      if (this.evento.objetivo) {
        this.stateValida.objetivo = true;
        this.validado = true;

      }
      else if (!this.evento.objetivo) {
        this.stateValida.objetivo = false;
        this.validado = false;
      }
      if (this.evento.cargaHoraria) {
        this.stateValida.cargaHoraria = true;
        this.validado = true;

      }
      else if (!this.evento.cargaHoraria) {
        this.stateValida.cargaHoraria = false;
        this.validado = false;
      }
      if (this.evento.quantParticipantes) {
        this.stateValida.quantParticipantes = true;
        this.validado = true;

      }
      else if (!this.evento.quantParticipantes) {
        this.stateValida.quantParticipantes = false;
        this.validado = false;
      }
      if (this.evento.tipo) {
        this.stateValida.tipo = true;
        this.validado = true;

      }
      else if (!this.evento.tipo) {
        this.stateValida.tipo = false;
        this.validado = false;
      }
      if (this.evento.inicio) {
        this.stateValida.inicio = true;
        this.validado = true;

      }
      else if (!this.evento.inicio) {
        this.stateValida.inicio = false;
        this.validado = false;
      }
      if (this.evento.publicoAlvo) {
        this.stateValida.publicoAlvo = true;
        this.validado = true;

      }
      else if (!this.evento.publicoAlvo) {
        this.stateValida.publicoAlvo = false;
        this.validado = false;
      }
      if (this.evento.equipe) {
        this.stateValida.equipe = true;
        this.validado = true;

      }
      else if (!this.evento.equipe) {
        this.stateValida.equipe = false;
        this.validado = false;
      }
      if (this.evento.agravos) {
        this.stateValida.agravos = true;
        this.validado = true;

      }
      else if (!this.evento.agravos) {
        this.stateValida.agravos = false;
        this.validado = false;
      }
      if (this.evento.abrangencia) {
        this.stateValida.abrangencia = true;
        this.validado = true;

      }
      else if (!this.evento.abrangencia) {
        this.stateValida.abrangencia = false;
        this.validado = false;
      }

      if (this.evento.situacao) {
        this.stateValida.situacao = true;
        this.validado = true;

      }
      else if (!this.evento.situacao) {
        this.stateValida.situacao = false;
        this.validado = false;
      }
      if (this.evento.modalidade) {
        this.stateValida.modalidade = true;
        this.validado = true;

      }
      else if (!this.evento.modalidade) {
        this.stateValida.modalidade = false;
        this.validado = false;
      }
      if (this.evento.ras) {
        this.stateValida.ras = true;
        this.validado = true;

      }
      else if (!this.evento.ras) {
        this.stateValida.ras = false;
        this.validado = false;
      }

      return this.validado

    },
    async inicializar() {
      await this.verificaLocalStore();
      this.listarRa();
      this.listarModalidade();
      this.listarSituacaoVisita();
      this.listarAgravo();
      this.listarEvento();
      this.listarAbrangencia();
      this.listarTipoEvento();
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inverterCampoData(data) {
      data = data
        .split("/")
        .reverse()
        .join("/");
      data = data.replaceAll("/", "-")

      return data;
    },
    pegaId(array) {
      var listaId = [];
      for (let i = 0; i < array.length; i++) {
        listaId.push(array[i].id);
      }
      console.log("Lista de Ids", listaId)
      return listaId;
    },

    async salvarEvento() {
      if (document.getElementById("btn-salvar").textContent == "Alterar") {
        setTimeout(() => {
          this.alterarEvento();
        }, 200)
      } else {

        this.evento.agravos = await this.pegaId(this.agravos);
        this.evento.ras = await this.pegaId(this.ras);
        this.evento.equipe = await this.pegaId(this.equipe);

        if (this.validaCampo() == true) {

          this.evento.idLogado = this.usuarioLogado.id


          eventoService
            .salvarEvento(this.evento)
            .then((res) => {
              this.stateValida = {};
              this.alert.mensagem = res.mensagem;
              this.alert.variant = "success";
              this.showAlert();

              this.listarEvento();

              this.eventoClonado = JSON.parse(JSON.stringify(this.evento));
              document.getElementById("btn-salvar").textContent = "Alterar"




            })
            .catch((erro) => {
              console.log(erro)
              this.alert.mensagem = "Atenção! Erro ao salvar Evento.";
              this.alert.variant = "danger";
              this.showAlert();
            });

        } else {
          this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
          this.alert.variant = "danger";
          this.showAlert();
        }
      }
    },
    listarAbrangencia() {
      constanteService
        .listarAbrangencia().then((res) => {
          this.itemAbrangencia = res;
        });
    },
    listarRa() {
      regiaoAdministrativaService.listarTodasRas().then((res) => {
        this.itemRa = res;
        this.itemRa = this.formataObjeto(this.itemRa);

      });
    },
    listarEvento() {
      eventoService.listarTodosEventos(this.usuarioLogado.id).then((res) => {
        this.itemEvento = res;

      });
    },
    listarTipoEvento() {
      constanteService.listarTipoEvento().then((res) => {
        this.itemTipo = res;


      });
    },
    listarModalidade() {
      constanteService.listarModalidade().then((res) => {
        this.itemModalidade = res;

      });
    },

    listarAgravo() {
      agravoService
        .listarAgravo().then((res) => {
          this.itemAgravo = res;
          this.itemAgravo = this.formataObjeto(this.itemAgravo);

        });
    },
    listarSituacaoVisita() {
      constanteService
        .listarSituacaoVisita().then((res) => {
          this.itemSituacaoEvento = res;

        });
    },

    verificaLocalStore() {
      this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
      if (localStorage.getItem("usuarioPagina")) {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

      } else {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
      }
      // Carrega o vinculo
      if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
        this.vinculoSelecionado.indexVinculo = 0;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
        localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
      } else { // pega vinculo caso já esteja carregado
        this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
      }
    }
  },

}
</script>

<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 10px;

}
</style>



