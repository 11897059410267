<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" @click="inicio">
            <h2>SIGEPI</h2>
            <h6>Sistema Gerenciador de Vigilância Epidemiológica</h6>
            
        </a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
               
                <li class="nav-item ">

                    <b-button @click="visita()">Visita</b-button>
                    <b-dropdown id="dropdown-right" right text="Cargo" class="m-2">
                        <b-dropdown-item @click="Origem">Origem</b-dropdown-item> 
                        <b-dropdown-item @click="Carreira">Carreira</b-dropdown-item>                         
                        <b-dropdown-item @click="Cargo">Cargo</b-dropdown-item>
                                              
                    </b-dropdown>

                  <b-dropdown id="dropdown-right" right text="Lotação" class="m-2">
                    <b-dropdown-item @click="gerencia">Gerência</b-dropdown-item>                   
                    <b-dropdown-item @click="nucleo">Núcleo</b-dropdown-item>                    
                    <b-dropdown-item @click="setor">Setor</b-dropdown-item>                        
                 </b-dropdown>

                 <b-dropdown id="dropdown-right" right text="Regional" class="m-2">
                    <b-dropdown-item @click="RegionalSaude">Regional Saúde</b-dropdown-item> 
                    <b-dropdown-item @click="RegiaoAdministrativa">Região Administrativa</b-dropdown-item>
                                          
                 </b-dropdown>
                
                
                 <b-dropdown id="dropdown-right" right text="Outros" class="m-2">
                    <b-dropdown-item @click="Agravo">Agravo</b-dropdown-item>
                    <b-dropdown-item @click="InstituicaoEnsino">Instituição</b-dropdown-item>
                    <b-dropdown-item @click="infoPessoais()">Informações Pessoais</b-dropdown-item>                        
                    <b-dropdown-item @click="informacoesFuncionais()">Informações Funcionais</b-dropdown-item>
                    <b-dropdown-item @click="aprovarcadastro()">Pré-Cadastro</b-dropdown-item>
                    <b-dropdown-item @click="preceptoria()">Preceptoria</b-dropdown-item>
                    <b-dropdown-item @click="evento()">Evento</b-dropdown-item>
                    <b-dropdown-item @click="mentoria()">Mentoria</b-dropdown-item>
                    <b-dropdown-item @click="projeto()">Projeto</b-dropdown-item>
                    
                 </b-dropdown>

                 <b-dropdown id="dropdown-right" right text="Qualidade de Vida" class="m-2">
                    <b-dropdown-item @click="praticas()">Práticas Integrativas</b-dropdown-item>
                    <b-dropdown-item @click="donsArtiticos()">Dons Artísticos</b-dropdown-item>
                 </b-dropdown>

                 <b-dropdown id="dropdown-right" right text="Competência" class="m-2">
                    <b-dropdown-item @click="Publicacao">Publicação</b-dropdown-item>
                    <b-dropdown-item @click="competencia">Competências</b-dropdown-item>
                 </b-dropdown>

                 <b-dropdown id="dropdown-right" right text="Formação Profissional" class="m-2">
                    <b-dropdown-item @click="cursos()">Formação e Cursos</b-dropdown-item>                            
                    <b-dropdown-item @click="Idioma()">Idioma</b-dropdown-item>
                 </b-dropdown>
                                       
                 
                 
                   
                   
                    <!-- <b-dropdown id="dropdown-right" right text="Pessoas" class="m-2">
                        <b-dropdown-item @click="infoPessoais()">Informações Pessoais</b-dropdown-item>                        
                        <b-dropdown-item @click="informacoesFuncionais()">Informações Funcionais</b-dropdown-item>
                        <b-dropdown-item @click="Aprovarcadastro()">Aprovar Cadastro</b-dropdown-item>
                        <b-dropdown id="dropdown-right" right text="Formação profissional" class="m-2">
                            <b-dropdown-item @click="cursos()">Formação e Cursos</b-dropdown-item>
                            <b-dropdown-item @click="expProfissional()">Experiência Profissional</b-dropdown-item>
                            <b-dropdown-item @click="Idioma()">Idioma</b-dropdown-item>
                            <b-dropdown-item @click="Publicacao">Publicação</b-dropdown-item>
                            <b-dropdown-item @click="praticas()">Práticas</b-dropdown-item>
                            <b-dropdown-item @click="donsArtiticos()">Dons Artísticos</b-dropdown-item>
                            <b-dropdown-item @click="competencia">Competências</b-dropdown-item>
                        </b-dropdown> 
                  </b-dropdown>  -->
                </li>
                



             <!--    <b-dropdown id="dropdown-right" right text="Apoio" class="m-2">
                    
                    <b-dropdown-item @click="Agravo">Agravo</b-dropdown-item>
                    <b-dropdown-item @click="Cargo">Cargo</b-dropdown-item>
                    <b-dropdown-item @click="Carreira">Carreira</b-dropdown-item>
                    <b-dropdown-item @click="gerencia">Gerência</b-dropdown-item>
                    <b-dropdown-item @click="InstituicaoEnsino">Instituição</b-dropdown-item>
                    <b-dropdown-item @click="nucleo">Núcleo</b-dropdown-item>
                    <b-dropdown-item @click="Origem">Origem</b-dropdown-item>                    
                    <b-dropdown-item @click="RegiaoAdministrativa">Região Administrativa</b-dropdown-item>
                    <b-dropdown-item @click="RegionalSaude">Regional Saúde</b-dropdown-item>
                    <b-dropdown-item @click="setor">Setor</b-dropdown-item>
                    
                </b-dropdown> -->
                

                        <img id="photo-preview" class="perfil" type="file" width="50" height="50" alt="logo maujor"
                            accept="image/png, image/jpeg">
               

                <b-dropdown id="dropdown-right" right text="" class="m-2">


            
                    <b-dropdown-item @click="alterarFoto()">Alterar foto de perfil</b-dropdown-item>
                    <b-dropdown-item @click="alterarSenha">Alterar senha</b-dropdown-item>
                    <b-dropdown-item @click="logout">Sair</b-dropdown-item>

                </b-dropdown>
            </ul>
        </div>
    </nav>
</template>
<script>

export default {
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            menssagem: "erro, fazer novamente"
        }
    },
    props: {
        parametros: Object,
    },
    computed: {
        routes() {
            return this.$router.options.routes.filter(route => "meta" in route && "displayName" in route.meta)
        },
    },
    components: {

    },
    mounted() {/* 
        this.inicializar(); */
        this.verificaLocalStore();
        this.mostrarFoto();
    },
    methods: {
        mostrarFoto() {
            document.querySelector("#photo-preview").src = "data:image/gif;base64," + this.usuarioLogado.foto;
        },
/*         async inicializar() {
            await this.verificaLocalStore()
            await this.ativarCarregaFoto();
            await 
        },
        async ativarCarregaFoto() {
            console.log("Oi")
            const photoFile = document.getElementById('photo-file');


            document.getElementById('select-image').onclick = await function () {
                photoFile.click()
            }

          
             await window.addEventListener('DOMContentLoaded', () => {
                photoFile.addEventListener('change', () => {
                    let file = photoFile.files.item(0)

                    // ler um arquivo
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function (event) {
                        let image = document.getElementById('photo-preview')
                        image.src = event.target.result

                        const encoder = new TextEncoder()
                        const view = encoder.encode(image.src);
                        console.log("Enconder", view)

                        let dataForm = new FormData();
      for (let filef of this.$refs.files.files) {
        dataForm.append(`file`, filef);
      }

            usuarioService.alterarFoto(1, 1, dataForm).then((res) => {
                            console.log(res)

                        }).catch((erro) => {
                            console.log(erro)
                        });

                    }
                })
            })
        }, */
        createToast() {

        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            /* if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else { // pega vinculo caso já esteja carregado
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
            } */
        },
        limpaLocalStore() {
            localStorage.clear();
        },
        /* novoCadastro() {
            this.$router.push({
                path: "dadosPessoais",
                params: { parametros: "" },
            });
        }, */
        administrativo() {
            this.$router.push({
                path: "administrativo",
                params: { parametros: "" },
            });
        },
        infoPessoais() {
            this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            if (this.vinculoSelecionado.idVinculo) {
                this.$router.push({
                    path: "infoPessoais",
                    params: {
                        parametros: ""
                    },
                });
            } else {
                this.mostrarToast();
                //this.createToast()
            }
        },
        informacoesFuncionais() {
            this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            if (this.vinculoSelecionado.idVinculo) {
                console.log("entrou aqui no if")
                this.$router.push({
                    path: "informacoesFuncionais",
                    params: {
                        parametros: ""
                    },
                });
            } else {
                //this.mostrarToast();     
                alert("teste")
            }
        },
        mostrarToast() {
            this.$bvToast.toast('Operação não permitida!', {
                title: "É necessário selecionar um usuário na página principal.",
                variant: "danger",
                appendToast: false,
                toaster: "b-toaster-top-center"
            });
        },
        cursos() {
            this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            if (this.vinculoSelecionado.idVinculo) {
                this.$router.push({
                    path: "curso",
                    params: {
                        parametros: ""
                    },
                });
            } else {
                this.mostrarToast();
            }
        },
        diretoria() {
            this.$router.push({
                path: "diretoria",
                params: { parametros: "" },
            });
        },
        gerencia() {
            this.$router.push({
                path: "gerencia",
                params: { parametros: "" },
            });
        },
        nucleo() {
            this.$router.push({
                path: "nucleo",
                params: { parametros: "" },
            });
        },
        setor() {
            this.$router.push({
                path: "setor",
                params: { parametros: "" },
            });
        },
        alterarFoto() {
            this.$router.push({
                path: "alterarFoto",
                params: { parametros: "" },
            });
        },
        InstituicaoEnsino() {
            this.$router.push({
                path: "instituicao",
                params: { parametros: "" },
            });
        },
        Origem() {
            this.$router.push({
                path: "origem",
                params: { parametros: "" },
            });
        },
        Carreira() {
            this.$router.push({
                path: "carreira",
                params: { parametros: "" },
            });
        },
        Cargo() {
            this.$router.push({
                path: "cargo",
                params: { parametros: "" },
            });
        },
        expProfissional() {
            this.$router.push({
                path: "experienciaProfissional",
                params: { parametros: "" },
            });
        },
        aprovarcadastro() {
            this.$router.push({
                path: "aprovacaoCadastro",
                params: { parametros: "" },
            });
        },
        Idioma() {
            this.$router.push({
                path: "idioma",
                params: { parametros: "" },
            });
        },
        logout() {

            this.limpaLocalStore();
            this.$router.push({
                path: "/",
                params: { parametros: "" },
            });
        },
        alterarSenha() {

            this.$router.push({
                path: "alterarSenha",
                params: { parametros: "" },
            });
        },

        inicio() {
            this.$router.push({
                path: "inicio",
                params: { parametros: "" },
            });
        },
        Agravo() {
            this.$router.push({
                path: "agravo",
                params: { parametros: "" },
            });
        },
        RegionalSaude() {
            this.$router.push({
                path: "regionalSaude",
                params: { parametros: "" },
            });
        }, 
        RegiaoAdministrativa() {
            this.$router.push({
                path: "regiaoAdministrativa",
                params: { parametros: "" },
            });
        }, 
        Publicacao() {
            this.$router.push({
                path: "publicacao",
                params: { parametros: "" },
            });
        }, 
        praticas() {
            this.$router.push({
                path: "praticas",
                params: { parametros: "" },
            });
        }, 
        donsArtiticos() {
            this.$router.push({
                path: "donsArtisticos",
                params: { parametros: "" },
            });
        }, 
        competencia() {
            this.$router.push({
                path: "competencia",
                params: { parametros: "" },
            });
        }, 
        visita() {
            this.$router.push({
                path: "visita",
                params: { parametros: "" },
            }); 
        }, 
        preceptoria() {
            this.$router.push({
                path: "preceptoria",
                params: { parametros: "" },
            }); 
        }, 
        evento() {
            this.$router.push({
                path: "evento",
                params: { parametros: "" },
            }); 
        }, 
        mentoria() {
            this.$router.push({
                path: "mentoria",
                params: { parametros: "" },
            }); 
        }, 
        projeto() {
            this.$router.push({
                path: "projeto",
                params: { parametros: "" },
            }); 
        }, 
        
        
    }
}
</script>
<style scope>
.dropdown:hover .dropdown-menu {
    display: block;
    position: absolute;
    /* distancia entre menu e btn */
    position: absolute;
    transform: translate3d(-50%, 38px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}

.dropdown {
    display: inline-block;
    /* deixa o LO do "tamanho do próprio texto" e não da largura inteira da tela*/
    list-style: none;
    /* rita a "bolinha" do LI*/
}

.btn-secondary {
    --bs-btn-bg: rgba(0, 0, 0, 0) !important;
    --bs-btn-border-color: rgba(0, 0, 0, 0) !important;
    --bs-btn-hover-bg: rgba(0, 0, 0, 0.15) !important;
    --bs-btn-hover-border-color: rgba(0, 0, 0, 0) !important;
}

.perfil {
    border-radius: 50%;
}

/* #photo-file {
    display: none;
}  */
</style>
