import axios from "axios";

export const informacaoFuncionalService = {
  salvarInformacaoFuncionais(objInfoFuncionais) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/vinculo`, objInfoFuncionais)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarInformacaoFuncionais(idVinculo, objInfoFuncionais) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/vinculo/${idVinculo}`,
          objInfoFuncionais
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarLotacao(idVinculo, objLotacao) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/vinculo/lotacao/${idVinculo}`,
          objLotacao
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, 
  alterarNivelAcesso(idVinculo, objNivelAcesso) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/vinculo/${idVinculo}`,
          objNivelAcesso
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarInformacaoFuncional(idUsuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/vinculo/usuario/${idUsuario}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarCargoHoraria() {
    return new Promise((resolve) => {
      let cargaHoraria = [
        { cargaHoraria: 20 },
        { cargaHoraria: 24 },
        { cargaHoraria: 30 },
        { cargaHoraria: 40 },
      ];

      resolve(cargaHoraria);
    });
  },
};
