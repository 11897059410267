import axios from "axios";

export const enderecoService = {
  listarEndereco(idUsuario) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/endereco/listar/${idUsuario}`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  criarEndereco(endereco) {
    return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/endereco/`, endereco)
          .then((ret) => {
            resolve(ret.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
  },
  alterarEndereco(idEndereco, endereco) {
    return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_URL_BASE_BACK_END}/endereco/${idEndereco}`, endereco)
          .then((ret) => {
            resolve(ret.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
  },
};
