<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Agravo</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
               
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>

                            <b-form-input id="input-live" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome do Agravo" trim v-model="agravo.nome" SIZE="60"
                                MAXLENGTH="60"></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="input-live" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla do Agravo" trim v-model="agravo.sigla" SIZE="20"
                                MAXLENGTH="20"></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>
                       
                    </div>

                </div>
                <div class="legend1 ">

                    <fieldset class="fieldset ">

                        <div class="row">
                            <div class="btn-group" role="group">

                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarAgravo" variant="outline-success" id="btn-salvar">
                                        Salvar
                                    </b-button>
                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoAgravo()">
                                        Novo</b-button>

                                </div>&nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas dos Agravos</h2>
                

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>

                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="ag in itemsAgravo" :key="ag.id">

                                <td>{{ ag.nome }}</td>
                                <td>{{ ag.sigla }}</td>

                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="agravo = JSON.parse(JSON.stringify(ag)), editarAgravo(ag)">
                                           
                                            Editar
                                        </b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { agravoService } from "@/service/agravoService";


export default {
   
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                nome: null,
                sigla: null,
            },
            //  usuarioPagina: {},
            agravo: {
                nome: "",
                sigla: "",                
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },

            agravoClonada: {},

            tipoDiretoria: [],
            infoAgravo: {},
            validado:"",

            mensagem: "",
            stateNome: null,
            stateSigla: null,
            fields: ['nome', 'sigla'],



            itemsAgravo: [],
            idAgravoClonado: "",

        }
    },
    props: {
        parametros: Object,
    },
    mounted() {
        this.inicializar();


    },
    methods:{   
        
        async inicializar() {
            await this.verificaLocalStore();
          
            this.listarAgravo();
            this.alerta = {};
        },

        limparObjetoAgravo() {
            this.alerta = {};
            this.idAgravoClonado = "";

            this.agravoClonada.nome = "";
            this.agravoClonada.sigla = "";            
            this.agravoClonada.idLogado = "";
            this.agravoClonada.id = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"

            }, 200)

            this.agravo = {};

        },
        async buscarValorParametro() {           

            this.listarAgravo()

        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        async buscaAgravo(idAgravo) {

            await agravoService
                .pegaAgravo(idAgravo)
                .then((res) => {

                    this.infoAgravo = res;
                    console.log("pegou agravo legal", this.infoAgravo)


                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        validaCampo() {

            if (this.agravo.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.agravo.nome) {
                this.stateValida.nome = false;
                this.validado = false;
            }
            if (this.agravo.sigla) {
                this.stateValida.sigla = true;
                this.validado = true;

            }
            else if (!this.agravo.sigla) {
                this.stateValida.sigla = false;
                this.validado = false;
            }
            return this.validado;



        },
        editarAgravo() {
            this.alerta = {};
            document.getElementById("btn-salvar").textContent = "Alterar"
            this.idAgravoClonado = "";

            this.agravoClonada.nome = "";
            this.agravoClonada.sigla = "";            
            this.agravoClonada.idLogado = "";
            this.agravoClonada.id = "";




        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        addAgravo() {
            let itemAbono = {};
            itemAbono.Período = ` ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataInicio)))} a ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataFim)))}`;
            setTimeout(() => {
                this.items.push(itemAbono)
                localStorage.setItem("abono", JSON.stringify(this.items));
            }, 900)

        },
        adicionarAgravo() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarAgravo();
                }, 200)
            } else {
                if (this.validaCampo() == true) {
                    
                    this.agravo.idLogado = this.usuarioLogado.id
                    this.idAgravoClonado = "";

                    this.agravoClonada.nome = "";
                    this.agravoClonada.sigla = "";                    
                    this.agravoClonada.idLogado = "";
                    this.agravoClonada.id = "";

                    agravoService
                        .salvarAgravo(this.agravo)
                        .then((res) => {

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                            document.getElementById("btn-salvar").textContent = "Alterar"



                            setTimeout(() => {
                                this.alerta.msnSuccess = "";
                                this.agravoClonada = JSON.parse(JSON.stringify(this.agravo));

                            }, 3000)


                            this.showAlert();
                            document.getElementById("btn-salvar").textContent = "Alterar"


                        })
                        .catch(() => {

                        }).
                        finally(() => {
                            this.listarAgravo();
                            
                        })
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarAgravo() {
            this.alert = {};

            for (let index = 0; index < this.itemsAgravo.length; index++) {

                if (this.agravoClonada.nome === this.itemsAgravo[index].nome) {
                    this.idAgravoClonado = this.itemsAgravo[index].id
                    console.log(this.idAgravoClonado)
                    break;
                }


            }


            if (this.idAgravoClonado) {
                this.agravo.id = this.idAgravoClonado;

            }

            if (this.validaCampo() == true) {
                
                this.agravo.idLogado = this.usuarioLogado.id


                agravoService
                    .alterarAgravo(this.agravo.id, this.agravo)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                        this.listarAgravo();
                    })
                    .catch(() => {

                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        listarAgravo() {
           

            agravoService
                .listarAgravo().then((res) => {
                    this.itemsAgravo = res;
                    console.log("agravo é", this.itemsAgravo)
                    
                    console.log("agravo clonada é", this.agravoClonada)
                });
        },
    },


}

</script>
<style scoped>

</style>