<template>
  <div id="app" >

    <Header v-if="notInLoginPage && notInPreCadastro" />
    <!-- <Header v-else-if="notInPreCadastro" /> -->
   
    <Content />

  </div>
</template>

<script>


import Content from './partials/template/Content.vue';
import Header from './partials/template/Header.vue';


export default {
  name: 'App',

  data(){
    return {
    
    }
  },
  components: {
    Content,
    Header
    
  }, 
computed: {
    notInLoginPage() {
      if ( this.$route.name !== "login") return true
      return false;
    },
    notInPreCadastro() {
      if ( this.$route.name !== "PreCadastroServidor") return true
      return false;

    } 
  }

}
</script>

<style>
@import "@/assets/css/divep-css-global.css";
</style>
