<template>
  <div class="container-login col-md-12">
    <div class="card-login col-md-3 mt-5">
      <div class="row">
        <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
        <div class="col-12">
          <h1 class="h3 mb-3 font-weight-normal">Alterar Senha</h1>

          <label for="inputPassword" class="sr-only">Senha atual<strong style="color:red">*</strong></label>
          <input type="password" id="inputPassword" class="form-control" required="" v-on:change="validaCampo"
            v-model="objSenha.senhaAtual" :state="stateValida.senhaAtual">
          <div class="invalid-feedback">Campo Data na chegada da Diretoria é obrigatório!</div>
          <label for="inputPassword" class="sr-only">Nova senha<strong style="color:red">*</strong></label>
          <input type="password" id="inputPassword" class="form-control" required="" v-on:change="validaCampo"
            v-model="objSenha.novaSenha" :state="stateValida.novaSenha">
          <div class="invalid-feedback">Campo Data na chegada da Diretoria é obrigatório!</div>
          <label for="inputPassword" class="sr-only">Confirmar senha<strong style="color:red">*</strong></label>
          <input type="password" id="inputPassword" class="form-control" required="" v-on:change="validaCampo"
            v-model="objSenha.confirmaSenha" :state="stateValida.confirmaSenha">
          <div class="invalid-feedback">Campo Data na chegada da Diretoria é obrigatório!</div>

          

          <div id="alerta-mensagem" class="d-none alert alert-warning alert-dismissible fade show" role="alert">
            <strong>Falha de Login!</strong> Usuário ou senha estão incorretos.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <button class="btn btn-lg btn-primary btn-block" type="button" @click="alterarSenha">Alterar</button>&nbsp;      


            <router-link to="/inicio">
           <button class="btn btn-lg btn-primary btn-block" type="button" @click="alterarSenha">Voltar</button>
           </router-link>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>


import { loginService } from "@/service/loginService";

import { mask } from "vue-the-mask";

export default {
  directives: { mask },

  

  data() {
    return {
      alert: {
        mensagem: "",
        variant: ""
      },

      objSenha: {
        senhaAtual: "",
        novaSenha: "",
        confirmaSenha: "",
        idLogado: "",
      },
      stateValida: {
        senhaAtual: null,
        novaSenha: null,
        confirmaSenha: null,
        

      },

      dismissSecs: 10,
      dismissCountDown: 0,

      showDismissibleAlert: false,
      usuarioLogado: {},
      usuarioPagina: {},
      vinculoSelecionado: {},
      validado:"",
      


    }
  },
  computed: {
  idLogado2 () {return this.usuarioLogado.id},

  },
  component: {
    // Header
  },
   mounted() {
        this.inicializar();
    },
  methods: {
     inicializar() {
       this.verificaLocalStore();
    },
    validaCampo() {
      if (this.objSenha.senhaAtual) {
        this.stateValida.senhaAtual = true;
        this.validado =  true;
      }
      else if (!this.objSenha.senhaAtual) {
        this.stateValida.senhaAtual = false;
        this.validado =  false;
      }
      if (this.objSenha.novaSenha) {
        this.stateValida.novaSenha = true;
        this.validado =  true;
      }
      else if (!this.objSenha.novaSenha) {
        this.stateValida.novaSenha = false;
        this.validado =  false;
      }
      if (this.objSenha.confirmaSenha) {
        this.stateValida.confirmaSenha = true;
        this.validado =  true;
      }
      else if (!this.objSenha.confirmaSenha) {
        this.stateValida.confirmaSenha = false;
        this.validado =  false;
      }
      return this.validado
    },
    verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
    ountDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    alterarSenha() {
      this.alert = {};

      if (this.validaCampo() == true) {
       
        this.objSenha.idLogado = this.usuarioLogado.id
        let idUsuario = this.usuarioPagina.id;
        loginService
          .alterarSenha(idUsuario, this.objSenha)
          .then((res) => {
            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert();

          })
          .catch(() => {


          });
      }else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
                    this.alert.variant = "danger";
                    this.showAlert();
                }
    }
  },
  
  RetiraMascara() {

    let cpf = this.cpf;
    if (cpf.length == 14) {
      cpf = cpf.charAt(0) + cpf.charAt(1) + cpf.charAt(2) +
        cpf.charAt(4) + cpf.charAt(5) + cpf.charAt(6) +
        cpf.charAt(8) + cpf.charAt(9) + cpf.charAt(10) +
        cpf.charAt(12) + cpf.charAt(13);

      this.cpf = cpf;


      this.validar();
    } else {
      this.showAlert();
    }
  }

}

</script>
