<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Informações Funcionais</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Matrícula<strong style="color:red">*</strong></label>
                                <b-form-input id="matricula" :state="stateValida.matricula" v-on:change="validaCampo"
                                    v-model="informacaoFuncional.matricula" SIZE="5" MAXLENGTH="12"
                                    aria-describedby="input-live-help input-live-feedback">
                                </b-form-input>
                                <div class="invalid-feedback">Campo Matrícula é obrigatório!</div>
                            </div>
                        </div>
                        
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">E-mail Institucional</label>
                                <b-form-input id="email" type="text" v-model="informacaoFuncional.emailInstitucional"
                                    aria-describedby="input-live-help input-live-feedback" trim></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Carga Horária</label>
                                <select class="form-control mb-3 input-select" id="idCargaHoraria"
                                    aria-describedby="input-live-help input-live-feedback"
                                    v-model="informacaoFuncional.cargaHoraria">
                                    <option v-for="carga in itemsCargaHoraria" :key="carga.cargaHoraria"
                                        :value="carga.cargaHoraria">{{ carga.cargaHoraria }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Aposentadoria</label>
                                <b-form-input id="aposentadoria" aria-describedby="input-live-help input-live-feedback"
                                    v-model="informacaoFuncional.aposentadoria" SIZE="6" MAXLENGTH="6">
                                </b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Data de admissão<strong style="color:red">*</strong></label>
                                <b-form-input id="datepicker" type="date" :state="stateValida.admissao"
                                    v-on:change="validaCampo" aria-describedby="input-live-help input-live-feedback"
                                    v-model="informacaoFuncional.admissao" class="datepicker mb-3"
                                    data-date-format="mm/dd/yyyy">
                                </b-form-input>
                                <div class="invalid-feedback">Campo Data admissão é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Data na diretoria<strong style="color:red">*</strong></label>
                                <b-form-input id="datepicker-invalid" type="date" :state="stateValida.chegouDiretoria"
                                    v-on:change="validaCampo" aria-describedby="input-live-help input-live-feedback"
                                    v-model="informacaoFuncional.chegouDiretoria" class="datepicker"
                                    data-date-format="mm/dd/yyyy">
                                </b-form-input>
                                <div class="invalid-feedback">Campo Data na chegada da Diretoria é obrigatório!</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2">
                            <label for="input-live">Origem</label>
                            <select class="form-control mb-3 input-select" id="refOrigemDrop"
                                v-on:change="listarCarreira()" @click="itemsCargo = []">
                                <option value="0"></option>
                                <option v-for="origem in tipoOrigem" :key="origem.id" :value="origem.id">{{
                                        origem.sigla
                                }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3">
                            <label for="input-live">Carreira</label>
                            <select class="form-control mb-3 input-select" id="refCarreiraDrop"
                                v-on:change="listarCargo()" v-model="cargo.carreira">
                                <option value="0"></option>
                                <option v-for="carreira in tipoCarreira" :key="carreira.id" :value="carreira.id">{{
                                        carreira.sigla
                                }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3">
                            <label for="input-live">Cargo</label>
                            <select class="form-control mb-3 input-select" id="refCargoDrop"
                                v-model="informacaoFuncional.cargo">
                                <option value="0"></option>
                                <option v-for="cargoServidor in itemsCargo" :key="cargoServidor.id"
                                    :value="cargoServidor.id">{{
                                            cargoServidor.sigla
                                    }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-4">
                            <fieldset>
                                
                                <label for="input-live">Nível de Acesso</label>
                                <div class="input-group mb-3">
                                    <select class="form-control mb-3 input-select" id="nivelAcesso"
                                        v-model="informacaoFuncional.nivelAcesso">
                                        <option value=""></option>
                                        <option v-for="nivel in itemsNivelAcesso" :key="nivel.id" :value="nivel.id">{{
                                                nivel.id
                                        }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-success" type="button"
                                            @click="alterarNivelAcesso" :disabled="usuarioPagina.id == usuarioLogado.id">
                                            <abbr title="Clique aqui para alterar o Nivel de Acesso.">
                                                <i class="fa fa-edit"></i>
                                            </abbr>
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1">
                        <div class="container-buttons-salvar">
                            <b-button id="btn-salvarFuncionais" @click="alterarInformacaoFuncional"
                                variant="outline-success">
                                Alterar</b-button>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div class="container-buttons-salvar">
                            <b-button id="btn-novoFuncionaisNovo" @click="novoFuncionais" variant="outline-success">Novo
                            </b-button>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div class="col-lg-1">
                            <div class="container-buttons-salvar">
                                <router-link to="/inicio">
                                    <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- Tabela  -->

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Lista de Informações Funcionais</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Matricula</th>
                                <th>Data Admissão</th>
                                <th>Data Diretoria</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="infoFunci in itemsInformacaoFuncional" :key="infoFunci.matricula">
                                <td>{{ infoFunci.matricula }} </td>
                                <td>{{ infoFunci.admissao }} </td>
                                <td>{{ infoFunci.chegouDiretoria }} </td>
                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="informacaoFuncional = JSON.parse(JSON.stringify(infoFunci)), editarInformacaoFuncional(infoFunci)">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { nivelAcessoService } from "@/service/nivelAcessoService";
import { informacaoFuncionalService } from "@/service/informacaoFuncionalService";
import { cargoService } from "@/service/cargoService";
import { carreiraService } from "@/service/carreiraService";
import { origemService } from "@/service/origemService";
//import { error } from 'jquery';

export default {
/*     beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  }, */
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            idSetorPaginaInicial: {},

            alert: {
                mensagem: "",
                variant: ""
            },
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,

            vinculoIdSelecionado: {
                id: "",
                indexVinculo: ""
            },

            stateValida: {
                valida: null,
                matricula: null,
                admissao: null,
                chegouDiretoria: null
            },
            informacaoFuncional: {
                matricula: "",
                setor: "",
                emailInstitucional: "",
                cargaHoraria: "",
                nivelAcesso: "",
                aposentadoria: "",
                cargo: null,
                admissao: "",
                chegouDiretoria: "",
                usuario: "",
                idLogado: "",
            },

            cargo: {
                nome: "",
                sigla: "",
                carreira: "",
                idLogado: "",
            },

            tipoCarreira: [],
            itemsCargo: [],
            tipoOrigem: [],
            itemsCargaHoraria: [],
            itemsNivelAcesso: [],
            itemsInformacaoFuncional: [],
        }
    },
    mounted() {
        this.inicializar()
    },

    methods: {
        async inicializar() {
            await this.verificaLocalStore();
            await this.listarInformacaoFuncional();
            await this.listaCargaHoraria();
            await this.carregaVinculoInicial();
            await this.listarNivelAcesso();
            await this.pegaCargoInicial();
            await this.listarOrigem();
        },
        async listarOrigem() {
            await origemService
                .listarOrigem().then((res) => {
                    this.tipoOrigem = res;
                });
        },
        async listarCarreira() {
            this.tipoCarreira = {}
            let idOrigem = document.getElementById("refOrigemDrop").value;
            await carreiraService
                .listarCarreira(idOrigem).then((res) => {
                    this.tipoCarreira = res;
                    if (res.length > 0) {
                        this.tipoCarreira = res;
                    } else {
                        this.tipoCarreira = {}
                    }
                });
        },
        async listarCargo() {
            let idCarreira = document.getElementById("refCarreiraDrop").value
            if (idCarreira != 0) {
                await cargoService
                    .listarCargo(idCarreira).then((res) => {
                        if (res.length > 0) {
                            this.itemsCargo = res;

                        } else {
                            this.itemsCargo = {}
                        }
                    }).catch((erro) => {
                        console.log(erro)
                        this.itemsCargo = {}
                    });
            } else {
                this.itemsCargo = {};
                this.cargo.nome = "";
                this.cargo.sigla = "";
            }
        },
        pegaCargoInicial() {
            if (this.informacaoFuncional.cargo.id && this.informacaoFuncional.cargo.id != 0) {

                cargoService.pegaCargo(this.informacaoFuncional.cargo.id).then((res) => {
                    console.log("CARGO INICIAL", res)
                    let cargo = [res]
                    this.itemsCargo = cargo;
                })
            }
        },
        async listaCargaHoraria() {
            await informacaoFuncionalService
                .listarCargoHoraria().then((res) => {
                    this.itemsCargaHoraria = res;
                    console.log("carga horaria", this.itemsCargaHoraria)
                });
        },
        listarInformacaoFuncional() {
            let idUsuario = this.usuarioPagina.id
            informacaoFuncionalService
                .listarInformacaoFuncional(idUsuario).then((res) => {
                    this.itemsInformacaoFuncional = res;
                    this.informacaoFuncional.nivelAcesso =  this.itemsInformacaoFuncional[0].nivelAcesso
                   
                });
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
                this.idSetorPaginaInicial = JSON.parse(localStorage.getItem("setorSelecionado"));
            }
        },
        carregaVinculoInicial() {
            if (this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].admissao) {
                this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].admissao = this.inverterCampoData(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].admissao)
            }
            if (this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].chegouDiretoria) {
                this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].chegouDiretoria = this.inverterCampoData(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].chegouDiretoria)
            }
            this.informacaoFuncional = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo];
            console.log("Vinculo carregado inicial", this.informacaoFuncional);
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        reverterCampoData(data) {
            data = data
                .split("-")
                .reverse()
                .join("-");
            data = data.replaceAll("-", "/")

            return data;
        },
        validaCampo() {
            this.stateValida.valida = true;
            if (this.informacaoFuncional.matricula) {
                this.stateValida.matricula = true;
            }
            else if (!this.informacaoFuncional.matricula) {
                this.stateValida.matricula = false;
                this.stateValida.valida = false;
            }
            if (this.informacaoFuncional.admissao) {
                this.stateValida.admissao = true;
            }
            else if (!this.informacaoFuncional.admissao) {
                this.stateValida.admissao = false;
                this.stateValida.valida = false;
            }
            if (this.informacaoFuncional.chegouDiretoria) {
                this.stateValida.chegouDiretoria = true;
            }
            else if (!this.informacaoFuncional.chegouDiretoria) {
                this.stateValida.chegouDiretoria = false;
                this.stateValida.valida = false;
            }
        },
        alterarInformacaoFuncional() {
            this.validaCampo();
            if (this.stateValida.valida) {
                this.alert = {};
                if (this.idSetorPaginaInicial.idSetor && this.idSetorPaginaInicial.idSetor != 0) {
                    if (document.getElementById("btn-salvarFuncionais").textContent == "Salvar") {
                        this.adicionarInformacaoFuncional();
                    } else {
                        if (this.informacaoFuncional.admissao) {
                            this.informacaoFuncional.admissao = this.inverterCampoData(this.informacaoFuncional.admissao);
                        }
                        if (this.informacaoFuncional.chegouDiretoria) {
                            this.informacaoFuncional.chegouDiretoria = this.inverterCampoData(this.informacaoFuncional.chegouDiretoria);
                        }
                        this.pegaIdVinculo();
                        let idVinculo = this.vinculoIdSelecionado.id;
                        console.log("idVinculo", idVinculo)
                        this.informacaoFuncional.usuario = this.usuarioPagina.id
                        this.informacaoFuncional.idLogado = this.usuarioLogado.id
                        this.informacaoFuncional.setor = this.idSetorPaginaInicial.idSetor;
                        console.log("informacao funcion alter", this.informacaoFuncional)
                        informacaoFuncionalService
                            .alterarInformacaoFuncionais(idVinculo, this.informacaoFuncional)
                            .then((res) => {
                                this.atualizaVinculoLocalStore("alterar");
                                this.listarInformacaoFuncional();
                                this.alert.mensagem = res.mensagem;
                                this.alert.variant = "success";
                                this.showAlert()
                                this.stateValida = {};
                            })
                            .catch((erro) => {
                                console.log(erro)
                                this.alert.mensagem = "Atenção! Erro ao alterar as Informações Funcionais.";
                                this.alert.variant = "danger";
                                this.showAlert();
                            });
                    }

                } else {
                    this.alert.mensagem = "Atenção! A Área Técnica deve ser selecionada na Tela de Início";
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }
        },
        pegaIdVinculo() {
            let matriculaSelecionada = this.informacaoFuncional.matricula;
            for (let index = 0; index < this.itemsInformacaoFuncional.length; index++) {
                if (this.itemsInformacaoFuncional[index].matricula == matriculaSelecionada) {
                    this.vinculoIdSelecionado.id = this.itemsInformacaoFuncional[index].id;
                    this.vinculoIdSelecionado.indexVinculo = index;
                    break;
                }
            }
        },
        atualizaVinculoLocalStore(acao) {
            let atualizaUsuarioVinculo = {};
            atualizaUsuarioVinculo.matricula = this.informacaoFuncional.matricula;
            atualizaUsuarioVinculo.emailInstitucional = this.informacaoFuncional.emailInstitucional;
            atualizaUsuarioVinculo.cargo = this.informacaoFuncional.cargo;
            atualizaUsuarioVinculo.cargaHoraria = this.informacaoFuncional.cargaHoraria;
            atualizaUsuarioVinculo.aposentadoria = this.informacaoFuncional.aposentadoria;
            atualizaUsuarioVinculo.admissao = this.reverterCampoData(this.informacaoFuncional.admissao);
            atualizaUsuarioVinculo.chegouDiretoria = this.reverterCampoData(this.informacaoFuncional.chegouDiretoria);
            console.log("Vinculo atualizado", atualizaUsuarioVinculo);
            if (acao == "alterar") {
                this.usuarioPagina.vinculos[this.vinculoIdSelecionado.indexVinculo] = atualizaUsuarioVinculo;
            }
            if (acao == "salvar") {
                let salvaInfoFuncionais = this.informacaoFuncional;
                /* salvaInfoFuncionais.admissao = this.reverterCampoData(salvaInfoFuncionais.admissao);
                salvaInfoFuncionais.chegouDiretoria = this.reverterCampoData(salvaInfoFuncionais.chegouDiretoria); */
                this.usuarioPagina.vinculos.push(salvaInfoFuncionais);
            }
            localStorage.removeItem("usuarioPagina");
            localStorage.setItem("usuarioPagina", JSON.stringify(this.usuarioPagina));
            this.verificaLocalStore();
        },
        adicionarInformacaoFuncional() {

            this.validaCampo();
            if (this.stateValida.valida) {
                this.alert = {};
                if (this.idSetorPaginaInicial.idSetor && this.idSetorPaginaInicial.idSetor != 0) {


                    this.informacaoFuncional.admissao = this.inverterCampoData(this.informacaoFuncional.admissao);

                    this.informacaoFuncional.chegouDiretoria = this.inverterCampoData(this.informacaoFuncional.chegouDiretoria);



                    this.informacaoFuncional.usuario = this.usuarioPagina.id
                    this.informacaoFuncional.idLogado = this.usuarioLogado.id
                    this.informacaoFuncional.setor = this.idSetorPaginaInicial.idSetor;
                    console.log("informacao funcion salvar", this.informacaoFuncional)
                    informacaoFuncionalService
                        .salvarInformacaoFuncionais(this.informacaoFuncional)
                        .then(async (res) => {
                            await this.atualizaVinculoLocalStore("salvar");
                            await this.listarInformacaoFuncional();
                            document.getElementById("btn-salvarFuncionais").textContent = "Alterar";
                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                            this.stateValida = {};
                        })
                        .catch((erro) => {
                            console.log(erro)
                            this.alert.mensagem = "Atenção! Erro ao salvar as Informações Funcionais.";
                            this.alert.variant = "danger";
                            this.showAlert();
                        });
                } else {
                    this.alert.mensagem = "Atenção! A Área Técnica deve ser selecionada na Tela de Início";
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }
        },
        alterarNivelAcesso() {
            let idVinculo = this.informacaoFuncional.id            
           // let idVinculo = this.vinculoIdSelecionado.id;
            let nivelDeAcesso = {} ;
            nivelDeAcesso.nivelAcesso = this.informacaoFuncional.nivelAcesso
            nivelDeAcesso.idLogado = this.usuarioLogado.id;
            informacaoFuncionalService.alterarNivelAcesso(idVinculo, nivelDeAcesso).then((res) =>{
                this.alert.mensagem = res.mensagem;
                this.alert.variant = "success";
                this.showAlert()
               // this.listarNivelAcesso();

            }).catch((error) =>{
                console.log(error)
                this.alert.mensagem = error.response.data.mensagem;
                this.alert.variant = "danger";
                this.showAlert();
            })

        },
        async editarInformacaoFuncional(infoFunci) {
          //  this.informacaoFuncional = JSON.parse(JSON.stringify(infoFunci))
        this.informacaoFuncional.cargo = infoFunci.cargo.id;
            await this.pegaCargoInicial();
            document.getElementById("btn-salvarFuncionais").textContent = "Alterar";

            setTimeout(() => {
                this.informacaoFuncional.admissao = this.inverterCampoData(infoFunci.admissao);
                this.informacaoFuncional.chegouDiretoria = this.inverterCampoData(infoFunci.chegouDiretoria);
            }, 900)

        },
        limpaCampos() {
            this.informacaoFuncional = {};
            this.informacaoFuncional.matricula = "";
            this.informacaoFuncional.setor = "";
            this.informacaoFuncional.emailInstitucional = "";
            this.informacaoFuncional.cargaHoraria = "";
            this.informacaoFuncional.nivelAcesso = "";
            this.informacaoFuncional.aposentadoria = "";
            this.informacaoFuncional.admissao = "";
            this.informacaoFuncional.chegouDiretoria = "";
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs;
        },
        async listarNivelAcesso() {
            await nivelAcessoService
                .listarNivelAcesso().then((res) => {
                    this.itemsNivelAcesso = res;
                });
        },
        novoFuncionais() {
            this.limpaCampos();
            document.getElementById("btn-salvarFuncionais").textContent = "Salvar";
        },
    },
}

</script>

<style scoped>
.borda {
    border-width: thin;
    border-style: solid;
    border-color: #198754;
    border-radius: 10px;

}
</style>