<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo"> Competências</h2>
            <br>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>

            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber agir</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-agir" v-model="competencias.agir" type="range" min="0"
                                        max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-agir">Saber o que e por que faz.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.agir }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber decidir</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-decidir" v-model="competencias.decidir" type="range" min="0"
                                        max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-decidir">Julgar e escolher.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.decidir }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber mobilizar</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-mobilizar" v-model="competencias.mobilizar" type="range"
                                        min="0" max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-mobilizar">Saber mobilizar recursos humanos, financeiros,
                                        materiais criando sinergia, entre eles.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.mobilizar }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber comunicar</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-1" v-model="competencias.comunicar" type="range" min="0"
                                        max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-1">Compreender, processar, transmitir informações e conhecimentos,
                                        assegurando o entedimento da mensagem pelos outros.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.comunicar }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber aprender</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-aprender" v-model="competencias.aprender" type="range"
                                        min="0" max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-aprender">Trabalhar o conhecimento e a experiências; rever modelos
                                        mentais; saber desenvolver-se e propiciar o, desenvolvimento dos outros.</label>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.aprender }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber comprometer-se</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-comprometer" v-model="competencias.comprometer" type="range"
                                        min="0" max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-comprometer">Saber engajar-se e comprometer-se com os objetivos da
                                        organização.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.comprometer }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber assumir responsabilidades</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-assumir" v-model="competencias.assumir" type="range" min="0"
                                        max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-assumir">Ser responsável, assumindo os riscos e as consequências
                                        de suas ações, e ser, por isso, reconhecido.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.assumir}}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4>Saber ter visão estratégica</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-input id="range-visao" v-model="competencias.ver" type="range" min="0"
                                        max="10" style="width:100%"></b-form-input>
                                </div>
                                <div class="col-lg-12">

                                    <label for="range-visao">Conhecer e entender o négocio da organização, seu ambiente,
                                        identicando, oportunidades, alternativas.</label>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-1 mt-2">{{ competencias.ver }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <br>
            <div class="row">
                <div class="ccol-lg-12">
                    <div class="row">
                        <div class="col-lg-4">

                            <h4 for="textarea-default">Outras Competências</h4>
                        </div>
                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-lg-12">

                                    <b-form-textarea id="textarea-default" v-model="competencias.outras" placeholder="Outras Competências">
                                    </b-form-textarea>
                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br>


            <fieldset class="fieldset">
                <div class="row">
                    <div class="btn-group" role="group">


                        <div class="container-buttons-salvar" v-show="botaoAlterar">
                            <b-button @click="adicionarCompetencia" variant="outline-success" id="btn-salvar">Salvar
                            </b-button>
                        </div>
                        <div class="container-buttons-salvar" v-show="!botaoAlterar">
                            <b-button @click="alterarCompetencia" variant="outline-success" id="btn-salvar">Alterar
                            </b-button>
                        </div>

                       
                        &nbsp;
                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>


    </div>

</template>

<script>

import { competenciaService } from "@/service/competenciaService";

export default {
    data() {
        return {
            competencias: {
                agir: 0,
                decidir: 0,
                mobilizar: 0,
                comunicar: 0,
                aprender: 0,
                comprometer: 0,
                assumir: 0,
                ver: 0,
                outras: "",
                usuario:"",
                idLogado:""
            },

            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,

            alert: {
                mensagem: "",
                variant: ""
            },

            botaoAlterar: true,
            
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        converterInteger() {
            this.competencias.agir = parseInt(this.competencias.agir);
            this.competencias.decidir = parseInt(this.competencias.decidir);
            this.competencias.mobilizar = parseInt(this.competencias.mobilizar);
            this.competencias.comunicar = parseInt(this.competencias.comunicar);
            this.competencias.aprender = parseInt(this.competencias.aprender);
            this.competencias.comprometer = parseInt(this.competencias.comprometer);
            this.competencias.assumir = parseInt(this.competencias.assumir);
            this.competencias.ver = parseInt(this.competencias.ver);
        },
        async adicionarCompetencia(){
            this.alert = {};
            this.competencias.idLogado = this.usuarioLogado.id
            this.competencias.usuario = this.usuarioPagina.id;
            await this.converterInteger();
            console.log("Competencia", this.competencias)
            
                     competenciaService
                        .salvarCompetencias(this.competencias)
                        .then((res) => {
                            if(res.sucesso) {
                                this.alert.mensagem = res.mensagem;
                                 this.alert.variant = "success";
                                 this.showAlert()                         

                            } else {
                                this.alert.mensagem = res.mensagem;
                             this.alert.variant = "danger";
                             this.showAlert()
                            }

                        })
                        .catch((erro) => {
                            console.log(erro)
                            this.alert.mensagem = "Usuário já tem competências cadastrada";
                             this.alert.variant = "danger";
                             this.showAlert()

                        });            
        },
        alterarCompetencia() {        
            this.alert = {}; 
          
                 this.competencias.idLogado = this.usuarioLogado.id
                 this.competencias.usuario = this.usuarioPagina.id;
                 this.converterInteger();
                 competenciaService
                    .alterarCompetencia(this.competencias.id, this.competencias)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                       
                        
                    })
                    .catch(() => {
                    });
            
        },
        
        async inicializar() {
            await this.verificaLocalStore();
            this.listarCompetencias();
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        editarCurso() {
            this.CursoClonado = {};
            this.idCursoClonado = "";
            document.getElementById("btn-salvarCurso").textContent = "Alterar"
            this.alerta = {};

        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        transformaSeNull () {
            if (!this.competencias.agir) this.competencias.agir = 0;
            if (!this.competencias.decidir) this.competencias.decidir = 0;
            if (!this.competencias.mobilizar) this.competencias.mobilizar = 0;
            if (!this.competencias.comunicar) this.competencias.comunicar = 0;
            if (!this.competencias.aprender) this.competencias.aprender = 0;
            if (!this.competencias.comprometer) this.competencias.comprometer = 0;
            if (!this.competencias.assumir) this.competencias.assumir = 0;
            if (!this.competencias.ver) this.competencias.ver = 0;
            if (!this.competencias.outras) this.competencias.outras = "";
        },
        verificaBotaoAlterar () {
            this.botaoAlterar = false;
            if (this.competencias.agir == null) this.botaoAlterar = true;
            if (this.competencias.decidir == null) this.botaoAlterar = true;
            if (this.competencias.mobilizar == null) this.botaoAlterar = true;
            if (this.competencias.comunicar == null) this.botaoAlterar = true;
            if (this.competencias.aprender == null) this.botaoAlterar = true;
            if (this.competencias.comprometer == null) this.botaoAlterar = true;
            if (this.competencias.assumir == null) this.botaoAlterar = true;
            if (this.competencias.ver == null) this.botaoAlterar = true;
        },
        listarCompetencias() {
            let usuario = this.usuarioPagina.id;

            competenciaService
                .listarCompetencias(usuario).then((res) => {
                    this.competencias = res;
                    this.verificaBotaoAlterar();
                    this.transformaSeNull();
                    console.log("competencias é", res)
                }).catch((error) => {
                   console.log(error) });
        },

    },
}

</script>