<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo" v-if="!$route.params.parametro"> Curso </h2>
            <h2 class="card__titulo" v-else> Curso</h2>
           
            <div class="row">

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Título<strong style="color:red">*</strong></label>
                                <b-form-input id="input-live" :state="stateValida.titulo" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe o Título do curso" trim v-model="curso.titulo">
                                </b-form-input>
                                <div class="invalid-feedback">Campo Título é obrigatório!</div>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Url Tese</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe a Url" trim v-model="curso.urlTese">
                                </b-form-input>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Área de formação<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.areaFormacao" v-on:change="validaCampo"
                                    v-model="curso.areaFormacao" placeholder="Informe a área de formação">
                                    <option value=""></option>
                                    <option v-for="areaFormacao in itemAreaFormacao" :key="areaFormacao.id"
                                        :value="areaFormacao.id">{{
                                                areaFormacao.nome
                                        }}</option>
                                </select>
                                <div class="invalid-feedback">Campo Área de formação é obrigatório!</div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Carga horária<strong style="color:red">*</strong></label>
                                <b-form-input id="input-live" type="text" :state="stateValida.cargaHoraria"
                                    v-on:change="validaCampo" v-model="curso.cargaHoraria"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe Carga horária" trim></b-form-input>
                                <div class="invalid-feedback">Campo Carga horária é obrigatório!</div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe o Processo" trim v-model="curso.processoSEI" SIZE="30" MAXLENGTH = "30">
                                </b-form-input>
                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Url do Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe a url" trim v-model="curso.urlSEI"></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">UF<strong style="color:red">*</strong></label>
                                <select class="form-control mb-3 input-select" id="uf" :state="stateValida.uf"
                                    v-on:change="validaCampo" v-model="curso.uf">
                                    <option value=""></option>
                                    <option v-for="uf in UF" :key="uf.nome" :value="uf.id">{{
                                            uf.nome
                                    }}</option>
                                </select>
                            </div>
                            <div class="invalid-feedback">Campo UF é obrigatório!</div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Tipo<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="input-live" :state="stateValida.tipo"
                                    v-on:change="validaCampo" v-model="curso.tipo" placeholder="Informe o tipo">
                                    <option value=""></option>
                                    <option v-for="tipo in itensTipo" :key="tipo.id" :value="tipo.id">{{
                                            tipo.nome
                                    }}</option>
                                </select>
                                <div class="invalid-feedback">Campo Tipo é obrigatório!</div>
                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Status<strong style="color:red">*</strong></label>
                                <select class="form-control mb-3 input-select" id="input-live"
                                    :state="stateValida.estatus" v-on:change="validaCampo" v-model="curso.estatus"
                                    placeholder="Informe o status">
                                    <option value=""></option>
                                    <option v-for="status in itemStatusCurso" :key="status.id" :value="status.id">{{
                                            status.nome
                                    }}</option>
                                </select>
                                <div class="invalid-feedback">Campo status é obrigatório!</div>
                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Ano conclusão<strong style="color:red">*</strong></label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe o ano da conclusão do curso" trim v-model="curso.anoConclusao"
                                    :state="stateValida.anoConclusao" v-on:change="validaCampo" SIZE="4" MAXLENGTH = "4"></b-form-input>
                                <div class="invalid-feedback">Campo Ano conclusão é obrigatório!</div>
                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Instituição<strong style="color:red">*</strong></label>
                                <select class="form-control mb-3 input-select" id="uf" :state="stateValida.instituicao"
                                    v-on:change="validaCampo" v-model="curso.instituicao">
                                    <option value=""></option>
                                    <option v-for="instituicao in itensInstituicao" :key="instituicao.id"
                                        :value="instituicao.id">{{
                                                instituicao.nome
                                        }}</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1">
                            
                            <div class="container-buttons-salvar">
                                <b-button id="btn-salvarCurso" @click="adicionarCurso" variant="outline-success">Salvar
                                </b-button>
                               
                            </div>
                        </div>
                        <div class="col-lg-1">

                            <div class="container-buttons-salvar">

                                <b-button variant="outline-success" @click="limparObjetoCurso()">Novo</b-button>

                            </div>
                        </div>
                        <div class="col-lg-1">
                            <div class="container-buttons-salvar">
                                <router-link to="/inicio">
                                    <b-button variant="outline-success">Voltar</b-button>
                                </router-link>
                            </div>

                        </div>
                    </div>


                </div>



            </div>
        </div>
        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Lista de Cursos</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th>Ano</th>
                                <th>Formação</th>
                                <th>Status</th>


                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr v-for="c in itemsCurso" :key="c.id">

                                <td>{{ c.titulo }}</td>
                                <td>{{ c.anoConclusao }}</td>
                                <td> {{ c.areaFormacao }}</td>
                                <td> {{ c.estatus }}</td>
                               
                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="curso = JSON.parse(JSON.stringify(c)), editarCurso()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { cursoService } from "@/service/cursoService";
import { constanteService } from "@/service/constanteService";

export default {
   /*  beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  }, */
    data() {
        return {
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            menssagem: "",
            stateValida: {
                titulo: null,
                anoConclusao: null,
                areaFormacao: null,
                cargaHoraria: null,
                tipo: null,
                uf: null,
                estatus: null,
                instituicao: null,
            },
            curso: {
                titulo: null,
                anoConclusao: null,
                processoSEI: "",
                urlSEI: "",
                urlTese: "",
                areaFormacao: "",
                cargaHoraria: null,
                uf: "DF",
                tipo: null,
                estatus: null,
                usuario: null,
                instituicao: null,
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            soClonado:{},
            idCursoClonado: "",
            itensInstituicao: [],
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            mensagem: "",
            itemsCurso: [],
            UF: [],
            itensTipo: [],
            itemStatusCurso: [],
            itemAreaFormacao: [],
            validado:"",
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            await this.verificaLocalStore()
            this.listarCurso();
            this.listarUF();
            this.listarInstituicao();
            this.listarTipo();
            this.listarStatusCurso();
            this.listarAreaFormacao();
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        validaCampo() {

            if (this.curso.titulo) {
                this.stateValida.titulo = true;
                this.validado =  true;

            }
            else if (!this.curso.titulo) {
                this.stateValida.titulo = false;
                this.validado =  false;
            }
            if (this.curso.anoConclusao) {
                this.stateValida.anoConclusao = true;
                this.validado =  true;

            }
            else if (!this.curso.anoConclusao) {
                this.stateValida.anoConclusao = false;
                this.validado =  false;
            }
            if (this.curso.areaFormacao) {
                this.stateValida.areaFormacao = true;
                this.validado =  true;

            }
            else if (!this.curso.areaFormacao) {
                this.stateValida.areaFormacao = false;
                this.validado =  false;
            }
            if (this.curso.cargaHoraria) {
                this.stateValida.cargaHoraria = true;
                this.validado =  true;

            }
            else if (!this.curso.cargaHoraria) {
                this.stateValida.cargaHoraria = false;
                this.validado =  false;
            }
            if (this.curso.uf) {
                this.stateValida.uf = true;
                this.validado =  true;

            }
            else if (!this.curso.uf) {
                this.stateValida.uf = false;
                this.validado =  false;
            }
            if (this.curso.tipo) {
                this.stateValida.tipo = true;
                this.validado =  true;

            }
            else if (!this.curso.tipo) {
                this.stateValida.tipo = false;
                this.validado =  false;
            }
            if (this.curso.estatus) {
                this.stateValida.estatus = true;
                this.validado =  true;

            }
            else if (!this.curso.estatus) {
                this.stateValida.estatus = false;
                this.validado =  false;
            }
            return  this.validado;


        },
        editarCurso() {
            this.CursoClonado = {};
            this.idCursoClonado = "";
            document.getElementById("btn-salvarCurso").textContent = "Alterar"
            this.alerta = {};

        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        
        adicionarCurso() {
            this.alert = {};

            if (document.getElementById("btn-salvarCurso").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarCurso();
                }, 200)
            } else {

                if (this.validaCampo() == true) {
                    this.curso.usuario = this.usuarioPagina.id;
                    this.curso.idLogado = this.usuarioLogado.id
                    console.log("qual é o usuário", this.usuarioPagina.id)
                    cursoService
                        .salvarCurso(this.curso)
                        .then((res) => {
                           

                            this.CursoClonado = JSON.parse(JSON.stringify(this.curso));
                            console.log("novo curso clonado", this.CursoClonado )
                           
                            this.listarCurso();
                           
                          this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                            document.getElementById("btn-salvarCurso").textContent = "Alterar";
                        })
                        .catch(() => {

                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarCurso() {
            this.alert = {};
            this.curso.usuario = this.usuarioPagina.id;

          
            for (let index = 0; index < this.itemsCurso.length; index++) {

            if(this.CursoClonado.titulo === this.itemsCurso[index].titulo){
                this.idCursoClonado = this.itemsCurso[index].id
            console.log(this.idCursoClonado)
                break;
            }
               
                
          }

        
        if(this.idCursoClonado){ 
            this.curso.id = this.idCursoClonado;
            
        } 

            this.curso.idLogado = this.usuarioLogado.id
            cursoService
                .alterarCurso(this.curso.id, this.curso)
                .then((res) => {
                    // this.limparObjetoCurso();

                 

                    this.listarCurso();
                  this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                })
                .catch(() => {
                    console.log("deu erro no serviço")
                    console.log("data inicio das ferias 2", this.curso)
                    
                });
        },
        listarCurso() {
            let usuario = this.usuarioPagina.id;

            cursoService
                .listarCurso(usuario).then((res) => {
                    this.itemsCurso = res;
                    console.log("cursos é", this.itemsCurso)
                });
        },
        limparObjetoCurso() {
            this.CursoClonado= {};
            this.idCursoClonado= "";
            setTimeout(() => {
                document.getElementById("btn-salvarCurso").textContent = "Salvar"

            }, 200)

            this.curso = {};
            this.alerta = {};
        },
        listarUF() {
            cursoService
                .listarUF().then((res) => {
                    this.UF = res;
                });
        },
        listarTipo() {
            constanteService
                .listarTipoCurso().then((res) => {
                    this.itensTipo = res;
                });
        },
        listarStatusCurso() {
            constanteService
                .listarStatusCurso().then((res) => {
                    this.itemStatusCurso = res;
                });

        },
        listarAreaFormacao() {
            constanteService
                .listarAreaFormacao().then((res) => {
                    this.itemAreaFormacao = res;
                });

        },
        listarInstituicao() {
            cursoService
                .listarInstituicao().then((res) => {
                    this.itensInstituicao = res;
                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

    },
}

</script>