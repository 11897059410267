import axios from "axios";

export const publicacaoService = {
  salvarPublicacao(publicacao) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/publicacao`,
          publicacao,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarPublicacao(id, publicacao) {
    return new Promise((resolve, reject) => {
     

      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/publicacao/${id}`,
          publicacao,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarPublicacao(usuario) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/publicacao/listar/${usuario}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarUF() {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/constante/uf`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarInstituicao() {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/instituicao`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
