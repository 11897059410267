<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Dons Artísticos</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3" role="group">

                            <div role="group">
                                <label for="input-live">Dons Artístícos<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="refOrigemDrop"
                                    :state="stateValida.domArtistico" v-model="donsArtisticos.domArtistico"
                                    v-on:change="limparCampos(), validaCampo, listarDonsArtisticos(), habilitarCampo()">
                                    <option value="0"></option>
                                    <option v-for="dom in tipoDonsArtisticos" :key="dom.id" :value="dom.id">{{
                                    dom.nome
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo dons artísticos é obrigatório!</div>

                        </div>

                      
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Público Alvo<strong style="color:red">*</strong></label>
                            <b-form-input id="publicoAlvo" :state="stateValida.publicoAlvo" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o publico alvo" v-model="donsArtisticos.publicoAlvo" SIZE="20"
                                MAXLENGTH="20" disabled></b-form-input>
                            <div class="invalid-feedback">Campo público alvo é obrigatório!</div>
                        </div>
                        <div class="col-lg-12" role="group">
                            <label for="input-live">Habilidades Praticas<strong style="color:red">*</strong></label>
                            <b-form-input id="habilidadesPraticas" :state="stateValida.habilidadesPraticas"
                                v-on:change="validaCampo" type="text"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Habilidade Pratica"
                                v-model="donsArtisticos.habilidadesPraticas" SIZE="60" MAXLENGTH="60" disabled>
                            </b-form-input>
                            <div class="invalid-feedback">Campo hablidades práticas é obrigatório!</div>
                        </div>

                    </div>
                    <fieldset class="fieldset ">
                        <div class="row">
                            <div class="btn-group" role="group">
                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarDonsArtisticos" variant="outline-success"
                                        id="btn-salvar">Salvar
                                    </b-button>
                                </div>

                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar"
                                        @click="limparObjetoDonsArtisticos()">
                                        Novo
                                    </b-button>

                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                    </fieldset>



                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listar Dons Artísticos</h2>



                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Dom Artisticos</th>
                                <th>Habilidade Práticas</th>
                                <th>Público Alvo</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>

                            <!--     <td><router-link to="/ferias">{{ ferias.exercicio }}</router-link></td> -->
                            <tr v-for="dado in itensDonsArtisticos" :key="dado.id">

                                <td>{{ dado.domArtistico }}</td>
                                <td>{{ dado.habilidadesPraticas }}</td>
                                <td>{{ dado.publicoAlvo }}</td>



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="donsArtisticos = JSON.parse(JSON.stringify(dado)), editarDonsArtisticos()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { donsArtisticosService } from "@/service/donsArtisticosService";
import { constanteService } from "@/service/constanteService";

export default {
    data() {
        return {
            infoCargo: {},
            infoCarreira: {},
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            cargoErro: false,
            stateValida: {
                domArtistico: null,
                habilidadesPraticas: null,
                publicoAlvo: "",

            },
            listaDonsArtisticosAuxiliar: [],

            donsArtisticos: {
                domArtistico: "",
                habilidadesPraticas: "",
                publicoAlvo: "",
                usuario: "",
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            domArtisticosClonado: {},
            idDomArtisticosClonado: "",

            tipoDomArtisticos: [],
            itensDonsArtisticos: [],
            tipoDonsArtisticos: [],
            validado: "",

        }
    },
    mounted() {
        this.inicialiazar();

    },
    methods: {
        limparCampos(){
            this.donsArtisticos.habilidadesPraticas = "";
            this.donsArtisticos.publicoAlvo = "";
        },
        async inicialiazar() {
            await this.verificaLocalStore();

            //this.listarCarreira()
            await this.listarDonsArtisticos();
            await this.listarTipoDonsArtisticos();
            this.alerta = {};

        },
        listarDonsArtisticos() {
            let usuario = this.usuarioPagina.id;
            donsArtisticosService
                .listarDonsArtisticos(usuario).then((res) => {
                    this.itensDonsArtisticos = res;
                    console.log("usuário do dom", res)


                });
        },
        listarTipoDonsArtisticos() {
            constanteService
                .listarTipoDonsArtisticos().then((res) => {
                    this.tipoDonsArtisticos = res;


                });
        },
        limparObjetoDonsArtisticos() {
            document.getElementById('habilidadesPraticas').disabled = true;
            document.getElementById('publicoAlvo').disabled = true;
            document.getElementById("refOrigemDrop").value = "";
            this.alerta = {};

            this.donsArtisticosClonado = {};
            this.idDonsArtisticosClonado = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
            }, 200)
            this.donsArtisticos = {};
        },
        alterarDonsArtisticos() {
            this.alert = {};
            this.donsArtisticosClonado = JSON.parse(JSON.stringify(this.donsArtisticos));

            for (let index = 0; index < this.itensDonsArtisticos.length; index++) {

                if (this.donsArtisticosClonado.habilidadesPraticas === this.itensDonsArtisticos[index].habilidadesPraticas) {
                    this.idDonsArtisticosClonado = this.itensDonsArtisticos[index].id

                    break;
                }


            }


            if (this.idDonsArtisticosClonado) {
                this.donsArtisticos.id = this.idDonsArtisticosClonado;

            }


            if (this.validaCampo() == true) {
                this.donsArtisticos.idLogado = this.usuarioLogado.id
                this.donsArtisticos.usuario = this.usuarioPagina.id;
                donsArtisticosService
                    .alterarDonsArtisticos(this.donsArtisticos.id, this.donsArtisticos)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()

                        setTimeout(()=>{
                            this.listarDonsArtisticos();
                        },200)
                    })
                    .catch((error) => {
                        this.alert.mensagem = error.response.data.mensagem;
                            this.alert.variant = "danger";
                            this.showAlert()
                    });
            } else {
                this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
                this.alert.variant = "danger";
                this.showAlert();
            }
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        editarDonsArtisticos() {
            this.alerta = {};
            /* this.donsArtisticosClonado = {};
            this.idDonsArtisticosClonado = ""; */
            document.getElementById("btn-salvar").textContent = "Alterar"
            document.getElementById('habilidadesPraticas').disabled = false;
            document.getElementById('publicoAlvo').disabled = false;


        },

        validaCampo() {

            if (this.donsArtisticos.domArtistico) {
                this.stateValida.domArtistico = true;
                this.validado = true;

            }
            else if (!this.donsArtisticos.domArtistico) {
                this.stateValida.domArtistico = false;
                this.validado = false;
            }
            if (this.donsArtisticos.habilidadesPraticas) {
                this.stateValida.habilidadesPraticas = true;
                this.validado = true;

            }
            else if (!this.donsArtisticos.habilidadesPraticas) {
                this.stateValida.habilidadesPraticas = false;
                this.validado = false;
            }
            if (this.donsArtisticos.publicoAlvo) {
                this.stateValida.publicoAlvo = true;
                this.validado = true;

            }
            else if (!this.donsArtisticos.publicoAlvo) {
                this.stateValida.publicoAlvo = false;
                this.validado = false;
            }

            return this.validado
        },

        adicionarDonsArtisticos() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarDonsArtisticos();
            } else {
                if (this.validaCampo() == true) {
                    this.donsArtisticos.idLogado = this.usuarioLogado.id
                    this.donsArtisticos.usuario = this.usuarioPagina.id;


                    donsArtisticosService
                        .salvarDonsArtisticos(this.donsArtisticos)
                        .then((res) => {


                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            this.donsArtisticosClonado = JSON.parse(JSON.stringify(this.donsArtisticos));
                            document.getElementById("btn-salvar").textContent = "Alterar"
                            this.listarDonsArtisticos();

                        })
                        .catch((error) => {
                            this.alert.mensagem = error.response.data.mensagem;
                             this.alert.variant = "danger";
                             this.showAlert()

                        });
                } else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }

        },

        habilitarCampo() {
            let comboOrigem = document.getElementById("refOrigemDrop").value;
            if (this.donsArtisticos.domArtistico && comboOrigem) {
                document.getElementById('habilidadesPraticas').disabled = false;
                document.getElementById('publicoAlvo').disabled = false;

                document.getElementById("btn-salvar").textContent = "Salvar"

            } else {
                /*  this.cargo.nome = "";
                 this.cargo.sigla = "";
                 this.itemsCargo = {}; */
                document.getElementById('habilidadesPraticas').disabled = true;
                document.getElementById('publicoAlvo').disabled = true;
            }

        },

    },


}

</script>
<style scoped>
/* .borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 5%;

} 
.fieldset
{
  
  max-width:250px;
  padding:8px;	
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 5%;
  float: right; 
  margin-right: 10px;

}
.legend1
{
  max-width:250px;
  padding:8px;	 
  float: right; 
  margin-right: 10px;
} */
</style>
