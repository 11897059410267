<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Regional Saúde</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
               
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>

                            <b-form-input id="input-live" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Regional saúde" trim v-model="regionalSaude.nome" SIZE="60"
                                MAXLENGTH="60"></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="input-live" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla da Regional saúde" trim v-model="regionalSaude.sigla" SIZE="20"
                                MAXLENGTH="20"></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>
                       
                    </div>

                </div>
                <div class="legend1 ">

                    <fieldset class="fieldset ">

                        <div class="row">
                            <div class="btn-group" role="group">

                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarRegionalSaude" variant="outline-success" id="btn-salvar">
                                        Salvar
                                    </b-button>
                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoRegionalSaude()">
                                        Novo</b-button>

                                </div>&nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas das Regionais Saúde</h2>
                

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>

                            </tr>
                        </thead>
                        <tbody>
                          
                            <tr v-for="rs in itemsRegionalSaude" :key="rs.id">

                                <td>{{ rs.nome }}</td>
                                <td>{{ rs.sigla }}</td>

                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="regionalSaude = JSON.parse(JSON.stringify(rs)), editarRegionalSaude(rs)">
                                           
                                            Editar
                                        </b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { regionalSaudeService } from "@/service/regionalSaudeService";


export default {
   
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                nome: null,
                sigla: null,
            },
            
            regionalSaude: {
                nome: "",
                sigla: "",                
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },

            regionalSaudeClonada: {},

            tipoDiretoria: [],
            infoRegionalSaude: {},
            validado:"",

            mensagem: "",
            stateNome: null,
            stateSigla: null,
            fields: ['nome', 'sigla'],



            itemsRegionalSaude: [],
            idRegionalSaudeClonado: "",

        }
    },
    props: {
        parametros: Object,
    },
    mounted() {
        this.inicializar();


    },
    methods:{   
        
        async inicializar() {
            await this.verificaLocalStore();
            //this.buscarValorParametro();
            this.listarRegionalSaude();
            this.alerta = {};
        },

        limparObjetoRegionalSaude() {
            this.alerta = {};
            this.idRegionalSaudeClonado = "";

            this.regionalSaudeClonada.nome = "";
            this.regionalSaudeClonada.sigla = "";            
            this.regionalSaudeClonada.idLogado = "";
            this.regionalSaudeClonada.id = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"

            }, 200)

            this.regionalSaude = {};

        },
        async buscarValorParametro() {           

            this.listarRegionalSaude()

        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        async buscaRegionsalSaude(idRegionsalSaude) {

            await regionalSaudeService
                .pegaRegionalSaude(idRegionsalSaude)
                .then((res) => {

                    this.infoRegionalSaude = res;
                    console.log("pegou regional legal", this.infoRegionalSaude)


                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        validaCampo() {

            if (this.regionalSaude.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.regionalSaude.nome) {
                this.stateValida.nome = false;
                this.validado = false;
            }
            if (this.regionalSaude.sigla) {
                this.stateValida.sigla = true;
                this.validado = true;

            }
            else if (!this.regionalSaude.sigla) {
                this.stateValida.sigla = false;
                this.validado = false;
            }
            return this.validado;



        },
        editarRegionalSaude() {
            this.alerta = {};
            document.getElementById("btn-salvar").textContent = "Alterar"
            this.idRegionalSaudeClonado = "";

            this.regionalSaudeClonada.nome = "";
            this.regionalSaudeClonada.sigla = "";            
            this.regionalSaudeClonada.idLogado = "";
            this.regionalSaudeClonada.id = "";




        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        addRegionalSaude() {
            let itemAbono = {};
            itemAbono.Período = ` ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataInicio)))} a ${this.formatarObjetoData(this.parseDataBr(this.toDDMMYYYY(this.abono.dataFim)))}`;
            setTimeout(() => {
                this.items.push(itemAbono)
                localStorage.setItem("abono", JSON.stringify(this.items));
            }, 900)

        },
        adicionarRegionalSaude() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarRegionalSaude();
                }, 200)
            } else {
                if (this.validaCampo() == true) {
                    
                    this.regionalSaude.idLogado = this.usuarioLogado.id
                    this.idRegionalSaudeClonado = "";

                    this.regionalSaudeClonada.nome = "";
                    this.regionalSaudeClonada.sigla = "";                    
                    this.regionalSaudeClonada.idLogado = "";
                    this.regionalSaudeClonada.id = "";

                    regionalSaudeService
                        .salvarRegionalSaude(this.regionalSaude)
                        .then((res) => {

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                            document.getElementById("btn-salvar").textContent = "Alterar"



                            setTimeout(() => {
                                this.alerta.msnSuccess = "";
                                this.regionalSaudeClonada = JSON.parse(JSON.stringify(this.regionalSaude));

                            }, 3000)


                            this.showAlert();
                            document.getElementById("btn-salvar").textContent = "Alterar"


                        })
                        .catch(() => {

                        }).
                        finally(() => {
                            this.listarRegionalSaude();
                            
                        })
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarRegionalSaude() {
            this.alert = {};

            for (let index = 0; index < this.itemsRegionalSaude.length; index++) {

                if (this.regionalSaudeClonada.nome === this.itemsRegionalSaude[index].nome) {
                    this.idRegionalSaudeClonado = this.itemsRegionalSaude[index].id
                    console.log(this.idRegionalSaudeClonado)
                    break;
                }


            }


            if (this.idRegionalSaudeClonado) {
                this.regionalSaude.id = this.idRegionalSaudeClonado;
                this.regionalSaude.usuario = this.usuarioPagina.id;

            }

            if (this.validaCampo() == true) {
                
                this.regionalSaude.idLogado = this.usuarioLogado.id


                regionalSaudeService
                    .alterarRegionalSaude(this.regionalSaude.id, this.regionalSaude)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                        this.listarRegionalSaude();
                    })
                    .catch(() => {

                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        listarRegionalSaude() {
           

            regionalSaudeService
                .listarRegionalSaude().then((res) => {
                    this.itemsRegionalSaude = res;
                    console.log("regional é", this.itemsRegionalSaude)
                    
                    console.log("regional clonada é", this.regionalSaudeClonada)
                });
        },
    },


}

</script>
<style scoped>

</style>