<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Pré-Cadastro </h2>
            <div class="row">
                <b-alert :show="dismissCountDown" variant="warning" @dismissed="dismissCountDown = 0">
                   
                    <p>{{ alert.mensagem }}</p>
                    <b-progress variant="warning" :max="dismissSecs" :value="dismissCountDown" height="4px">
                    </b-progress>
                </b-alert>
                <div class="row">

                    <div class="col-lg-3">
                        <div role="group">
                            <label for="input-live">Situação:</label>

                            <select class="form-control mb-3 input-select" id="refSituacaoDrop"
                                v-on:change="listarPreCadastro(false)">
                                <option value=""></option>
                                <option v-for="situacao in itensSituacao" :key="situacao.id" :value="situacao.id">{{
                                        situacao.nome
                                }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div role="group">
                            <label for="input-live">Gerência:</label>
                            <select class="form-control mb-3 input-select" id="refGerenciaDrop"
                                v-on:change="listarNucleo(), listarPreCadastro(false)">

                                <option value="0"></option>
                                <option v-for="gerencia in listaGerencia" :key="gerencia.id" :value="gerencia.id">{{
                                        gerencia.sigla
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div role="group">
                            <label for="input-live">Núcleo:</label>
                            <select class="form-control mb-3 input-select" id="refNucleoDrop"
                                v-on:change="listarSetor(), listarPreCadastro(false)">
                                <option value="0"></option>
                                <option v-for="nucleo in listaNucleo" :key="nucleo.id" :value="nucleo.id">{{
                                        nucleo.sigla
                                }}</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="row">

                            <div class="col-lg-4">
                                <div role="group">
                                    <label for="input-live">Área Técnica:</label>
                                    <select class="form-control mb-3 input-select" id="refSetorDrop"
                                        v-on:change="listarPreCadastro(false)" v-model="cadastro.setor">
                                        <option value="0"></option>
                                        <option v-for="setor in listaSetor" :key="setor.id" :value="setor.id">{{
                                                setor.sigla
                                        }}</option>
                                    </select>
                                </div>
                            </div>



                            <div class="col-lg-3">
                                <div role="group">
                                    <label for="input-live">Nome Servidor:</label>
                                    <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                        :state="stateValida.nomeSocial" v-on:change="validaCampo"
                                        v-model="cadastro.nomeSocial"></b-form-input>
                                </div>
                            </div>
                            <div class="invalid-feedback">Campo dons artísticos é obrigatório!</div>
                            <div class="col-lg-3">
                                <div role="group">
                                    <label id="cpf" for="input-live">CPF:</label>
                                    <b-form-input v-mask="'###.###.###-##'" id="input-live" v-model="cadastro.cpf"
                                        :state="stateValida.cpf" v-on:change="validaCampo">
                                    </b-form-input>
                                </div>
                            </div>
                            <div class="invalid-feedback">Campo dons artísticos é obrigatório!</div>
                            <div class="col-lg-3">
                                <div role="group">
                                    <label for="input-live">Matricula:</label>
                                    <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                        placeholder="Matricula" v-model="cadastro.matricula"
                                        :state="stateValida.matricula" v-on:change="validaCampo">
                                    </b-form-input>
                                </div>
                            </div>
                            <div class="invalid-feedback">Campo dons artísticos é obrigatório!</div>
                            <div class="col-lg-3">
                                <div role="group">
                                    <label for="input-live">Email Pessoal:</label>
                                    <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                        placeholder="E-mail" v-model="cadastro.emailPessoal"
                                        :state="stateValida.emailPessoal" v-on:change="validaCampo">
                                    </b-form-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div role="group">
                                    <label for="input-live">Origem:</label>
                                    <select class="form-control mb-3 input-select" id="refNucleoDrop" disabled>
                                        <option value="0"></option>
                                        <option v-for="or in listaItensOrigem" :key="or.id" :value="or.id">{{
                                                or.nome
                                        }}</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                
                            </div>
                        </div>



                    </div>

                </div>

                <div class="row">
                    <div class="btn-group" role="group">


                        <div class="container-buttons-salvar">
                            <b-button variant="outline-success" id="btn-salvar" @click="adicionarPreCadastro">Salvar
                            </b-button>
                        </div>
                        &nbsp;
                        <div class="container-buttons-salvar">

                            <b-button variant="outline-success" id="btn-novo" @click="limparObjetoCadastro">Novo
                            </b-button>

                        </div>
                        &nbsp;
                        <div class="container-buttons-salvar">
                            <b-button variant="outline-success" id="btn-salvar">Efetivar</b-button>
                        </div>
                        &nbsp;
                        <div class="container-buttons-salvar">

                            <b-button variant="outline-success" id="btn-rejeitar">Rejeitar</b-button>

                        </div>
                        &nbsp;

                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas de Pré-Cadastro</h2>

                  

                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Matrícula</th>
                                <th>CPF</th>
                                <th>Nome</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>
                           

                            <tr v-for="pre in itensPreCadastro" :key="pre.id">
                                <td>{{ pre.matricula }}</td>
                                <td>{{ pre.cpf }}</td>
                                <td>{{ pre.nomeSocial }}</td>

                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="cadastro = JSON.parse(JSON.stringify(pre)), editarPreAprovacao()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>


</template>

<script>

import { preCadastroService } from "@/service/preCadastroService";
import { aprovacaoCadastroService } from "@/service/aprovacaoCadastroService";


import { gerenciaService } from "@/service/gerenciaService";
import { nucleoService } from "@/service/nucleoService";
import { setorService } from "@/service/setorService";
import { origemService } from "@/service/origemService";

export default {
    data() {
        return {
            usuarioPagina: {},
            usuarioLogado: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            instituicaoEnsino: {
                nome: null,
                sigla: null,
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            validado: "",

            cadastro: {
                matricula: "",
                nomeSocial: "",
                emailPessoal: "",
                cpf: "",
                setor: "",
                /*situacao:"", */
                origem: "INTERNO"
            },
            stateValida: {
                matricula: null,
                nomeSocial: null,
                emailPessoal: null,
                cpf: null,
            },

            listaInstituicaoEnsino: [],


            itensSituacao: {},
            itensPreCadastro: {},
            itemsInstituicaoEnsino: [],
            listaItensOrigem: [],

            listaGerencia: {},
            listaNucleo: {},
            listaSetor: {},

        }
    },
    mounted() {
        this.inicializar();

    },
    methods: {
        validaCampo() {

            if (this.cadastro.matricula) {
                this.stateValida.matricula = true;
                this.validado = true;

            }
            else if (!this.cadastro.matricula) {
                this.stateValida.matricula = false;
                this.validado = false;
            }
            if (this.cadastro.nomeSocial) {
                this.stateValida.nomeSocial = true;
                this.validado = true;

            }
            else if (!this.cadastro.nomeSocial) {
                this.stateValida.nomeSocial = false;
                this.validado = false;
            }
            if (this.cadastro.emailPessoal) {
                this.stateValida.emailPessoal = true;
                this.validado = true;

            }
            else if (!this.cadastro.emailPessoal) {
                this.stateValida.emailPessoal = false;
                this.validado = false;
            }
            if (this.cadastro.cpf) {
                this.stateValida.cpf = true;
                this.validado = true;

            }
            else if (!this.cadastro.cpf) {
                this.stateValida.cpf = false;
                this.validado = false;
            }


            return this.validado;

        },
        editarPreAprovacao(){
            document.getElementById("btn-salvar").textContent = "Alterar"
        },
        RetiraMascara() {
            let cpf = this.cadastro.cpf;
            if (cpf.length == 14) {
                cpf = cpf.charAt(0) + cpf.charAt(1) + cpf.charAt(2) +
                    cpf.charAt(4) + cpf.charAt(5) + cpf.charAt(6) +
                    cpf.charAt(8) + cpf.charAt(9) + cpf.charAt(10) +
                    cpf.charAt(12) + cpf.charAt(13);

                this.cadastro.cpf = cpf;

                this.validaPreCadastro();
            } else {
                this.showAlert();
            }
        },
        validaPreCadastro() {
            if (document.getElementById("refSituacaoDrop").value == "0") {
                this.alert.mensagem = "Campo situação é de preenchimento obrigatório";
                this.alert.variant = "danger";
                this.showAlert()

            } else {
                this.adicionarPreCadastro();
            }

        },
        adicionarPreCadastro() {

            this.alert = {};
            // document.getElementById("refGerenciaDrop").disabled = true;        

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarPreCadastro();
            } else {
                if (this.validaCampo() == true) {
                    this.cadastro.idLogado = this.usuarioLogado.id

                    aprovacaoCadastroService
                        .salvarAprovacaoCadastro(this.cadastro)
                        .then((res) => {

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            this.listarPreCadastro(false);


                            document.getElementById("btn-salvar").textContent = "Alterar"

                        })
                        .catch(error => {
                            console.log(error)
                            this.alert.mensagem = error.response //error.response.data.mensagem;
                            this.alert.variant = "danger";
                            this.showAlert()

                        });
                } else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }


        },
        alterarPreCadastro() {
            this.alert = {};


            if (this.validaCampo() == true) {
                this.cadastro.idLogado = this.usuarioLogado.id

                aprovacaoCadastroService
                    .alterarAprovacaoCadastro( this.cadastro.idLogado, this.cadastro)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()

                        this.listarPreCadastro(false);


                        document.getElementById("btn-salvar").textContent = "Alterar"

                    })
                    .catch(error => {
                        console.log(error)
                        this.alert.mensagem = error.response //error.response.data.mensagem;
                        this.alert.variant = "danger";
                        this.showAlert()

                    });
            } else {
                this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
                this.alert.variant = "danger";
                this.showAlert();
            }        

    },
    async listarGerencia() {
        var idDiretoria = "1"
        await gerenciaService
            .listarGerencia(idDiretoria).then((res) => {
                this.listaGerencia = res;
                this.listarNucleo();
            });
    },
    async listarNucleo() {
        let idGerencia = document.getElementById("refGerenciaDrop").value;
        await nucleoService
            .listarNucleo(idGerencia).then((res) => {
                this.listaNucleo = res;

            }).catch(erro => {
                console.log(erro);
                if (erro.response && erro.response.status == 404) {
                    this.listaNucleo = []
                }
            });
    },
    showAlert() {
        this.dismissCountDown = this.dismissSecs
    },
    listarOrigem() {
        origemService
            .listarOrigem()
            .then((res) => {
                this.listaItensOrigem = res;
            })
            .catch(erro => {
                console.log(erro)
            })
    },

    async listarSetor() {
        let idDiretoria = "1";
        let idGerencia = document.getElementById("refGerenciaDrop").value;
        let idNucleo = document.getElementById("refNucleoDrop").value;



        await setorService
            .listarSetor(idDiretoria, idGerencia, idNucleo).then((res) => {
                this.listaSetor = res;
                console.log("setores", this.listaSetor)
            }).catch(erro => {
                console.log(erro);
                if (erro.response && erro.response.status == 404) {
                    this.listaSetor = []
                }
            });
    },


    listarSituacaoCadastro() {
        aprovacaoCadastroService
            .listarSituacaoCadastro().then((res) => {
                this.itensSituacao = res;
                console.log("situacao atual é", this.itensSituacao)


            });
    },
    listarPreCadastro(primeiraVez) {
        let gerencia;
        let nucleo;
        let setor;
        let situacao;

        let diretoria = "1";
        let idLogado = this.usuarioLogado.id
        if (primeiraVez) {
            gerencia = 0;
            nucleo = 0;
            setor = 0;
            situacao = "PENDENTE";
        } else {
            gerencia = document.getElementById("refGerenciaDrop").value;
            nucleo = document.getElementById("refNucleoDrop").value;
            setor = document.getElementById("refSetorDrop").value;
            situacao = document.getElementById("refSituacaoDrop").value;
        }


        preCadastroService
            .listarPreCadastro(idLogado, diretoria, gerencia, nucleo, setor, situacao).then((res) => {
                this.itensPreCadastro = res;
                console.log("situacao atual é", this.itensPreCadastro)

            }).catch((error) => {
                console.log(error)
                if (error.response && error.response.status == 404) {
                    this.itensPreCadastro = {}
                }

            })
    },

    async inicializar() {
        await this.verificaLocalStore();
        await this.listarGerencia();
        await this.listarSituacaoCadastro();
        await this.listarSetor();
        this.listarOrigem();
        this.listarPreCadastro(true);




    },
    verificaLocalStore() {
        this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
        if (localStorage.getItem("usuarioPagina")) {
            this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

        } else {
            this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
        }
        // Carrega o vinculo
        if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
            this.vinculoSelecionado.indexVinculo = 0;
            this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
            this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
            localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
        } else {
            this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
        }
    },
    limparObjetoCadastro() {
        this.cadastro = {};
        document.getElementByid("refGerenciaDrop").value = "0";
        document.getEleentById("refNucleoDrop").value = "0";
    },

}
}

</script>
