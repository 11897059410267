<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Origem</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
                
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>

                            <b-form-input id="input-live" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Origem" trim v-model="origem.nome" SIZE="60"
                                MAXLENGTH="60"></b-form-input>
                            <div class="invalid-feedback">Campo nome origem é obrigatório!</div>
                        </div>
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="input-live" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla da origem" trim v-model="origem.sigla" SIZE="20"
                                MAXLENGTH="20"></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>

                       
                    </div>

                </div>
                <div class="legend1 ">

                    <fieldset class="fieldset ">

                        <div class="row">
                            <div class="btn-group" role="group">

                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarOrigem" variant="outline-success" id="btn-salvar">
                                        Salvar
                                    </b-button>
                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoOrigem()">
                                        Novo</b-button>

                                </div>&nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas das Origens</h2>

                  

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>

                            </tr>
                        </thead>
                        <tbody>
                       
                            <tr v-for="or in itemsOrigem" :key="or.id">

                                <td>{{ or.nome }}</td>
                                <td>{{ or.sigla }}</td>

                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="origem = JSON.parse(JSON.stringify(or)), editarOrigem(or)">
                                            
                                            Editar
                                        </b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { origemService } from "@/service/origemService";


export default {
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                nome: null,
                sigla: null,
            },
            
            origem: {
                nome: "",
                sigla: "",                
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },

           origemClonada: {},

            tipoDiretoria: [],
            infoOrigem: {},

            
            stateNome: null,
            stateSigla: null,
            



            itemsOrigem: [],
            idOrigemClonado: "",
            validado:"",

        }
    },
    props: {
        parametros: Object,
    },
    mounted() {
        this.inicializar();


    },
    methods: {

        
        async inicializar() {
            await this.verificaLocalStore();
           
            this.listarOrigem();
            this.alerta = {};
        },

        limparObjetoOrigem() {
            this.alerta = {};
            this.idOrigemClonado = "";

            this.origemClonada.nome = "";
            this.origemClonada.sigla = "";            
            this.origemClonada.idLogado = "";
            this.origemClonada.id = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"

            }, 200)

            this.origem = {};

        },
        async buscarValorParametro() {

            this.listarOrigem()


        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        async buscaOrigem(idOrigem) {

            await origemService
                .pegaGerencia(idOrigem)
                .then((res) => {

                    this.infoOrigem = res;
                  


                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        validaCampo() {

            if (this.origem.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.origem.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.origem.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;

            }
            else if (!this.origem.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
            return this.validado;



        },
        editarOrigem() {
            this.alerta = {};
            document.getElementById("btn-salvar").textContent = "Alterar"
            this.idOrigemClonado = "";

            this.origemClonada.nome = "";
            this.origemClonada.sigla = "";
            this.origemClonada.diretoria = "";
            this.origemClonada.idLogado = "";
            this.origemClonada.id = "";




        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        
        adicionarOrigem() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarOrigem();
                }, 200)
            } else {
                if (this.validaCampo() == true) {
                    
                    this.origem.idLogado = this.usuarioLogado.id
                    this.idOrigemClonado = "";

                    this.origemClonada.nome = "";
                    this.origemClonada.sigla = "";
                    
                    this.origemClonada.idLogado = "";
                    this.origemClonada.id = "";

                    origemService
                        .salvarOrigem(this.origem)
                        .then((res) => {

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()



                            setTimeout(() => {
                                this.alerta.msnSuccess = "";
                                this.origemClonada = JSON.parse(JSON.stringify(this.origem));

                            }, 3000)


                            this.showAlert();
                            document.getElementById("btn-salvar").textContent = "Alterar"


                        })
                        .catch(() => {

                        }).
                        finally(() => {
                            this.listarOrigem();
                            
                        })
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarOrigem() {
            this.alert = {};

            for (let index = 0; index < this.itemsOrigem.length; index++) {

                if (this.origemClonada.nome === this.itemsOrigem[index].nome) {
                    this.idOrigemClonado = this.itemsOrigem[index].id
                    console.log(this.idOrigemClonado)
                    break;
                }


            }


            if (this.idOrigemClonado) {
                this.origem.id = this.idOrigemClonado;

            }

            if (this.validaCampo() == true) {
               

                this.origem.idLogado = this.usuarioLogado.id


                origemService
                    .alterarOrigem(this.origem.id, this.origem)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                        this.listarOrigem();
                    })
                    .catch(() => {

                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        listarOrigem() {
            origemService
                .listarOrigem().then((res) => {
                    this.itemsOrigem = res;
                   
                });
        },
    },


}

</script>
<style scoped>

</style>