<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Práticas Integrativas</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3" role="group">

                            <div role="group">
                                <label for="input-live">Prática Integrativa<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="refOrigemDrop"
                                    :state="stateValida.praticaIntegrativa"
                                    v-model="praticas.praticaIntegrativa"
                                    v-on:change="validaCampo, listarPraticas(), habilitarCampo()">                                  
                                    <option value="0"></option>
                                    <option v-for="pr in tipoPraticaIntegrativas" :key="pr.id" :value="pr.id">{{
                                        pr.nome
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo pratica integrativa é obrigatório!</div>

                        </div>

                        
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Público Alvo<strong style="color:red">*</strong></label>
                            <b-form-input id="publicoAlvo" :state="stateValida.publicoAlvo" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o publico alvo" v-model="praticas.publicoAlvo" SIZE="20" MAXLENGTH="20"
                                disabled></b-form-input>
                            <div class="invalid-feedback">Campo público alvo é obrigatório!</div>
                        </div>
                        <div class="col-lg-12" role="group">
                            <label for="input-live">Habilidades Praticas<strong style="color:red">*</strong></label>
                            <b-form-input id="habilidadesPraticas" :state="stateValida.habilidadesPraticas" v-on:change="validaCampo" type="text"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Pratica" v-model="praticas.habilidadesPraticas" SIZE="60" MAXLENGTH="60"
                                disabled></b-form-input>
                            <div class="invalid-feedback">Campo hablidades práticas é obrigatório!</div>
                        </div>

                    </div>
                    <fieldset class="fieldset ">
                        <div class="row">
                            <div class="btn-group" role="group">
                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarPratica" variant="outline-success" id="btn-salvar">Salvar
                                    </b-button>
                                </div>

                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoPratica()">
                                        Novo
                                    </b-button>

                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                    </fieldset>



                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listar Práticas</h2>



                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Habilidade</th>
                                <th>Público</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>

                          
                            <tr v-for="pa in itensPraticas" :key="pa.id">

                                <td>{{ pa.praticaIntegrativa }}</td>
                                <td>{{ pa.habilidadesPraticas }}</td>

                               



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="praticas = JSON.parse(JSON.stringify(pa)), editarPratica()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { praticasService } from "@/service/praticasService";
import { constanteService } from "@/service/constanteService";


export default {
    data() {
        return {
            infoCargo: {},
            infoCarreira: {},
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            cargoErro: false,
            stateValida: {
                praticaIntegrativa: null,
                habilidadesPraticas: null,
                publicoAlvo: "",
               
            },
            listaPraticaoAuxiliar: [],

            praticas: {
                praticaIntegrativa: "",
                habilidadesPraticas: "",
                publicoAlvo: "",
                usuario:"",
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            praticaClonado: {},
            idPraticaClonado: "",           


            tipoPraticaIntegrativas: [],
            itensPraticas: [],
            tipoPratica: [],
            validado:"",

        }
    },
    mounted() {
        this.inicialiazar();

    },
    methods: {
        limparObjetoPratica(){
            this.praticas = {};
            document.getElementById("btn-salvar").textContent = "Salvar"
        },
        async inicialiazar() {
            await this.verificaLocalStore();

           
            await this.listarPraticas();
            await this.listarPraticasIntegrativas();
            this.alerta = {};

        },
        listarPraticas() {
            let usuario = this.usuarioPagina.id;
            praticasService
                .listarPraticas(usuario).then((res) => {
                    this.itensPraticas = res;


                });
        },
        listarPraticasIntegrativas(){
            constanteService
                .listarPraticasIntegrativas().then((res) => {
                    this.tipoPraticaIntegrativas = res;


                });
        },
        limparObjetoCargo() {
            document.getElementById('habilidadesPraticas').disabled = true;
            document.getElementById('siglaCargo').disabled = true;
            document.getElementById("refOrigemDrop").value = "";
            this.alerta = {};

            this.praticaClonado = {};
            this.idPraticaClonado = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
            }, 200)
            this.praticas = {};
        },
        alterarPraticas() {
            this.alert = {};

            for (let index = 0; index < this.itensPraticas.length; index++) {

                if (this.praticaClonado.habilidadesPraticas === this.itensPraticas[index].habilidadesPraticas) {
                    this.idPraticaClonado = this.itensPraticas[index].id
                    
                    break;
                }


            }


            if (this.idPraticaClonado) {
                this.praticas.id = this.idPraticaClonado;

            }


            if (this.validaCampo() == true) {
                this.praticas.idLogado = this.usuarioLogado.id;
                this.praticas.usuario = this.usuarioPagina.id;
               
                praticasService
                    .alterarPraticas(this.praticas.id, this.praticas)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()                       

                        this.listarPraticas();
                        
                    })
                    .catch((error) => {
                        this.alert.mensagem = error.response.data.mensagem;
                            this.alert.variant = "danger";
                            this.showAlert()
                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },       
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        editarPratica() {
            this.alerta = {};
            this.praticaClonado = {};
            this.idPraticaClonado = "";
            document.getElementById("btn-salvar").textContent = "Alterar"
            document.getElementById('habilidadesPraticas').disabled = false;
            document.getElementById('publicoAlvo').disabled = false;
            

        },

        validaCampo() {

            if (this.praticas.praticaIntegrativa) {
                this.stateValida.praticaIntegrativa = true;
                this.validado =  true;

            }
            else if (!this.praticas.praticaIntegrativa) {
                this.stateValida.praticaIntegrativa = false;
                this.validado =  false;
            }
            if (this.praticas.habilidadesPraticas) {
                this.stateValida.habilidadesPraticas = true;
                this.validado =  true;

            }
            else if (!this.praticas.habilidadesPraticas) {
                this.stateValida.habilidadesPraticas = false;
                this.validado =  false;
            }
            if (this.praticas.publicoAlvo) {
                this.stateValida.publicoAlvo = true;
                this.validado =  true;

            }
            else if (!this.praticas.publicoAlvo) {
                this.stateValida.publicoAlvo = false;
                this.validado =  false;
            }
           
            return this.validado
        },       

        adicionarPratica() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarPraticas();
            } else {
                if (this.validaCampo() == true) {
                    this.praticas.idLogado = this.usuarioLogado.id
                    this.praticas.usuario = this.usuarioPagina.id;
     

                    praticasService
                        .salvarPraticas(this.praticas)
                        .then((res) => {


                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            this.praticaClonado = JSON.parse(JSON.stringify(this.praticas));
                            document.getElementById("btn-salvar").textContent = "Alterar"                            
                            this.listarPraticas();

                        })
                        .catch((error) => {
                            this.alert.mensagem = error.response.data.mensagem;
                            this.alert.variant = "danger";
                            this.showAlert()

                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }

        },
       
       
        habilitarCampo() {
            let comboOrigem = document.getElementById("refOrigemDrop").value;
            if (this.praticas.praticaIntegrativa && comboOrigem) {
                document.getElementById('habilidadesPraticas').disabled = false;
                document.getElementById('publicoAlvo').disabled = false;
                this.praticas.habilidadesPraticas = "";
                this.praticas.publicoAlvo = "";
                document.getElementById("btn-salvar").textContent = "Salvar"

            } else {
                this.praticas.habilidadesPraticas = "";
                this.praticas.publicoAlvo = "";
                
                document.getElementById('habilidadesPraticas').disabled = true;
                document.getElementById('publicoAlvo').disabled = true;
            }

        },

    },


}

</script>
<style scoped>

</style>
