import axios from "axios";

export const afastamentoService = {

    
    salvarAfastamento(afastamento) {
      
        return new Promise((resolve, reject) => {
        
          axios.post(`${process.env.VUE_APP_URL_BASE_BACK_END}/afastamento`, afastamento,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
      listarAfastamento(vinculo) {
        
        return new Promise((resolve, reject) => {
         
          axios.get(`${process.env.VUE_APP_URL_BASE_BACK_END}/afastamento/listar/${vinculo}`,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
      listarTipoAfastamento() {
        return new Promise((resolve, reject) => {
          
          axios.get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoAfastamento`,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
      alterarAfastamento(id,afastamento) {
        return new Promise((resolve, reject) => {
          
          axios.put(`${process.env.VUE_APP_URL_BASE_BACK_END}/afastamento/${id}`, afastamento,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      },
  
}