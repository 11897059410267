import axios from "axios";

export const gerenciaService = {
  salvarGerencia(gerencia) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/gerencia`,
          gerencia,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarGerencia(id, gerencia) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/gerencia/${id}`,
          gerencia,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarGerencia(idDiretoria) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/gerencia/listar/${idDiretoria}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pegaGerencia(idGerencia){
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/gerencia/${idGerencia}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
