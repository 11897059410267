<template>

    <div class="container">
        
        <!-- Formulários de entrada-->
        <div class="card">
            <h2>Pré-Cadastro de Servidor</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
                <div class="col-lg-12">
                   
                    <div class="row">
                  
                        
                       
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Gerência:</label>
                                <select class="form-control mb-3 input-select" id="refGerenciaDrop" v-model="preCadastro.gerencia"
                                    v-on:change="pegaValorCampo, listarNucleo()">
                                    <option value="0"></option>
                                    <option
                                        
                                        v-for="gerencia in listaGerencia" :key="gerencia.id" :value="gerencia.id">{{
                                                gerencia.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Núcleo:</label>
                                <select class="form-control mb-3 input-select" id="refNucleoDrop" v-model="preCadastro.nucleo"
                                    v-on:change="pegaValorCampo, listarSetor()">
                                    <option value="0"></option>
                                    <option
                                        
                                        v-for="nucleo in listaNucleo" :key="nucleo.id" :value="nucleo.id">{{
                                                nucleo.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Área Técnica:</label>
                                <select class="form-control mb-3 input-select" id="refSetorDrop" v-model="preCadastro.setor">
                                    <option value="0"></option>
                                    <option
                                        
                                        v-for="setor in listaSetor" :key="setor.id" :value="setor.id">{{
                                                setor.sigla
                                        }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                       
                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Nome Servidor:</label>
                                <b-form-input v-model="preCadastro.nomeSocial" id="input-live"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira o nome completo" trim max="60"></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div role="group">
                                <label id="cpf" for="input-live">CPF:</label>
                                
                                <input  v-mask="'###.###.###-##'" class="form-control"
                                      placeholder="Informe o CPF" required="" autofocus=""  v-model="preCadastro.cpf">
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Matricula:</label>
                                <b-form-input 
                                    v-model="preCadastro.matricula"
                                    id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Matricula" SIZE="5" MAXLENGTH = "12" 
                                    :state="stateValida.nome">
                                </b-form-input>
                                <div class="invalid-feedback"> A matrícula deve contar no mínimo 5 e máximo 12 caracteres.</div>
                            </div>
                        </div>
                         <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Email Pessoal:</label>
                                <b-form-input 
                                    v-model="preCadastro.emailPessoal"
                                    id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="E-mail">
                                </b-form-input>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="container-buttons container-buttons--colunas">
                        <b-button  id="btn-salvar-alterar" 
                            variant="outline-success" @click="RetiraMascara()" >
                            Cadastrar usuário
                        </b-button>                         
                    </div>                    
                </div>
                <div class="col-lg-2">
                    <div class="container-buttons container-buttons--colunas">
                        
                        <b-button id="btn-pequisa-novo" variant="outline-success" @click="limpaCampos">Novo
                        </b-button>     
                        
                    </div>
                                        
                </div>
                <div class="col-lg-2">
                    <div class="container-buttons container-buttons--colunas">
                        
                        <b-button id="btn-pequisa-voltar" variant="outline-success" @click="voltarTelaLogin">Voltar
                        </b-button>     
                        
                    </div>
                                        
                </div>    
                
            </div>
        </div>
        

        
    </div>
</template>

<script>
import { diretoriaService } from "@/service/diretoriaService";
import { gerenciaService } from "@/service/gerenciaService";
import { nucleoService } from "@/service/nucleoService";
import { setorService } from "@/service/setorService";

import { preCadastroService } from "@/service/preCadastroService"; 

import Header from '@/partials/template/Header.vue';

import { mask } from "vue-the-mask";


export default {
    directives: { mask },
    data() {
        return {
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            usuarioLogado: {},
            usuarioPagina: {},
            

           
            alert: {
                mensagem: "",
                variant: ""
            },
            preCadastro:{
                diretoria:"",
                gerencia:"",
                nucleo:"",
                setor:"",
                nomeSocial:"",
                cpf:"",
                matricula:"",
                emailPessoal:"",
               idLogado:"",
               origem: "0",

            },
            stateValida: {                
                gerencia: null,
                nucleo: null,
                setor: null,
                nomeSocial: null,
                cpf: null,
                matricula: null,
                emailPessoal: null,
               idLogado: null,
               origem: "0",
            },
            PreCadastroClonada: {},

            itensDiretoria: [],
            listaGerencia: [],
            listaNucleo: [],
            listaSetor: [],
            mensagem:"",     
            idPreCadastroClonado:"",    
            validado:"",    

        }
    },
    component: {
    Header
  }, 

/*    beforeUpdate() {
    this.$store.commit('setLayout', { Header: true })
  }, */
    methods: {
        validaCampo() {
           

           if (this.preCadastro.gerencia ) {
               this.stateValida.gerencia = true;
               this.validado = true;

           }
           else if (!this.preCadastro.gerencia ) {
               this.stateValida.gerencia  = false;
               this.validado = false;
           }
           if (this.preCadastro.nucleo) {
               this.stateValida.nucleo = true;
               this.validado = true;

           }
           else if (!this.preCadastro.nucleo) {
               this.stateValida.nucleo = false;
               this.validado = false;
           }
           if (this.preCadastro.setor) {
               this.stateValida.setor = true;
               this.validado = true;

           }
           else if (!this.preCadastro.setor) {
               this.stateValida.setor = false;
               this.validado = false;
           }
           if (this.preCadastro.cpf) {
               this.stateValida.cpf = true;
               this.validado = true;

           }
           else if (!this.preCadastro.cpf) {
               this.stateValida.cpf = false;
               this.validado = false;
           } 
           if (this.preCadastro.matricula) {
               this.stateValida.matricula = true;
               this.validado = true;

           }
           else if (!this.preCadastro.matricula) {
               this.stateValida.matricula = false;
               this.validado = false;
           } 
           if (this.preCadastro.emailPessoal) {
               this.stateValida.emailPessoal = true;
               this.validado = true;

           }
           else if (!this.preCadastro.emailPessoal) {
               this.stateValida.emailPessoal = false;
               this.validado = false;
           }  
                   
           
           
           return this.validado;


       },
          voltarTelaLogin() {
            this.$router.push({
                path: "/",
                params: { parametros: "" },
            });
          },
        
        adicionarPreCadastro() {
            this.alert = {};

            if (document.getElementById("btn-salvar-alterar").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarPreCadastro();
                }, 200)
            } else {
               // if (!this.validaCampo()) {
                    this.preCadastro.diretoria = 1
                    this.preCadastro.idLogado = "0"
                    this.idPreCadastroClonado = "";

                    this.PreCadastroClonada.nome = "";
                    this.PreCadastroClonada.sigla = "";
                    this.PreCadastroClonada.diretoria = "";
                    this.PreCadastroClonada.idLogado = "";
                    this.PreCadastroClonada.id = "";

                    preCadastroService
                        .salvarPreCadastro(this.preCadastro)
                        .then((res) => {

                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                            setTimeout(() => {
                              
                                this.preCadastroClonado = JSON.parse(JSON.stringify(this.preCadastro));

                            }, 3000)

                            document.getElementById("btn-salvar-alterar").textContent = "Alterar"


                        })
                        .catch((error) => {
                            this.alert.mensagem = error.response.data.mensagem;
                             this.alert.variant = "danger";
                             this.showAlert()

                        }).
                        finally(() => {
                           
                        })
              
            }
        },
        alterarPrecadastro() {
            this.alert = {};

            for (let index = 0; index < this.itemsPreCadastro.length; index++) {

                if (this.preCadastroClonado.nome === this.itemsPreCadastro[index].nome) {
                    this.idPreCadastroClonado = this.itemsPreCadastro[index].id
                    console.log(this.idPreCadastroClonado)
                    break;
                }


            }


            if (this.idPreCadastroClonado) {
                this.preCadastro.id = this.idPreCadastroClonado;

            }

          //  if (this.validaCampo() == true) {
                this.preCadastro.diretoria = 1
                // como só existe uma diretoria, o idDiretoria colocamos na mão -> this.usuarioPagina.vinculos[0].setor.lotacao.diretoria; 

                this.preCadastro.idLogado = this.usuarioLogado.id


                preCadastroService
                    .alterarPrecadastro(this.preCadastro.id, this.preCadastro)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()
                        this.listarPreCadastro();
                    })
                    .catch((error) => {
                         this.alert.mensagem = error.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()

                    });
         //   }else {
              /* this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert(); */
            //}
        },
        RetiraMascara() {
      let cpf = this.preCadastro.cpf;
      if (cpf.length == 14) {
        cpf = cpf.charAt(0) + cpf.charAt(1) + cpf.charAt(2) +
          cpf.charAt(4) + cpf.charAt(5) + cpf.charAt(6) +
          cpf.charAt(8) + cpf.charAt(9) + cpf.charAt(10) +
          cpf.charAt(12) + cpf.charAt(13);

        this.preCadastro.cpf = cpf;

        this.adicionarPreCadastro();
      } else {
        this.showAlert();
      }
    },   
     
       
         inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
       
        
      
       
        limpaCampos() {
        this.preCadastro = {};
           
        },
        async listarDiretoria() {
           
            await diretoriaService
                .listarDiretoria().then((res) => {
                    this.itensDiretoria = res;
                
                 this.listarGerencia();
   
                    
                });
        },
        async listarGerencia() {
            var idDiretoria = 1
            await gerenciaService
                .listarGerencia(idDiretoria).then((res) => {
                    this.listaGerencia = res;
                     this.listarNucleo();
   
                });
        },
        async listarNucleo(idGerencia) {
       
            idGerencia = document.getElementById("refGerenciaDrop").value
             
       
            await nucleoService
                .listarNucleo(idGerencia).then((res) => {
                    this.listaNucleo = res;
                
                }).catch(erro => {
                    console.log(erro);
                    if (erro.response && erro.response.status == 404) {
                        this.listaNucleo = []
                    }
                });
        },
        async listarSetor() {
        let idDiretoria = "1" 
        let idGerencia  =  document.getElementById("refGerenciaDrop").value
        let idNucleo    =    document.getElementById("refNucleoDrop").value
            
            await setorService
                .listarSetor(idDiretoria, idGerencia, idNucleo).then((res) => {
                    this.listaSetor = res;
                    console.log("setores", this.listaSetor)
                }).catch(erro => {
                    console.log(erro);
                    if (erro.response && erro.response.status == 404) {
                        this.listaSetor = []
                    }
                });
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
    },
   
    mounted() {
    this.listarDiretoria();   
    },
   
}
</script>
