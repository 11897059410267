<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo" v-if="!$route.params.parametro"> Férias</h2>
            <h2 class="card__titulo" v-else> Férias</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
           
            <div class="row">

                <div class="col-lg-12">

                    <div class="row">

                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Ano de exercicio<strong style="color:red">*</strong></label>
                               
                                <select class="form-control mb-3 input-select" id="selectAnoExercicio"
                                    :state="stateValida.exercicio" v-model="feriasSelecionadas.exercicio"
                                    v-on:change="validaCampo">
                                    <option value="0"></option>
                                    <option v-for="ano in itemsAnoExercicio" :key="ano.id" :value="ano.id">{{
                                            ano.nome
                                    }}</option>
                                </select>
                                <div class="invalid-feedback">Campo ano exercício é obrigatório!</div>

                            </div>
                        </div>


                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Data início<strong style="color:red">*</strong></label>
                                <b-form-input id="datepicker" :state="stateValida.inicio" type="date"
                                    v-model="feriasSelecionadas.inicio"
                                    v-on:change="validaCampo, calculaDiasAfastamento()"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira a data de inicio das férias" trim class="datepicker"
                                    data-date-format="mm/dd/yyyy"></b-form-input>
                                <div class="invalid-feedback">Campo data início é obrigatório!</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Data Fim<strong style="color:red">*</strong></label>
                                <b-form-input id="datepicker-invalid" type="date" :state="stateValida.termino"
                                    v-on:change="validaCampo, calculaDiasAfastamento()"
                                    v-model="feriasSelecionadas.termino"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira a data de termino das férias" trim>
                                </b-form-input>
                                <!-- <input type="text" v-model="feriasSelecionadas.termino" v-mask="'##/##/####'" /> -->
                                <div class="invalid-feedback">Campo data fim é obrigatório!</div>

                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="col-lg-3">
                                <div role="group">
                                    <label for="input-live">Dias:</label>
                                    <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                        v-model="feriasSelecionadas.dias" disabled>
                                    </b-form-input>
                                    <div class="invalid-feedback">Campo dias é obrigatório!</div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="row">

                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe o Processo" trim v-model="feriasSelecionadas.processoSEI"
                                    SIZE="30" MAXLENGTH="30">
                                </b-form-input>
                            </div>

                        </div>
                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Url do Processo SEI:</label>
                                <b-form-input id="input-live" aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Informe a url" trim v-model="feriasSelecionadas.urlSEI"></b-form-input>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-1">

                            <div class="container-buttons-salvar">
                                <b-button id="btn-salvarFerias" @click="adicionarFerias" variant="outline-success">
                                    Salvar</b-button>
                                
                            </div>
                        </div>
                        <div class="col-lg-1">

                            <div class="container-buttons-salvar">

                                <b-button variant="outline-success" @click="limparObjeto()">Novo</b-button>

                            </div>
                        </div>
                        <div class="col-lg-1">
                            <div class="container-buttons-salvar">
                                <router-link to="/inicio">
                                    <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Lista de Férias</h2>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Ano Exercício</th>
                                <th>Período</th>
                                <th>Dias</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!--     <td><router-link to="/ferias">{{ ferias.exercicio }}</router-link></td> -->
                            <tr v-for="ferias in itemsFerias" :key="ferias.id">
                                <td> {{ ferias.exercicio }}</td>
                                <td>{{ ferias.inicio }} a {{ ferias.termino }} </td>
                                <td> {{ ferias.dias }}</td>
                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success" @click="editarFerias(ferias)">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { feriasService } from "@/service/feriasService";
import { constanteService } from "@/service/constanteService";

export default {
    beforeRouteEnter(to, from, next) {
    if (
      from.name == "inicio" 
    ) {
      return next();
    }

    return next("/inicio");
  },
    data() {
        return {
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            alert: {
                mensagem: "",
                variant: ""
            },
            stateValida: {
                exercicio: null,
                inicio: null,
                termino: null,
                vinculo: null,
                dias: null,
            },
            feriasSelecionadas: {
                exercicio: null,
                inicio: null,
                termino: null,
                dias: "",
                processoSEI: "",
                urlSEI: "",
                vinculo: null,
                tipoAfastamento: null,
                idLogado: ""
            },
            ferias: [],
            itemsFerias: [],
            itemsAnoExercicio: [],
            validado:"",

        }
    },
   
    mounted() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            await this.verificaLocalStore();
            this.listarAnosExercicio();

            this.listarFerias();
            if (this.$route.params.parametro) {
                document.getElementById("btn-salvarFerias").textContent = "Alterar"
               
                this.feriasSelecionadas = JSON.parse(this.$route.params.parametro)
            }
            this.feriasSelecionadas.inicio = this.inverterCampoData(this.feriasSelecionadas.inicio)
            this.feriasSelecionadas.termino = this.inverterCampoData(this.feriasSelecionadas.termino)
        },

        limparObjeto() {
            this.feriasSelecionadas = {};
            document.getElementById("btn-salvarFerias").textContent = "Salvar"
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        calculaDiasAfastamento() {

            let start = this.feriasSelecionadas.inicio;
            let and = this.feriasSelecionadas.termino;
            if (start && and) {
                start = new Date(start)
                and = new Date(and)

                let diffInTime = Math.abs(and - start)
                let timeInOneDay = 1000 * 60 * 60 * 24
                let diffInDays = diffInTime / timeInOneDay + 1

                this.feriasSelecionadas.dias = diffInDays;


                return diffInDays

            }
            this.feriasSelecionadas.dias = "";

        },
        validaCampo() {

            if (this.feriasSelecionadas.exercicio) {
                this.stateValida.exercicio = true;
                this.validado =  true;

            }
            else if (!this.feriasSelecionadas.exercicio) {
                this.stateValida.exercicio = false;
                this.validado =  false;
            }
            if (this.feriasSelecionadas.inicio) {
                this.stateValida.inicio = true;
                this.validado =  true;

            }
            else if (!this.feriasSelecionadas.inicio) {
                this.stateValida.inicio = false;
                this.validado =  false;
            }
            if (this.feriasSelecionadas.termino) {
                this.stateValida.termino = true;
                this.validado =  true;

            }
            else if (!this.feriasSelecionadas.termino) {
                this.stateValida.termino = false;
                this.validado =  false;
            }
            if (this.feriasSelecionadas.dias) {
                this.stateValida.dias = true;
                this.validado =  true;

            }
            else if (!this.feriasSelecionadas.dias) {
                this.stateValida.dias = false;
                this.validado =  false;
            }
            return this.validado


        },
        editarFerias(ferias) {
            document.getElementById("btn-salvarFerias").textContent = "Alterar"

            this.feriasSelecionadas = JSON.parse(JSON.stringify(ferias))

            setTimeout(() => {
                this.feriasSelecionadas.inicio = this.inverterCampoData(ferias.inicio);
                this.feriasSelecionadas.termino = this.inverterCampoData(ferias.termino);

            }, 200)
        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
        inverterCampoData(data) {
            data = data
                .split("/")
                .reverse()
                .join("/");
            data = data.replaceAll("/", "-")

            return data;
        },
      
        adicionarFerias() {
            this.alert = {};
            if (document.getElementById("btn-salvarFerias").textContent == "Alterar") {
                this.alterarFerias();
            } else {
                this.feriasSelecionadas.idLogado = this.usuarioPagina.id;

                if (this.validaCampo() == true) {
                    this.feriasSelecionadas.vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
                    feriasService
                        .salvarFerias(this.feriasSelecionadas)
                        .then((res) => {
                            console.log(res)
                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()
                            this.listarFerias();
                           // this.limparObjetoFerias();
                           document.getElementById("btn-salvarFerias").textContent = "Alterar"
                        })
                        .catch((error) => {
                            console.log("qual o erro", error.response)
                           
                        });
                } else {
                    this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
                    this.alert.variant = "danger";
                    this.showAlert();
                }
            }
        },
        alterarFerias() {
            this.alert = {};
            this.feriasSelecionadas.inicio = this.inverterCampoData(this.feriasSelecionadas.inicio)
            this.feriasSelecionadas.termino = this.inverterCampoData(this.feriasSelecionadas.termino)
            console.log("data inicio das ferias", this.feriasSelecionadas.inicio)
            feriasService
                .alterarFerias(this.feriasSelecionadas.id, this.feriasSelecionadas)
                .then((res) => {
                   
                    this.alert.mensagem = res.mensagem;
                    this.alert.variant = "success";
                    this.showAlert();

                  

                    this.listarFerias();
                })
                .catch((error) => {
                    console.log(error);
                    this.alert.mensagem = error.response.data.mensagem;
                    this.alert.variant = "danger";
                    this.showAlert();
                   
                });
        },
        listarFerias() {
            let vinculo = this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].id;
            feriasService
                .listarFerias(vinculo).then((res) => {
                    this.itemsFerias = res;
                    console.log("ferias é atual", this.itemsFerias)

                    this.itemsFerias.inicio = this.inverterCampoData(this.itemsFerias.inicio)
                    this.itemsFerias.termino = this.inverterCampoData(this.itemsFerias.termino)

                });
        },

        listarAnosExercicio() {
            constanteService
                .listarAnosExercicio().then((res) => {
                    this.itemsAnoExercicio = res;
                });
        },
        limparObjetoFerias() {
            this.feriasSelecionadas.exercicio = "";
            this.feriasSelecionadas.inicio = "";
            this.feriasSelecionadas.termino = "";
            this.feriasSelecionadas.dias = "";
            this.feriasSelecionadas.processoSEI = "";
            this.feriasSelecionadas.urlSEI = "";
            this.feriasSelecionadas.vinculo = "";
            
        },
        calculaDataFim() {

            let date = this.feriasSelecionadas.inicio;
            let days = this.feriasSelecionadas.dias;

         


            var res = new Date(date);
            this.formatarObjetoData(res.setDate(res.getDate() + days));
            console.log("resultado da data final", res)
            return res;





        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
       

    },


}

</script>
<style scoped>
.borda {
    border-width: thin;
    border-style: solid;
    border-color: #198754;
    border-radius: 5%;

}

.fieldset {

    max-width: 250px;
    padding: 8px;
    border-width: thin;
    border-style: solid;
    border-color: #198754;
    border-radius: 5%;
    float: right;
    margin-right: 10px;

}

.legend1 {
    max-width: 250px;
    padding: 8px;
    float: right;
    margin-right: 10px;
}
</style>