<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Instituição de Ensino </h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
               
                <div class="col-lg-9">
                    <div class="row">
                     
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>

                            <b-form-input id="input-live" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                v-model="instituicaoEnsino.nome" SIZE="60" MAXLENGTH = "60"></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-6" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="input-live" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                v-model="instituicaoEnsino.sigla" SIZE="20" MAXLENGTH = "20"></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>


                    </div>

                </div>
<fieldset class="fieldset " > 
                <div class="row">
                    <div class="btn-group" role="group">

                        <div class="container-buttons-salvar">
                            <b-button @click="adicionarInstituicao" variant="outline-success" id="btn-salvar">Salvar
                            </b-button> &nbsp;
                        </div>
                        <div class="container-buttons-salvar">
                            <b-button @click="limparObjInstituicao" variant="outline-success" id="btn-salvar">Novo
                            </b-button>
                        </div>
                        &nbsp;

                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
                </fieldset>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas das Instituições de Ensino</h2>

                  

                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>
                         
                            <tr v-for="i in itemsInstituicaoEnsino" :key="i.id">

                                <td>{{ i.nome }}</td>
                                <td>{{ i.sigla }}</td>



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="instituicaoEnsino = JSON.parse(JSON.stringify(i)), editarInstituicaoEnsino()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>


</template>

<script>

import { instituicaoEnsinoService } from "@/service/instituicaoEnsinoService";

export default {
    data() {
        return {
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            instituicaoEnsino: {
                nome: null,
                sigla: null,
                idLogado: "",
            },
            stateValida: {
                nome: null,
                sigla: null,
            },
              alert: {
                mensagem: "",
                variant: ""
            },

            listaInstituicaoEnsino: [],

            mensagem: "",
            validado:"",

            fields: ['nome', 'sigla'],
            itemsInstituicaoEnsino: [
            ]
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
       async inicializar() {
        await this.verificaLocalStore()
            this.listarInstituicaoEnsino();
        },
        limparObjInstituicao() {
            this.alerta = {};
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"

            }, 200)

            this.instituicaoEnsino = {};

        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        validaCampo() {
            if (this.instituicaoEnsino.nome) {
                this.stateValida.nome = true;
                this.validado =  true;
            }
            else if (!this.instituicaoEnsino.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.instituicaoEnsino.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;
            }
            else if (!this.instituicaoEnsino.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
            return this.validado;
        },
        editarInstituicaoEnsino() {
            this.alerta = {};
            document.getElementById("btn-salvar").textContent = "Alterar"
            //document.getElementById("btn-voltar").textContent = "Cancelar"
        },
        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
      
        adicionarInstituicao() {
            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarInstituicao();
            } else {
                if (this.validaCampo() == true) {
                    this.instituicaoEnsino.idLogado = this.usuarioLogado.id
                    instituicaoEnsinoService
                        .salvarInstituicaoEnsino(this.instituicaoEnsino)
                        .then((res) => {

                           this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            this.instituicaoEnsino = {}
                            this.stateValida = {}

                            this.listarInstituicaoEnsino()
                        })
                        .catch(() => {
                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarInstituicao() {
            if (this.validaCampo() == true) {
                 this.instituicaoEnsino.idLogado = this.usuarioLogado.id
                instituicaoEnsinoService
                    .alterarInstituicaoEnsino(this.instituicaoEnsino.id, this.instituicaoEnsino)
                    .then((res) => {
                        this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()

                        
                        this.listarInstituicaoEnsino();
                    })
                    .catch(() => {
                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },
        listarInstituicaoEnsino() {
            instituicaoEnsinoService
                .listarInstituicaoEnsino().then((res) => {
                    this.itemsInstituicaoEnsino = res;
                    console.log("instituicaoEnsino teste", this.itemsInstituicaoEnsino)
                });
        },
    }
}
</script>
<style scoped>



</style>