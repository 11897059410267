import axios from "axios";

export const constanteService = {
  /*   salvarAbono(abono) {
        return new Promise((resolve, reject) => {

          
          axios.post(`${process.env.VUE_APP_URL_BASE_BACK_END}/abono`, abono,)
          .then(ret => {
            resolve(ret.data)
          }).catch(err => {
            reject(err)
          })
        });
      }, */
  listarTipoCurso() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoCurso/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarStatusCurso() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/estatusCurso/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarAreaFormacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/areaFormacao/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarAnosExercicio() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/constante/anosExercicio/`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoSanguineo() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoSanguineo/`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarSexo() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/sexo/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoGenero() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/genero/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoDeficiencia() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoDeficiencia/`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoUF() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/uf/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarEscolaridade() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/escolaridade/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarRa() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/ra/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  tipoTelefone() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoTelefone/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoIdioma() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/idioma/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarNivelIdioma() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/nivelIdioma/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /* listarAreaFormacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/areaFormacao/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, */
  listarTipoVinculo() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoVinculo/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoPublicacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoPublicacao/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoParticipacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoParticipacao/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarEventoParticipacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/eventoParticipacao/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarStatusPublicacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/estatusCurso/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarPraticasIntegrativas() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/praticas/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoDonsArtisticos() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/donsArtisticos/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarAtividade() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/atividade/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarAbrangencia() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/abrangencia/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarSituacaoVisita() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/situacaoVisita/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarNatureza() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/natureza/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarOrientacao() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/orientacao/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarModalidade() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/modalidade/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarTipoEvento() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL_BASE_BACK_END}/constante/tipoEvento/`)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
