<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Experiência Profissional </h2>
            <div class="row">
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
              
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Instituição:</label>
                                <b-form-input  id="input-live" :state="stateValida.instituicao" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira a instituição" v-model="experienciaProfissional.instituicao"></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Cargo:</label>
                                <b-form-input  id="input-live" :state="stateValida.cargo" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira o cargo" v-model="experienciaProfissional.cargo"></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Atividades:</label>
                                <b-form-input  id="input-live" :state="stateValida.atividades" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira o nome" v-model="experienciaProfissional.atividades"></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Projetos:</label>
                               
                                <b-form-textarea  id="input-live" :state="stateValida.projetos" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira o nome do projeto" v-model="experienciaProfissional.projetos"></b-form-textarea>
                            </div>
                        </div>
              
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Área de formação:</label>
                                <select class="form-control mb-3 input-select" id="refNucleoDrop" :state="stateValida.areaFormacao " v-on:change="validaCampo"
                                v-model="experienciaProfissional.areaFormacao ">                                    
                                <option value="0"></option> 
            
                                    <option                                        
                                        v-for="area in itemsTipoAreaFormacao" :key="area.id" :value="area.id">{{
                                            area.nome
                                        }}</option>
                                </select>
                            </div>
                        </div>
                
                        <div class="col-lg-4">
                            <div role="group">
                                <label for="input-live">Tipo vinculo:</label>
                                <select class="form-control mb-3 input-select" id="refSetorDrop" :state="stateValida.tipoVinculo  " v-on:change="validaCampo"
                                v-model="experienciaProfissional.tipoVinculo" >
                                    <option value="0"></option>
                                    <option
                                       
                                        v-for="tipoVinculo in itemsTipoVinculo" :key="tipoVinculo.id" :value="tipoVinculo.id">{{
                                            tipoVinculo.nome
                                        }}</option>
                                </select>
                            </div>
                        </div>

            </div>
                <div class="col-lg-12">
                        <div class="row"> 
                        <div class="row">                     


                       
                        <div class="col-lg-3">
                            <div role="group">
                                <label for="input-live">Ano início:</label>
                                <b-form-input  id="input-live" :state="stateValida.anoInicio" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback"
                                    placeholder="Insira o ano início" v-model="experienciaProfissional.anoInicio"></b-form-input>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div role="group">
                                <label id="cpf" for="input-live">Ano termino:</label>
                                <b-form-input   id="input-live" :state="stateValida.anoTermino" v-on:change="validaCampo"
                                    aria-describedby="input-live-help input-live-feedback" placeholder="Insira o ano termino"
                                    v-model="experienciaProfissional.anoTermino"></b-form-input>
                            </div>
                        </div>                        
                         
                        
                    </div>
                 

                    
                    </div>

                </div>

                <div class= "row"> 
                    <div class="btn-group" role="group">

                         <div class="container-buttons-salvar">
                            <b-button  variant="outline-success" id="btn-salvar" @click="adicionarExperienciaProfissional">Salvar</b-button>
                         </div>
                        &nbsp;
                         
                        <div class="container-buttons-salvar">
                            
                                <b-button variant="outline-success" id="btn-novo" @click="limparObjetoExperienciaProfissional">Novo</b-button>
                            
                        </div>
                            &nbsp;
                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas de Experiências Profissionais</h2>

                  

                    <table class="table table-striped">

                    <thead>
                        <tr>

                            <th>Instituição</th>
                            <th>Cargo</th>
                            <th>Atividades</th>
                            <th>Ação</th>


                        </tr>
                    </thead>
                    <tbody>
                       
                       
                        <tr v-for="e in itensExperienciaProfissional" :key="e.id">
                             <td>{{ e.instituicao }}</td>
                                <td>{{ e.cargo }}</td>
                                <td>{{ e.atividades }}</td>                            
                        
                            <td>
                                <div>
                                    <b-button type="button" variant="outline-success"
                                    @click="experienciaProfissional = JSON.parse(JSON.stringify(e)), editarExperienciaProfissional()">
                                        Editar</b-button>
                                </div>
                            </td>
                        </tr>

                    </tbody>

                </table>

                </div>
            </div>
        </div>
    </div>


</template>

<script>

import { experienciaProfissionalService } from "@/service/experienciaProfissionalService";



import { constanteService } from "@/service/constanteService";

export default {
    data() {
        return {
            usuarioPagina: {},
            usuarioLogado: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            experienciaProfissional: {
                instituicao: null,
                cargo: null,
                atividades: null,
                projetos: null,
                areaFormacao: null,
                tipoVinculo: null,
                anoInicio: null,
                anoTermino: null,
                usuario: null,
                idLogado: null,
                
            },
            stateValida: {
                instituicao: null,
                cargo: null,
                atividades: null,
                projetos: null,
                areaFormacao: null,
                tipoVinculo: null,
                anoInicio: null,
                anoTermino: null,
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            ExperienciaProfissionalClonado:{},
            idExperienciaProfissionalClonado: "",
            

            itensExperienciaProfissional:{},
           

            itemsTipoAreaFormacao:{},
            itemsTipoVinculo:{},
            validado:""
           
            
            
        }
    },
    mounted() {
     this.inicializar();    
       
    },
    methods: {
        editarExperienciaProfissional(){
            this.ExperienciaProfissionalClonado = {};
            this.idExperienciaProfissionalClonado = "";
            document.getElementById("btn-salvar").textContent = "Alterar"
            this.alerta = {};

        },
        adicionarExperienciaProfissional() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                setTimeout(() => {
                    this.alterarExperienciProfissional();
                }, 200)
            } else {

                if (this.validaCampo() == true) {
                    this.experienciaProfissional.usuario = this.usuarioPagina.id;
                    this.experienciaProfissional.idLogado = this.usuarioLogado.id

                    this.ExperienciaProfissionalClonado = {};
                    this.idExperienciaProfissionalClonado = "";
                    
                    experienciaProfissionalService
                        .salvarExperienciaProfissional(this.experienciaProfissional)
                        .then((res) => {
                            console.log("res da mensagem", res.mensagem)
                           
                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            setTimeout(() => {
                                this.alerta.msnSuccess = "";
                                this.ExperienciaProfissionalClonado = JSON.parse(JSON.stringify(this.experienciaProfissional));

                            }, 3000)

                            this.showAlert();
                            document.getElementById("btn-salvar").textContent = "Alterar";
                        })
                        .catch(() => {

                        }).
                        finally(() => {
                            this.listarExperienciaProfissional();
                            document.getElementById("btn-salvar").textContent = "Alterar";
                            
                        })
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        alterarExperienciProfissional() {
            this.alert = {};
            this.experienciaProfissional.usuario = this.usuarioPagina.id;

          
            for (let index = 0; index < this.itensExperienciaProfissional.length; index++) {

            if(this.ExperienciaProfissionalClonado.instituicao === this.itensExperienciaProfissional[index].instituicao){
                this.idExperienciaProfissionalClonado = this.itensExperienciaProfissional[index].id
            
                break;
            }
               
                
          }

        
        if(this.idExperienciaProfissionalClonado){ 
            this.experienciaProfissional.id = this.idExperienciaProfissionalClonado;
            
        } 

            this.experienciaProfissional.idLogado = this.usuarioLogado.id
            experienciaProfissionalService
                .alterarExperienciaProfissionalService(this.experienciaProfissional.id, this.experienciaProfissional)
                .then((res) => {
                    console.log("res da mensagem alterada", res.mensagem)

                 

                    this.listarExperienciaProfissional();
                  this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                })
                .catch(() => {
                    
                });
        },
       
        listarAreaFormacao() { 
            constanteService
                .listarAreaFormacao().then((res) => {
                    this.itemsTipoAreaFormacao = res;
                    
                });
        },
        listarTipoVinculo() { 
            constanteService
                .listarTipoVinculo().then((res) => {
                    this.itemsTipoVinculo = res;
                    
                });
        },
        async inicializar(){
            await this.verificaLocalStore();
            await this.listarAreaFormacao();
            await this.listarTipoVinculo();
            await this.listarExperienciaProfissional();

        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        listarExperienciaProfissional() { 
            let usuario = this.usuarioPagina.id;
            experienciaProfissionalService
                .listarExperienciaProfissional(usuario).then((res) => {
                    this.itensExperienciaProfissional = res;
                    
                });
        },
        limparObjetoExperienciaProfissional(){
            this.experienciaProfissional = {};
            this.alerta = {};
            this.ExperienciaProfissionalClonado= {};
            this.idExperienciaProfissionalClonado= "";
            
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"

            }, 200)

            
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        validaCampo() {

if (this.experienciaProfissional.instituicao) {
    this.stateValida.instituicao = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.instituicao) {
    this.stateValida.instituicao = false;
    this.validado =  false;
}
if (this.experienciaProfissional.cargo) {
    this.stateValida.cargo = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.cargo) {
    this.stateValida.cargo = false;
    this.validado =  false;
}
if (this.experienciaProfissional.atividades) {
    this.stateValida.atividades = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.atividades) {
    this.stateValida.atividades = false;
    this.validado =  false;
}
if (this.experienciaProfissional.projetos) {
    this.stateValida.projetos = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.projetos) {
    this.stateValida.projetos = false;
    this.validado =  false;
}
if (this.experienciaProfissional.areaFormacao ) {
    this.stateValida.areaFormacao  = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.areaFormacao ) {
    this.stateValida.areaFormacao  = false;
    this.validado =  false;
}
if (this.experienciaProfissional.tipoVinculo ) {
    this.stateValida.tipoVinculo  = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.tipoVinculo ) {
    this.stateValida.tipoVinculo  = false;
    this.validado =  false;
}
if (this.experienciaProfissional.anoInicio) {
    this.stateValida.anoInicio = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.anoInicio) {
    this.stateValida.anoInicio = false;
    this.validado =  false;
}
if (this.experienciaProfissional.anoTermino) {
    this.stateValida.anoTermino = true;
    this.validado =  true;

}
else if (!this.experienciaProfissional.anoTermino) {
    this.stateValida.anoTermino = false;
    this.validado =  false;
}
return this.validado

},

    }
}

</script>
