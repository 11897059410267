<template>
  <div class="container">
    <div class="card">
      <h2 class="card__titulo">Mentoria</h2>
      <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class=" col-lg-6 ">
          <label for="nome" class="form-label">Título<strong style="color:red">*</strong></label>
        
          <b-form-input type="text" class="form-control mb-3" required id="nome" v-model="mentoria.titulo"
            :state="stateValida.titulo"></b-form-input>
          <div class="invalid-feedback">Campo Título é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="nomSocial" class="form-label">Local <strong style="color:red">*</strong></label>
        
          <b-form-input type="text" class="form-control mb-3" id="nomSocial" v-model.trim="mentoria.local"
            :state="stateValida.local"></b-form-input>
          <div class="invalid-feedback">Campo local é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Início<strong style="color:red">*</strong></label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="mentoria.inicio"
            :state="stateValida.inicio">
          </b-form-input>
          <div class="invalid-feedback">Campo Início é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="staticEmail2">Término</label>
         
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="mentoria.termino">
          </b-form-input>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="cpf">Carga Horária<strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control mb-3" id="cargaHoraria" v-model="mentoria.cargaHoraria"
            :state="stateValida.cargaHoraria">
          </b-form-input>
          <div class="invalid-feedback">Campo Carga Horária é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="rg">Quantidade de Participantes<strong style="color:red">*</strong></label>
         
          <b-form-input type="text" class="form-control mb-3" id="quantAlunos" v-model="mentoria.quantParticipantes"
            :state="stateValida.quantParticipantes">
          </b-form-input>
          <div class="invalid-feedback">Campo Quantidade de Participantes é obrigatório!</div>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Público Alvo<strong style="color:red">*</strong></label>

          <b-form-input type="text" class="form-control mb-3" id="staticEmail2" v-model="mentoria.publicoAlvo"
            :state="stateValida.publicoAlvo">
          </b-form-input>

          <div class="invalid-feedback">Campo Publico Alvo é obrigatório!</div>
        </div>

        <div class="col-lg-6 ">
          <label for="staticEmail2">Abrangência<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.abrangencia"
            v-model="mentoria.abrangencia">
            <option value="0"></option>
            <option v-for="abr in itemAbrangencia" :key="abr.id" :value="abr.id">{{
            abr.nome
            }}

            </option>
          </select>


          <div class="invalid-feedback">Campo Abrangência é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="staticEmail2">Tipo<strong style="color:red">*</strong></label>

          <select class="form-control mb-3 input-select" id="tipo" :state="stateValida.tipo" v-model="mentoria.tipo">
            <option value="0"></option>
            <option v-for="tipoCurso in itemTipo" :key="tipoCurso.id" :value="tipoCurso.id">{{
            tipoCurso.nome
            }}

            </option>
          </select>
          <div class="invalid-feedback">Campo Tipo é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="staticEmail2">Situação<strong style="color:red">*</strong></label>

          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.situacao"
            v-model="mentoria.situacao">
            <option value="0"></option>
            <option v-for="sit in itemSituacaomentoria" :key="sit.id" :value="sit.id">{{
            sit.nome
            }}

            </option>

          </select>
          <div class="invalid-feedback">Campo Situação é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="staticEmail2">Modalidade<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="modalidade" :state="stateValida.modalidade"
            v-model="mentoria.modalidade">
            <option value="0"></option>
            <option v-for="mod in itemModalidade" :key="mod.id" :value="mod.id">{{
            mod.nome
            }}
            </option>
          </select>
          <div class="invalid-feedback">Campo Modalidade é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="input-live">Equipe<strong style="color:red">*</strong></label>
          <multiselect class="mb-3" id="refEquipe" v-model="equipe" :state="stateValida.equipe" :options="itemEquipe"
            :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
            placeholder="Informe Id, Nome ou Cpf do usuário" :show-no-results="false" :hide-selected="true"
            label="nomeSocial" track-by="nomeSocial" :preselect-first="true" :searchable="true" :loading="isLoading"
            :internal-search="false" @search-change="buscarUsuario">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Usuário(s)
                Selecionado(s)</span></template>
            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          </multiselect>

        </div>
      </div>
      <fieldset class="row">
        <div class="col-md-3">
          <label for="input-live">Agravo<strong style="color:red">*</strong></label>
          <div>
            <treeselect v-model="mentoria.agravos" :multiple="true" :options="itemAgravo" :state="stateValida.agravos" />
          </div>
        </div>
        <div class="col-lg-3">
          <label for="input-live">RA<strong style="color:red">*</strong></label>
          <div>            
            <treeselect v-model="mentoria.ras" :multiple="true" :options="itemRa" :state="stateValida.ras" />
          </div>
        </div>

        <div class="col-lg-3 ">
          <label for="processoSei">Processo SEI</label>
          <input type="text" class="form-control mb-3" id="processoSei" v-model="mentoria.processoSEI">
        </div>
        <div class="col-lg-3 ">
          <label for="urlSei">UrlSEI</label>
          <input type="text" class="form-control mb-3" id="urlSei" v-model="mentoria.urlSEI">
        </div>

      </fieldset>

      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="salvarMentoria" variant="outline-success" id="btn-salvar">Salvar
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="limparObjetoMentoria" variant="outline-success" id="btn-salvar">Novo
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <router-link to="/inicio">
              <b-button variant="outline-success">Voltar</b-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabela de visitas -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h2 class="card__titulo"> Lista de Mentoria</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Título</th>
                <th>Carga Horária</th>
                <th>Data Início</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in itemmentoria" :key="event.id">

                <td>{{ event.titulo }}</td>
                <td>{{ event.cargaHoraria }}</td>
                <td>{{ event.inicio }}</td>
                <td>
                  <div>
                    <b-button type="button" variant="outline-success"
                      @click="mentoria = JSON.parse(JSON.stringify(event)), editarMentoria(event)">Editar
                    </b-button>
                  </div>
                </td>
              </tr>

            </tbody>

          </table>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import { constanteService } from "@/service/constanteService";
import { mentoriaService } from "@/service/mentoriaService";
import { agravoService } from "@/service/agravoService";
import { usuarioService } from "@/service/usuarioService";
import { regiaoAdministrativaService } from "@/service/regiaoAdministrativaService";
import { mask } from "vue-the-mask";
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {

  directives: { mask },
  data() {
    return {

      alert: {
        mensagem: "",
        variant: ""
      },
      dismissCountDown: 0,
      dismissSecs: 10,
      usuarioLogado: {},
      usuarioPagina: {},
      mentoriaSelecionado: {},
      agravos: [],
      ras: [],
      equipe: [],
      itemRa: [],
      itemmentoria: [],
      itemModalidade: [],
      itemTipo: [],
      itemSituacaomentoria: [],
      itemAgravo: [],
      mentoriaClonada: {},
      idmentoriaClonado: "",
      itemEquipe: [],
      itemAbrangencia: [],
      isLoading: false,
      mentoria: {
        titulo: "",
        local: "",
        inicio: "",
        termino: "",
        cargaHoraria: "",
        quantParticipantes: "",
        publicoAlvo: "",
        abrangencia: "",
        tipo: "",
        situacao: "",
        modalidade: "",
        agravos: [],
        ras: [],
        equipe: [],
        processoSEI: "",
        urlSEI: "",
        idLogado: "",

      },

      stateValida: {
        titulo: "",
        local: "",
        inicio: "",
        termino: "",
        cargaHoraria: "",
        quantParticipantes: "",
        publicoAlvo: "",
        abrangencia: "",
        tipo: "",
        situacao: "",
        modalidade: "",
        agravos: "",
        ras: "",
        equipe: "",
      },
    }
  },
  components: {
    Multiselect,
    Treeselect
  },
  mounted() {
    this.inicializar();
  },
  methods: {

    limparObjetoMentoria() {
      this.alerta = {};
      this.idMentoriaClonado = "";

      this.mentoriaClonada = {}

      setTimeout(() => {
        document.getElementById("btn-salvar").textContent = "Salvar"

      }, 200)

      this.mentoria = {};

    },

    async buscarUsuario() {
      this.isLoading = true;
      let palavraChave = await document.getElementById("refEquipe").value;
      console.log("campo", palavraChave)
      usuarioService
        .buscarUsuarioSimples(palavraChave, this.usuarioLogado.id).then((res) => {
          this.itemEquipe = [res];
          console.log("Usuario Pesquisado", this.itemEquipe)
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        });
    },
    formataObjeto(array) {
      var listaIdLabel = [];
      for (let i = 0; i < array.length; i++) {
        let idLabel = {}
        idLabel.id = array[i].id;
        idLabel.label = array[i].nome;
        listaIdLabel.push(idLabel);
      }
      console.log("Lista de Ids e Labels", listaIdLabel)
      return listaIdLabel;
    },
    formataMultiSelect(selecionados, listaTotal) {
      let resultado = [];
      for (let i = 0; i < listaTotal.length; i++) {
        for (let j = 0; j < selecionados.length; j++) {
          if (selecionados[j] == listaTotal[i].id)
            resultado.push(listaTotal[i]);
        }
      }
      console.log("Resultado", resultado)
      return resultado;

    },
    async editarMentoria(mentoria) {
      this.alerta = {};
      this.mentoria = JSON.parse(JSON.stringify(mentoria))
      

      document.getElementById("btn-salvar").textContent = "Alterar"

      this.mentoria.inicio = await this.inverterCampoData(mentoria.inicio);
      this.mentoria.termino = await this.inverterCampoData(mentoria.termino);
      


      this.idMentoriaClonado = "";
      this.mentoriaClonada = {};
    },
    async alterarMentoria() {
      this.alert = {};
      this.mentoriaClonada.inicio = this.inverterCampoData(this.mentoria.inicio);
      this.mentoriaClonada.termino = this.inverterCampoData(this.mentoria.termino);

      for (let index = 0; index < this.itemMentoria.length; index++) {

        if (this.mentoriaClonada.titulo === this.itemMentoria[index].titulo) {
          this.idMentoriaClonado = this.itemMentoria[index].id

          break;
        }


      }


      if (this.idMentoriaClonado) {
        this.mentoria.id = this.idMentoriaClonado;

      }

      if (this.validaCampo() == true) {


        this.mentoria.idLogado = this.usuarioLogado.id
        this.mentoria.equipe = await this.pegaId(this.equipe);


        mentoriaService
          .alterarMentoria(this.mentoria.id, this.mentoria)
          .then((res) => {

            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert()
            this.listarMentoria();
          })
          .catch(() => {

          });
      } else {
        this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
        this.alert.variant = "danger";
        this.showAlert();
      }
    },


    validaCampo() {
      if (this.mentoria.titulo) {
        this.stateValida.titulo = true;
        this.validado = true;

      }
      else if (!this.mentoria.titulo) {
        this.stateValida.titulo = false;
        this.validado = false;
      }
      if (this.mentoria.local) {
        this.stateValida.local = true;
        this.validado = true;

      }
      else if (!this.mentoria.local) {
        this.stateValida.local = false;
        this.validado = false;
      }
      if (this.mentoria.cargaHoraria) {
        this.stateValida.cargaHoraria = true;
        this.validado = true;

      }
      else if (!this.mentoria.cargaHoraria) {
        this.stateValida.cargaHoraria = false;
        this.validado = false;
      }
      if (this.mentoria.quantParticipantes) {
        this.stateValida.quantParticipantes = true;
        this.validado = true;

      }
      else if (!this.mentoria.quantParticipantes) {
        this.stateValida.quantParticipantes = false;
        this.validado = false;
      }
      if (this.mentoria.tipo) {
        this.stateValida.tipo = true;
        this.validado = true;

      }
      else if (!this.mentoria.tipo) {
        this.stateValida.tipo = false;
        this.validado = false;
      }
      if (this.mentoria.inicio) {
        this.stateValida.inicio = true;
        this.validado = true;

      }
      else if (!this.mentoria.inicio) {
        this.stateValida.inicio = false;
        this.validado = false;
      }
      if (this.mentoria.publicoAlvo) {
        this.stateValida.publicoAlvo = true;
        this.validado = true;

      }
      else if (!this.mentoria.publicoAlvo) {
        this.stateValida.publicoAlvo = false;
        this.validado = false;
      }
      if (this.mentoria.equipe) {
        this.stateValida.equipe = true;
        this.validado = true;

      }
      else if (!this.mentoria.equipe) {
        this.stateValida.equipe = false;
        this.validado = false;
      }
      if (this.mentoria.agravos) {
        this.stateValida.agravos = true;
        this.validado = true;

      }
      else if (!this.mentoria.agravos) {
        this.stateValida.agravos = false;
        this.validado = false;
      }
      if (this.mentoria.abrangencia) {
        this.stateValida.abrangencia = true;
        this.validado = true;

      }
      else if (!this.mentoria.abrangencia) {
        this.stateValida.abrangencia = false;
        this.validado = false;
      }

      if (this.mentoria.situacao) {
        this.stateValida.situacao = true;
        this.validado = true;

      }
      else if (!this.mentoria.situacao) {
        this.stateValida.situacao = false;
        this.validado = false;
      }
      if (this.mentoria.modalidade) {
        this.stateValida.modalidade = true;
        this.validado = true;

      }
      else if (!this.mentoria.modalidade) {
        this.stateValida.modalidade = false;
        this.validado = false;
      }
      if (this.mentoria.ras) {
        this.stateValida.ras = true;
        this.validado = true;

      }
      else if (!this.mentoria.ras) {
        this.stateValida.ras = false;
        this.validado = false;
      }

      return this.validado

    },
    async inicializar() {
      await this.verificaLocalStore();
      this.listarRa();
      this.listarModalidade();
      this.listarSituacaoVisita();
      this.listarAgravo();
      this.listarMentoria();
      this.listarAbrangencia();
      this.listarTipoCurso();
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inverterCampoData(data) {
      data = data
        .split("/")
        .reverse()
        .join("/");
      data = data.replaceAll("/", "-")

      return data;
    },
    pegaId(array) {
      var listaId = [];
      for (let i = 0; i < array.length; i++) {
        listaId.push(array[i].id);
      }
      console.log("Lista de Ids", listaId)
      return listaId;
    },

    async salvarMentoria() {
      if (document.getElementById("btn-salvar").textContent == "Alterar") {
        setTimeout(() => {
          this.alterarMentoria();
        }, 200)
      } else {

        this.mentoria.agravos = await this.pegaId(this.agravos);
        this.mentoria.ras = await this.pegaId(this.ras);
        this.mentoria.equipe = await this.pegaId(this.equipe);

        if (this.validaCampo() == true) {

          this.mentoria.idLogado = this.usuarioLogado.id


          mentoriaService
            .salvarMentoria(this.mentoria)
            .then((res) => {
              this.stateValida = {};
              this.alert.mensagem = res.mensagem;
              this.alert.variant = "success";
              this.showAlert();

              this.listarMentoria();

              this.mentoriaClonado = JSON.parse(JSON.stringify(this.mentoria));
              document.getElementById("btn-salvar").textContent = "Alterar"




            })
            .catch((erro) => {
              console.log(erro)
              this.alert.mensagem = "Atenção! Erro ao salvar mentoria.";
              this.alert.variant = "danger";
              this.showAlert();
            });

        } else {
          this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
          this.alert.variant = "danger";
          this.showAlert();
        }
      }
    },
    listarAbrangencia() {
      constanteService
        .listarAbrangencia().then((res) => {
          this.itemAbrangencia = res;
        });
    },
    listarRa() {
      regiaoAdministrativaService.listarTodasRas().then((res) => {
        this.itemRa = res;
        this.itemRa = this.formataObjeto(this.itemRa);

      });
    },
    listarMentoria() {
      mentoriaService.listarTodosMentoria(this.usuarioLogado.id).then((res) => {
        this.itemmentoria = res;

      });
    },
    listarTipoCurso() {
      constanteService.listarTipoCurso().then((res) => {
        this.itemTipo = res;


      });
    },
    listarModalidade() {
      constanteService.listarModalidade().then((res) => {
        this.itemModalidade = res;

      });
    },

    listarAgravo() {
      agravoService
        .listarAgravo().then((res) => {
          this.itemAgravo = res;
          this.itemAgravo = this.formataObjeto(this.itemAgravo);

        });
    },
    listarSituacaoVisita() {
      constanteService
        .listarSituacaoVisita().then((res) => {
          this.itemSituacaomentoria = res;

        });
    },

    verificaLocalStore() {
      this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
      if (localStorage.getItem("usuarioPagina")) {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

      } else {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
      }
      // Carrega o vinculo
      if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
        this.vinculoSelecionado.indexVinculo = 0;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
        localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
      } else { // pega vinculo caso já esteja carregado
        this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
      }
    }
  },

}
</script>

<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 10px;

}
</style>



