<template>
  <div class="container">
    <div class="card">
      <h2 class="card__titulo"> Visita Técnica</h2>
      <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
        <p>{{ alert.mensagem }}</p>
        <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
        </b-progress>
      </b-alert>
      <div class="row">
        <div class=" col-lg-6 ">
          <label for="nome" class="form-label">Objetivo<strong style="color:red">*</strong></label>
          <!--  <input type="text" class="form-control" required id="nome" v-model.trim="infoPessoal.nomeCivil"> -->
          <b-form-input type="text" class="form-control mb-3" required id="nome" v-model="visita.objetivo"
            :state="stateValida.objetivo"></b-form-input>
          <div class="invalid-feedback">Campo Nome Objetivo é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="nomSocial" class="form-label">Especificação<strong style="color:red">*</strong></label>
          <!-- <input type="text" class="form-control" id="nomSocial" v-model.trim="infoPessoal.nomeSocial"> -->
          <b-form-input type="text" class="form-control mb-3" id="nomSocial" v-model.trim="visita.especificacao"
            :state="stateValida.especificacao"></b-form-input>
          <div class="invalid-feedback">Campo Nome Especificação é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="cpf">Finalidade<strong style="color:red">*</strong></label>
          <!-- <input type="text" class="form-control" id="cpf" v-model="infoPessoal.cpf"> -->
          <b-form-textarea type="text" class="form-control mb-3" id="finalidade" v-model="visita.finalidade"
            :state="stateValida.finalidade">
          </b-form-textarea>
          <div class="invalid-feedback">Campo finalidade é obrigatório!</div>
        </div>
        <div class="col-lg-6 ">
          <label for="staticEmail2">Motivação<strong style="color:red">*</strong></label>

          <b-form-textarea type="text" class="form-control mb-3" id="staticEmail2" v-model="visita.motivacao"
            :state="stateValida.motivacao">
          </b-form-textarea>
          <div class="invalid-feedback">Campo Motivação é obrigatório!</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 ">
          <label for="staticEmail2">Ações realizadas<strong style="color:red">*</strong></label>
          <!--  <input type="date" class="form-control" id="staticEmail2" v-model="infoPessoal.nascimento"> -->
          <b-form-textarea type="text" class="form-control mb-3" id="staticEmail2" v-model="visita.acoesRealizadas"
            :state="stateValida.acoesRealizadas">
          </b-form-textarea>
          <div class="invalid-feedback">Campo Ações Realizadas é obrigatório!</div>
        </div>
        <div class="col-lg-6">
          <label for="staticEmail2">Encaminhamento<strong style="color:red">*</strong></label>
          <!--  <input type="date" class="form-control" id="staticEmail2" v-model="infoPessoal.nascimento"> -->
          <b-form-textarea type="text" class="form-control mb-3" id="staticEmail2" v-model="visita.encaminhamentos"
            :state="stateValida.encaminhamentos">
          </b-form-textarea>
          <div class="invalid-feedback">Campo Encaminhamento é obrigatório!</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="local">Local<strong style="color:red">*</strong></label>
          <b-form-input type="text" class="form-control mb-3" required id="nome" v-model="visita.local"
            :state="stateValida.local"></b-form-input>
          <div class="invalid-feedback">Campo Local é obrigatório!</div>
        </div>

        <div class="col-lg-3">
          <label for="staticEmail2">Início<strong style="color:red">*</strong></label>
          <!--  <input type="date" class="form-control" id="staticEmail2" v-model="infoPessoal.nascimento"> -->
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="visita.inicio"
            :state="stateValida.inicio">
          </b-form-input>
          <div class="invalid-feedback">Campo Início é obrigatório!</div>
        </div>
        <div class="col-lg-3">
          <label for="staticEmail2">Término</label>
          <!--  <input type="date" class="form-control" id="staticEmail2" v-model="infoPessoal.nascimento"> -->
          <b-form-input type="date" class="form-control mb-3" id="staticEmail2" v-model="visita.termino">
          </b-form-input>
        </div>
      </div>
      <fieldset class="row">
        <div class="col-lg-3">
          
          <label for="input-live">Agravo<strong style="color:red">*</strong></label>
          <div>
            <treeselect v-model="visita.agravos" :multiple="true" :options="itemAgravo" :state="stateValida.agravos"
              placeholder="Selecione o(s) Agravo(s)" />
          </div>
        </div>
        <div class="col-lg-3">
          <label for="input-live">RA<strong style="color:red">*</strong></label>
          <div>
            <treeselect v-model="visita.ras" :multiple="true" :options="itemRa" :state="stateValida.ras"
              placeholder="Selecione a(s) Ra(s)" />
          </div>
        </div>
        <div class="col-lg-3 ">
          <label for="emailPessoal">Processo SEI</label>
          <input type="text" class="form-control mb-3" id="emailPessoal" v-model="visita.processoSEI">
        </div>
        <div class="col-lg-3 ">
          <label for="curriculo">UrlSEI</label>
          <input type="text" class="form-control mb-3" id="curriculo" v-model="visita.urlSEI">
        </div>

      </fieldset>

      <fieldset class="row">

        <div class="col-lg-3">
          <label for="input-live">Atividade<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.atividade"
            v-on:change="mostraCampo()" v-model="visita.atividade">
            <option value="0"></option>
            <option v-for="atv in itemAtividade" :key="atv.id" :value="atv.id">{{
            atv.nome
            }}
            </option>
          </select>
        </div>
        <div class="col-lg-3 " v-if="mostraOutraAtividade">
          <label for="curriculo">Outras Atividades<strong style="color:red">*</strong></label>
          <input type="text" class="form-control mb-3" id="curriculo" v-model="visita.atividadeOutras"
            :state="stateValida.atividadeOutras">
        </div>
        <div class="invalid-feedback" v-if="mostraOutraAtividade">Campo Atividade Outras é obrigatório!</div>
        <div class="col-lg-3">
          <label for="input-live">Abrangência<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.abrangencia"
            v-model="visita.abrangencia">
            <option value="0"></option>
            <option v-for="abr in itemAbrangencia" :key="abr.id" :value="abr.id">{{
            abr.nome
            }}

            </option>

          </select>
        </div>
        <div class="col-lg-3">
          <label for="input-live">Situação<strong style="color:red">*</strong></label>
          <select class="form-control mb-3 input-select" id="refGerenciaDrop" :state="stateValida.situacao"
            v-model="visita.situacao">
            <option value="0"></option>
            <option v-for="sit in itemSituacaoVisita" :key="sit.id" :value="sit.id">{{
            sit.nome
            }}

            </option>

          </select>
        </div>
        <div class="row">
          <div class="col-lg-12 borda">
            <div class="row">
              <div class="col-lg-5">
                <label for="input-live">Equipe<strong style="color:red">*</strong></label>
                <div>
                  <treeselect v-model="visita.equipe" :multiple="true" :options="equipeVisita"
                    placeholder="Participantes da Equipe" noOptionsText="Nenhum participante na equipe." />
                </div>
              </div>
              <div class="col-lg-4">
                <label for="input-live">Participante<strong style="color:red">*</strong></label>
                <multiselect class="mb-3" id="refEquipe" v-model="equipe" :state="stateValida.equipe"
                  :options="itemEquipe" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Informe Id, Nome ou Cpf do usuário" :show-no-results="true" :hide-selected="true"
                  label="nomeSocial" track-by="nomeSocial" :preselect-first="true" :searchable="true"
                  :loading="isLoading" :internal-search="false" @search-change="buscarUsuario" :clearOnSelect="true" :showNoOptions="false">
                  <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Usuário(s)
                      Selecionado(s)</span></template>
                  <template><span slot="noResult">Nenhum participante encontrado.</span></template>
                </multiselect>
              </div>

              <div class="col-lg-3">
                <div class="container-buttons-salvar">
                  <br />
                  <b-button type="button" @click="adicionarNaEquipe" variant="outline-success" id="btn-add-equipe"> <i
                      class="fa fa-plus"></i> Participante
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="row">
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="validaAtividadeOutras" variant="outline-success" id="btn-salvar">Salvar
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <b-button type="button" @click="limparObjetoVisita" variant="outline-success" id="btn-salvar">Novo
            </b-button>
          </div>
        </div>
        <div class="col-lg-1">
          <div class="container-buttons-salvar">
            <router-link to="/inicio">
              <b-button variant="outline-success">Voltar</b-button>
            </router-link>
          </div>
        </div>
        
        <div class="col-lg-7"></div>

        <div class="col-lg-2" >
          <div class="container-buttons-salvar">
            <router-link to="/relatorioVisitaTecnica">
              <b-button type="button" variant="outline-success" id="btn-relatorio">Gerar Relatório
              </b-button>
            </router-link>
          </div>
        </div>


      </div>
    </div>

    <!-- Tabela de visitas -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <h2 class="card__titulo"> Lista de Visitas</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Objetivo</th>
                <th>Especificação</th>
                <th>Data Início</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="visit in itemVisita" :key="visit.id">

                <td>{{ visit.objetivo }}</td>
                <td>{{ visit.especificacao }}</td>
                <td>{{ visit.inicio }}</td>
                <td>
                  <div>
                    <b-button type="button" variant="outline-success"
                      @click="visita = JSON.parse(JSON.stringify(visit)), editarVisita(visit)">Editar</b-button>
                  </div>
                </td>
              </tr>

            </tbody>

          </table>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import { constanteService } from "@/service/constanteService";
import { visitaService } from "@/service/visitaService";
import { usuarioService } from "@/service/usuarioService";
import { agravoService } from "@/service/agravoService";
import { regiaoAdministrativaService } from "@/service/regiaoAdministrativaService";
import { mask } from "vue-the-mask";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Multiselect from 'vue-multiselect';

export default {
  directives: { mask },
  data() {
    return {
      alert: {
        mensagem: "",
        variant: ""
      },
      dismissCountDown: 0,
      dismissSecs: 10,
      isLoading: false,
      usuarioLogado: {},
      usuarioPagina: {},
      vinculoSelecionado: {},

      
      visita: {
        objetivo: "",
        especificacao: "",
        finalidade: "",
        local: "",
        inicio: "",
        termino: "",
        motivacao: "",
        acoesRealizadas: "",
        encaminhamentos: "",
        equipe: [],
        agravos: [],
        ras: [],
        processoSEI: "",
        urlSEI: "",
        atividade: "",
        atividadeOutras: "",
        abrangencia: "",
        situacao: "",
        idLogado: "",
        
      },
      equipe: [],
      equipeSelecionada: [],
      equipeVisita: [],
      itemEquipe: [],
      itemVisita: [],
      itemRa: [],
      itemSituacaoVisita: [],
      itemAbrangencia: [],
      itemAtividade: [],
      itemAgravo: [],
      mostraOutraAtividade: "",
      idVisitaClonado: "",
      visitaClonada: "",

      stateValida: {
        objetivo: null,
        especificacao: null,
        finalidade: null,
        local: null,
        inicio: null,
        termino: null,
        motivacao: null,
        acoesRealizadas: null,
        encaminhamentos: null,
        equipe: null,
        agravos: null,
        ras: null,
        processoSEI: null,
        urlSEI: null,
        atividade: null,
        atividadeOutras: null,
        abrangencia: null,
        situacao: null,
      },
    }
  },
  components: {
    Multiselect,
    Treeselect
  },
  mounted() {
    this.inicializar();
  },
  methods: {
    adicionarNaEquipe() {
      let participante = {};
      participante.id = this.equipe.id;
      participante.label = this.equipe.nomeSocial;
      console.log("Equipe", participante);
      this.equipeVisita.push(participante); // Adiciona na listagem da equipe
      this.visita.equipe.push(participante.id) //Traz o participante já marcado na listagem da equipe.
      //this.equipeSelecionada.push(participante.id); //Traz o participante já marcado na listagem da equipe.
      this.equipe = [];



    },
    validaAtividadeOutras() {
      if (this.visita.atividade == "OUTROS" && this.visita.atividadeOutras == "") {
        this.alert.mensagem = "Atenção! Campo Atividades Outras é obrigatório.";
        this.alert.variant = "danger";
        this.showAlert();
        this.stateValida.atividade = true;
        this.mostraOutraAtividade = true;

      } else {
        this.salvarVisita()
      }

    },
    limparObjetoVisita() {
      this.alerta = {};
      this.idVisitaClonado = "";

      this.visitaClonada = {}

      setTimeout(() => {
        document.getElementById("btn-salvar").textContent = "Salvar"

      }, 200)

      this.visita = {};

    },
    async buscarUsuario() {
      this.isLoading = true;
      let palavraChave = await document.getElementById("refEquipe").value;
      console.log("campo", palavraChave)
      usuarioService
        .buscarUsuarioSimples(palavraChave, this.usuarioLogado.id).then((res) => {
          this.itemEquipe = [res];
          console.log("Usuario Pesquisado", this.itemEquipe)
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        });
    },
    formataMultiSelect(selecionados, listaTotal) {
      let resultado = [];
      for (let i = 0; i < listaTotal.length; i++) {
        for (let j = 0; j < selecionados.length; j++) {
          if (selecionados[j] == listaTotal[i].id)
            resultado.push(listaTotal[i]);
        }
      }
      console.log("Resultado", resultado)
      return resultado;

    },
    async editarVisita(visita) {
      this.alerta = {};
      this.equipeVisita = [];
      this.visita = JSON.parse(JSON.stringify(visita));
      this.agravos = await this.formataMultiSelect(this.visita.agravos, this.itemAgravo);
      this.ras = await this.formataMultiSelect(this.visita.ras, this.itemRa);

      this.equipeVisita = this.formataObjeto(this.visita.usuarios); //await this.formataMultiSelect(this.visita.equipe, this.visita.usuarios);
      this.equipe = this.visita.equipe;

      document.getElementById("btn-salvar").textContent = "Alterar"

      this.visita.inicio = await this.inverterCampoData(visita.inicio);
      this.visita.termino = await this.inverterCampoData(visita.termino);
console.log("Editar Visita", this.visita)
    },
    async alterarVisita() {

      this.alert = {};
      //this.visita.equipe = await this.pegaId(this.equipe);

      for (let index = 0; index < this.itemVisita.length; index++) {
        if (this.visitaClonada.objetivo === this.itemVisita[index].objetivo) {
          this.idVisitaClonado = this.itemVisita[index].id
          break;
        }
      }
      if (this.idVisitaClonado) {
        this.visita.id = this.idVisitaClonado;
      }

      if (this.validaCampo() == true) {
        this.visita.idLogado = this.usuarioLogado.id;
        console.log("VISTA PARA ALTERAR", this.visita)

        visitaService
          .alterarVisita(this.visita.id, this.visita)
          .then((res) => {

            this.alert.mensagem = res.mensagem;
            this.alert.variant = "success";
            this.showAlert()
            this.listarVisitas();
          })
          .catch(() => {

          });
      } else {
        this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
        this.alert.variant = "danger";
        this.showAlert();
      }
    },
    mostraCampo() {
      if (this.visita.atividade == "OUTROS") {
        this.mostraOutraAtividade = true;
      } else {
        this.mostraOutraAtividade = false;
      }

    },
    validaCampo() {
      if (this.visita.objetivo) {
        this.stateValida.objetivo = true;
        this.validado = true;

      }
      else if (!this.visita.objetivo) {
        this.stateValida.objetivo = false;
        this.validado = false;
      }
      if (this.visita.especificacao) {
        this.stateValida.especificacao = true;
        this.validado = true;

      }
      else if (!this.visita.especificacao) {
        this.stateValida.especificacao = false;
        this.validado = false;
      }
      if (this.visita.finalidade) {
        this.stateValida.finalidade = true;
        this.validado = true;

      }
      else if (!this.visita.finalidade) {
        this.stateValida.finalidade = false;
        this.validado = false;
      }
      if (this.visita.local) {
        this.stateValida.local = true;
        this.validado = true;

      }
      else if (!this.visita.local) {
        this.stateValida.local = false;
        this.validado = false;
      }
      if (this.visita.inicio) {
        this.stateValida.inicio = true;
        this.validado = true;

      }
      else if (!this.visita.inicio) {
        this.stateValida.inicio = false;
        this.validado = false;
      }
      if (this.visita.termino) {
        this.stateValida.termino = true;
        this.validado = true;

      }
      else if (!this.visita.termino) {
        this.stateValida.termino = false;
        this.validado = false;
      }
      if (this.visita.motivacao) {
        this.stateValida.motivacao = true;
        this.validado = true;

      }
      else if (!this.visita.motivacao) {
        this.stateValida.motivacao = false;
        this.validado = false;
      }
      if (this.visita.acoesRealizadas) {
        this.stateValida.acoesRealizadas = true;
        this.validado = true;

      }
      else if (!this.visita.acoesRealizadas) {
        this.stateValida.acoesRealizadas = false;
        this.validado = false;
      }
      if (this.visita.encaminhamentos) {
        this.stateValida.encaminhamentos = true;
        this.validado = true;

      }
      else if (!this.visita.encaminhamentos) {
        this.stateValida.encaminhamentos = false;
        this.validado = false;
      }
      if (this.visita.equipe) {
        this.stateValida.equipe = true;
        this.validado = true;

      }
      else if (!this.visita.equipe) {
        this.stateValida.equipe = false;
        this.validado = false;
      }
      if (this.visita.agravos) {
        this.stateValida.agravos = true;
        this.validado = true;

      }
      else if (!this.visita.agravos) {
        this.stateValida.agravos = false;
        this.validado = false;
      }
      if (this.visita.ras) {
        this.stateValida.ras = true;
        this.validado = true;

      }
      else if (!this.visita.ras) {
        this.stateValida.ras = false;
        this.validado = false;
      }
      if (this.visita.atividade) {
        this.stateValida.atividade = true;
        this.validado = true;

      }
      else if (!this.visita.atividade) {
        this.stateValida.atividade = false;
        this.validado = false;
      }
      if (this.visita.atividadeOutras) {
        this.stateValida.atividadeOutras = true;
        this.validado = true;

      }
      else if (!this.visita.atividadeOutras) {
        this.stateValida.atividadeOutras = false;
        this.validado = false;
      }
      if (this.visita.abrangencia) {
        this.stateValida.abrangencia = true;
        this.validado = true;

      }
      else if (!this.visita.abrangencia) {
        this.stateValida.abrangencia = false;
        this.validado = false;
      }
      if (this.visita.situacao) {
        this.stateValida.situacao = true;
        this.validado = true;

      }
      else if (!this.visita.situacao) {
        this.stateValida.situacao = false;
        this.validado = false;
      }
      return this.validado

    },
    async inicializar() {
      await this.verificaLocalStore();
      this.listarRa();
      this.listarAtividade();
      this.listarAbrangencia();
      this.listarSituacaoVisita();
      this.listarAgravo();
      this.listarVisitas();
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    inverterCampoData(data) {
      data = data
        .split("/")
        .reverse()
        .join("/");
      data = data.replaceAll("/", "-")

      return data;
    },
    pegaId(array) {
      var listaId = [];
      for (let i = 0; i < array.length; i++) {
        listaId.push(array[i].id);
      }
      console.log("Lista de Ids", listaId)
      return listaId;
    },

    async salvarVisita() {
      if (document.getElementById("btn-salvar").textContent == "Alterar") {
        this.alterarVisita();

      } else {

        if (this.validaCampo() == true) {

          this.visita.idLogado = this.usuarioLogado.id
          // this.visita.equipe = [1, 10]
          //this.visita.equipe = await this.pegaId(this.equipe);
          console.log("VISTA PARA SALVAR", this.visita)
          visitaService
            .salvarVisita(this.visita)
            .then((res) => {
              this.stateValida = {};
              this.alert.mensagem = res.mensagem;
              this.alert.variant = "success";
              this.showAlert();

              this.visitaClonada = JSON.parse(JSON.stringify(this.visita));
              document.getElementById("btn-salvar").textContent = "Alterar";
              this.listarVisitas();
            })
            .catch((erro) => {
              console.log(erro)
              this.alert.mensagem = "Atenção! Erro ao salvar Visita.";
              this.alert.variant = "danger";
              this.showAlert();
            });

        } else {
          this.alert.mensagem = "Favor preencher os campos obrigatórios(*)."
          this.alert.variant = "danger";
          this.showAlert();
        }
      }

    },
    listarVisitas() {
      visitaService.listarTodasVisita(this.usuarioLogado.id).then((res) => {
        this.itemVisita = res;
        console.log("Visitas", this.itemVisita)
      });
    },
    listarRa() {
      regiaoAdministrativaService.listarTodasRas().then((res) => {
        this.itemRa = res;
        this.itemRa = this.formataObjeto(this.itemRa);
        console.log("Lista Ra's", this.itemRa)
      });
    },
    listarAbrangencia() {
      constanteService
        .listarAbrangencia().then((res) => {
          this.itemAbrangencia = res;
        });
    },
    formataObjeto(array) {
      var listaIdLabel = [];
      for (let i = 0; i < array.length; i++) {
        let idLabel = {}
        idLabel.id = array[i].id;
        if(array[i].nome) {
          idLabel.label = array[i].nome;
        }
        if(array[i].nomeSocial) {
          idLabel.label = array[i].nomeSocial;
        }
        listaIdLabel.push(idLabel);
      }
      console.log("Lista de Ids e Labels", listaIdLabel)
      return listaIdLabel;
    },

    listarAgravo() {
      agravoService
        .listarAgravo().then((res) => {
          this.itemAgravo = res;
          this.itemAgravo = this.formataObjeto(this.itemAgravo);
          console.log("Lista de Agravos", this.itemAgravo)
        });
    },
    listarSituacaoVisita() {
      constanteService
        .listarSituacaoVisita().then((res) => {
          this.itemSituacaoVisita = res;
        });
    },
    listarAtividade() {
      constanteService
        .listarAtividade().then((res) => {
          this.itemAtividade = res;
        });
    },

    verificaLocalStore() {
      this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
      if (localStorage.getItem("usuarioPagina")) {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

      } else {
        this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
      }
      // Carrega o vinculo
      if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
        this.vinculoSelecionado.indexVinculo = 0;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
        this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
        localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
      } else { // pega vinculo caso já esteja carregado
        this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"));
      }
    }
  },

}
</script>

<style scoped>
.borda {
  border-width: thin;
  border-style: solid;
  border-color: #198754;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}
</style>



