<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Cargo</h2>
            <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3" role="group">

                            <div role="group">
                                <label for="input-live">Origem<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="refOrigemDrop"
                                    :state="stateValida.origem"
                                    v-model="cargo.origem"
                                    v-on:change="validaCampo, listarCarreira(), habilitarCampo()">
                                    <option value="0"></option>
                                    <option v-for="origem in tipoOrigem" :key="origem.id" :value="origem.id">{{
                                            origem.sigla
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo origem é obrigatório!</div>

                        </div>

                        <div class="col-lg-3" role="group">

                            <div role="group">
                                <label for="input-live">Carreira<strong style="color:red">*</strong></label>

                                <select class="form-control mb-3 input-select" id="refCarreiraDrop"
                                    :state="stateValida.carreira"
                                    v-on:change="validaCampo, listarCargo(), habilitarCampo()" v-model="cargo.carreira">
                                    <option value="0"></option>
                                    <option v-for="carreira in tipoCarreira" :key="carreira.id" :value="carreira.id">{{
                                            carreira.sigla
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo Carreira é obrigatório!</div>

                        </div>
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>
                            <b-form-input id="nomeCargo" :state="stateValida.nome" v-on:change="validaCampo" type="text"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Cargo" v-model="cargo.nome" SIZE="60" MAXLENGTH="60"
                                disabled></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="siglaCargo" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla do Cargo" v-model="cargo.sigla" SIZE="20" MAXLENGTH="20"
                                disabled></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>

                    </div>
                    <fieldset class="fieldset ">
                        <div class="row">
                            <div class="btn-group" role="group">
                                <div class="container-buttons-salvar">
                                    <b-button @click="adicionarCargo" variant="outline-success" id="btn-salvar">Salvar
                                    </b-button>
                                </div>

                                &nbsp;
                                <div class="container-buttons-salvar">

                                    <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoCargo()">
                                        Novo
                                    </b-button>

                                </div>
                                &nbsp;
                                <div class="container-buttons-salvar">
                                    <router-link to="/inicio">
                                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                    </fieldset>



                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas dos Cargos</h2>



                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>

                          
                            <tr v-for="ca in itemsCargo" :key="ca.id">

                                <td>{{ ca.nome }}</td>
                                <td>{{ ca.sigla }}</td>



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="cargo = JSON.parse(JSON.stringify(ca)), editarCargo()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { cargoService } from "@/service/cargoService";
import { carreiraService } from "@/service/carreiraService";
import { origemService } from "@/service/origemService";

export default {
    data() {
        return {
            infoCargo: {},
            infoCarreira: {},
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            cargoErro: false,
            stateValida: {
                nome: null,
                sigla: null,
                carreira: "",
                origem: "",
            },
            listaCargoAuxiliar: [],

            cargo: {
                nome: "",
                sigla: "",
                carreira: "",
                origem:"",
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            cargoClonado: {},
            idCargoClonado: "",
            stateNome: null,
            stateSigla: null,
            stateCarreira: null,
            // listaDiretorias: [],


            tipoCarreira: [],
            itemsCargo: [],
            tipoOrigem: [],
            validado:"",

        }
    },
    mounted() {
        this.inicialiazar();

    },
    methods: {
        async inicialiazar() {
            await this.verificaLocalStore();

            
            this.listarOrigem();
            this.alerta = {};

        },
        listarOrigem() {
            origemService
                .listarOrigem().then((res) => {
                    this.tipoOrigem = res;


                });
        },
        limparObjetoCargo() {
            document.getElementById('nomeCargo').disabled = true;
            document.getElementById('siglaCargo').disabled = true;
            document.getElementById("refOrigemDrop").value = "";
            this.alerta = {};

            this.cargoClonado = {};
            this.idCargoClonado = "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
            }, 200)
            this.cargo = {};
        },
        alterarCargo() {
            this.alert = {};

            for (let index = 0; index < this.itemsCargo.length; index++) {

                if (this.cargoClonado.nome === this.itemsCargo[index].nome) {
                    this.idCargoClonado = this.itemsCargo[index].id
                    console.log(this.idCargoClonado)
                    break;
                }


            }


            if (this.idCargoClonado) {
                this.cargo.id = this.idCargoClonado;

            }


            if (this.validaCampo() == true) {
                this.cargo.idLogado = this.usuarioLogado.id
                cargoService
                    .alterarCargo(this.cargo.id, this.cargo)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                        this.alert.variant = "success";
                        this.showAlert()

                        this.listarCargo();
                    })
                    .catch(() => {
                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },


        async buscaCargo(idCargo) {

            await cargoService
                .pegaCargo(idCargo)
                .then((res) => {

                    this.infoCargo = res;



                })
                .catch(() => {

                });
        },
        async buscaCarreira(idCarreira) {

            await carreiraService
                .pegaCarreira(idCarreira)
                .then((res) => {

                    this.infoCarreira = res;


                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        editarCargo() {
            this.alerta = {};
            this.cargoClonado = {};
            this.idCargoClonado = "";
            document.getElementById("btn-salvar").textContent = "Alterar"
            // document.getElementById("btn-voltar").textContent = "Cancelar"

        },

        validaCampo() {

            if (this.cargo.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.cargo.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.cargo.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;

            }
            else if (!this.cargo.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
            if (this.cargo.carreira) {
                this.stateValida.carreira = true;
                this.validado =  true;

            }
            else if (!this.cargo.carreira) {
                this.stateValida.carreira = false;
                this.validado =  false;
            }
            if (this.cargo.origem) {
                this.stateValida.origem = true;
                this.validado =  true;

            }
            else if (!this.cargo.origem) {
                this.stateValida.origem = false;
                this.validado =  false;
            }
            return this.validado


        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },


        adicionarCargo() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarCargo();
            } else {
                if (this.validaCampo() == true) {
                    this.cargo.idLogado = this.usuarioLogado.id


                    cargoService
                        .salvarCargo(this.cargo)
                        .then((res) => {


                            this.alert.mensagem = res.mensagem;
                            this.alert.variant = "success";
                            this.showAlert()

                            this.cargoClonado = JSON.parse(JSON.stringify(this.cargo));
                            document.getElementById("btn-salvar").textContent = "Alterar"
                            this.listarCargo(this.cargo.carreira);
                            this.listarCarreira();

                        })
                        .catch(() => {

                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }

        },
        listarCarreira() {
            this.tipoCarreira = {}
            let idOrigem = document.getElementById("refOrigemDrop").value;
            carreiraService
                .listarCarreira(idOrigem).then((res) => {
                    this.tipoCarreira = res;

                    if (res.length > 0) {
                        this.tipoCarreira = res;

                    } else {
                        this.tipoCarreira = {}
                    }


                });
        },
        async listarCargo(idCarreira) {

            if (!idCarreira) {
                idCarreira = document.getElementById("refCarreiraDrop").value

                if (idCarreira != 0) {
                    await cargoService
                        .listarCargo(idCarreira).then((res) => {


                            if (res.length > 0) {
                                this.itemsCargo = res;

                            } else {
                                this.itemsCargo = {}
                            }



                        }).catch((erro) => {
                            console.log(erro)
                            this.itemsCargo = {}


                        });

                } else {
                    setTimeout(() => {
                        this.itemsCargo = {};
                        this.cargo.nome = "";
                        this.cargo.sigla = "";
                        document.getElementById('nomeCargo').disabled = true;
                        document.getElementById('siglaCargo').disabled = true;

                    }, 200)
                }

            } else {
                await cargoService
                    .listarCargo(idCarreira).then((res) => {

                        this.itemsCargo = res;
                        idCarreira = "";

                    }).catch((erro) => {
                        console.log(erro)
                    });
            }

        },
        habilitarCampo() {
            let comboOrigem = document.getElementById("refOrigemDrop").value;
            if (this.cargo.carreira && comboOrigem) {
                document.getElementById('nomeCargo').disabled = false;
                document.getElementById('siglaCargo').disabled = false;
                this.cargo.nome = "";
                this.cargo.sigla = "";
                document.getElementById("btn-salvar").textContent = "Salvar"

            } else {
                this.cargo.nome = "";
                this.cargo.sigla = "";
                this.itemsCargo = {};
                document.getElementById('nomeCargo').disabled = true;
                document.getElementById('siglaCargo').disabled = true;
            }

        },

    },


}

</script>
<style scoped>

</style>
