import axios from "axios";

export const preCadastroService = {
  salvarPreCadastro(preCadastro) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preCadastro`,
          preCadastro,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarPreCadastro(id, preCadastro) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preCadastro/${id}`,
          preCadastro,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarPreCadastro(idLogado,idDiretoria, idGerencia, idNucleo, idSetor, situacao) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preCadastro/listar?idLogado=${idLogado}&diretoria=${idDiretoria}&gerencia=${idGerencia}&nucleo=${idNucleo}&setor=${idSetor}&situacao=${situacao}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pegaPreCadastro(idPreCadastro){
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/preCadastro/${idPreCadastro}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
