import axios from "axios";

export const praticasService = {
  salvarPraticas(praticas) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/praticas`,
          praticas,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarPraticas(id, praticas) {
    return new Promise((resolve, reject) => {
     

      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/praticas/${id}`,
          praticas,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarPraticas(usuario) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/praticas/listar/${usuario}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, 
 
};
