import axios from "axios";

export const carreiraService = {
  salvarCarreira(carreira) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/carreira`, carreira,)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarCarreira(id, carreira) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/carreira/${id}`,
          carreira,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarCarreira(idOrigem) {
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/carreira/listar/${idOrigem}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pegaCarreira(idCarreira){
    return new Promise((resolve, reject) => {
      
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/carreira/${idCarreira}`
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
