import axios from "axios";

export const donsArtisticosService = {
  salvarDonsArtisticos(donsArtisticos) {
    return new Promise((resolve, reject) => {
     
      axios
        .post(`${process.env.VUE_APP_URL_BASE_BACK_END}/donsArtisticos`, donsArtisticos,)
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarDonsArtisticos(id, donsArtisticos) {
    return new Promise((resolve, reject) => {
     

      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/donsArtisticos/${id}`,
          donsArtisticos,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarDonsArtisticos(usuario) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/donsArtisticos/listar/${usuario}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, 
 
};
