<template>
    <div class="container">
        <div class="card">
            <h2 class="card__titulo"> Área Técnica</h2>
          <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
              
                <div class="col-lg-12">

                    <div class="row">
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Gerência</label>
                            <select class="form-control mb-3 input-select" id="selectGerencia"
                                 v-model="setor.gerencia"
                                v-on:change="validaCampo, pegaValorCampo(), listarSetor(), limparCampos(), habilitarCampo()">
                                <option value="0"></option>
                                <option :selected="gerencia.id == setor.gerencia" v-for="gerencia in itemsGerencia"
                                    :key="gerencia.id" :value="gerencia.id">{{
                                            gerencia.sigla
                                    }}</option>
                            </select>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Núcleo</label>
                            <select class="form-control mb-3 input-select" id="selectNucleo" 
                                v-model="setor.nucleo"
                                v-on:change="validaCampo, pegaValorCampo(), limparCampos(), listarSetor(), habilitaBotaoSalvar()">
                                <option value="0"></option>
                                <option v-for="n in itemsNucleo" :key="n.id" :value="n.id">{{
                                        n.sigla
                                }}</option>
                            </select>
                        </div>
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>
                            <b-form-input id="inputNome" :state="stateValida.nome" v-on:change="validaCampo" type="text"
                                aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome do Setor" trim v-model="setor.nome" SIZE="60" MAXLENGTH = "60"></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-3" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="inputSigla" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla do Setor" trim v-model="setor.sigla" SIZE="20" MAXLENGTH = "20"></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>
                    </div>
                </div>
            </div>
            <fieldset class="fieldset " >   
                <div class="row">
                    <div class="btn-group" role="group">


                        <div class="container-buttons-salvar">
                            <b-button @click="adicionarSetor" variant="outline-success" id="btn-salvar">Salvar</b-button>
                        </div>

                        &nbsp;
                        <div class="container-buttons-salvar">

                            <b-button variant="outline-success" @click="limparObjetoSetor()">Novo</b-button>

                        </div>
                        &nbsp;
                        <div class="container-buttons-salvar">
                            <router-link to="/inicio">
                                <b-button variant="outline-success">Voltar</b-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </fieldset>
            
        </div>
        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas das Área Técnica</h2>
                    
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr v-for="s in itemsSetor" :key="s.id">
                                <td>{{ s.nome }}</td>
                                <td>{{ s.sigla }}</td>

                                <td>
                                    <div>
                                      
                                        <b-button type="button" variant="outline-success" 
                                        @click="setor = JSON.parse(JSON.stringify(s)), editarSetor(s)">

                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { nucleoService } from "@/service/nucleoService";
import { gerenciaService } from "@/service/gerenciaService";
import { setorService } from "@/service/setorService";

export default {
    data() {
        return {

            infoNucleo: {},
            infoGerencia: {},
            infoSetor: {},
            usuarioPagina: {},
            usuarioLogado: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            stateValida: {
                nome: null,
                sigla: null,
                gerencia: "",
                nucleo: "",
            },
            setor: {
                nome: "",
                sigla: "",
                gerencia: "",
                nucleo: "",
                diretoria:"",
                idLogado: "",
            },
            alert: {
                mensagem: "",
                variant: ""
            },
            setorClonado: {},
            idSetorClonado: "",

            novoIdGerencia: 0,
            novoIdNucleo: 0,

            stateNome: null,
            stateSigla: null,
            listaDiretorias: [],

            mensagem: "",
            idGerencia: "",

            fields: ['nome', 'sigla'],

            itemsSetor: [],

            itemsGerencia: [],
            itemsNucleo: [],
            valorEditarSetor: "",
            validado:"",
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            await this.verificaLocalStore();
            this.listarGerencia();
            // this.listarNucleo();
            this.buscarValorParametro();
        },
        habilitaBotaoSalvar(){
            this.setor.nome = "";
            this.setor.sigla = "";
                
            document.getElementById("btn-salvar").textContent = "Salvar"
        },
        habilitarCampo() {
            let comboGerencia = document.getElementById('selectGerencia').value;
            if (comboGerencia == 0) {
                document.getElementById('selectNucleo').value = 0;
                document.getElementById('selectNucleo').disabled = true;
               
                 document.getElementById("btn-salvar").textContent = "Salvar"
                 this.setor.nome = "";
                this.setor.sigla = "";
                this.listarSetor();


               


            } else {
               
                document.getElementById('selectNucleo').disabled = false;
                document.getElementById('inputNome').disabled = false;
                document.getElementById('inputSigla').disabled = false;
                this.setor.nome = "";
                this.setor.sigla = "";
                
                document.getElementById("btn-salvar").textContent = "Salvar"
            }

        },
        limparObjetoSetor() {
        this.setorClonado= {};
        this.idSetorClonado= "";

            setTimeout(() => {
                this.listarSetor();

            }, 200)

            this.setor = {};
            this.alerta = {};

            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
                
                document.getElementById("selectNucleo").disabled = true;

            }, 200)


        },
        limparCampos() {

            if (!this.valorEditarSetor) {
                document.getElementById("inputNome").value = "";
                document.getElementById("inputSigla").value = "";
                document.getElementById('selectNucleo').disabled = false;

            }

        },
        editarSetor(s) {
            
        this.setorClonado= {};
        this.idSetorClonado= "";

        this.setor.gerencia = s.lotacao.gerencia;
        this.setor.nucleo = s.lotacao.nucleo
        this.alerta = {};

           

            document.getElementById('selectNucleo').disabled = false;
            document.getElementById('inputNome').disabled = false;
            document.getElementById('inputSigla').disabled = false;
            document.getElementById("btn-salvar").textContent = "Alterar"

            this.valorEditarSetor = true;
            

        },
        alterarSetor() {
           this.alert = {};
        
         for (let index = 0; index < this.itemsSetor.length; index++) {

            if(this.setorClonado.nome === this.itemsSetor[index].nome){
                this.idSetorClonado = this.itemsSetor[index].id
            console.log(this.idSetorClonado)
                break;
            }
               
                
          }

        
        if(this.idSetorClonado){ 
            this.setor.id = this.idSetorClonado;
            
        } 

            if (this.validaCampo() == true) {
                console.log("setor é", this.setor)

                this.setor.diretoria = this.usuarioPagina.vinculos[0].setor.lotacao.diretoria;

                this.setor.idLogado = this.usuarioLogado.id
                 


                setorService
                    .alterarSetor(this.setor.id, this.setor)
                    .then((res) => {

                       
                     this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()

                       


                        this.listarSetor();
                    })
                    .catch(() => {

                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },
        async buscarValorParametro() {
            document.getElementById('selectNucleo').disabled = true;
            
            this.setor.nome = "";
            this.setor.sigla = "";


            await this.buscaNucleo(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.nucleo)
            await this.buscaGerencia(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.gerencia)
            await this.buscaSetor(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.id)


            

            let idDiretoria = 1;

            this.listarNucleo(this.usuarioPagina.vinculos[this.vinculoSelecionado.indexVinculo].setor.lotacao.gerencia)


            let idGerencia = this.infoGerencia.id;
            let idNucleo = this.infoNucleo.id

            this.listarSetor(idDiretoria, idGerencia, idNucleo)



        },
        async buscaNucleo(idNucleo) {

            await nucleoService
                .pegaNucleo(idNucleo)
                .then((res) => {

                    this.infoNucleo = res;
                    console.log("objeto é ", this.infoNucleo)


                })
                .catch(() => {

                });
        },
        async buscaGerencia(idGerencia) {

            await gerenciaService
                .pegaGerencia(idGerencia)
                .then((res) => {

                    this.infoGerencia = res;
                    console.log("objeto da gerencia é ", this.infoGerencia)

                })
                .catch(() => {

                });
        },
        async buscaSetor(idSetor) {

            await setorService
                .pegaSetor(idSetor)
                .then((res) => {

                    this.infoSetor = res;
                    console.log("objeto da do setor é ", this.infoSetor)

                })
                .catch(() => {

                });
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        validaCampo() {

            if (this.setor.nome) {
                this.stateValida.nome = true;
                this.validado =  true;
            }
            else if (!this.setor.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.setor.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;
            }
            else if (!this.setor.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
          
            return  this.validado;
        },
        pegaValorCampo() {
            this.novoIdGerencia = document.getElementById("selectGerencia").value;
            console.log("NOVO ID GERENCIA", this.novoIdGerencia)
            this.listarNucleo(this.novoIdGerencia);
            this.novoIdNucleo = document.getElementById("selectNucleo").value;


        },
        adicionarSetor() {
            this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarSetor();
            } else {
                if (this.validaCampo() == true) {
                    this.setor.diretoria = this.usuarioPagina.vinculos[0].setor.lotacao.diretoria;
                    this.setor.gerencia = parseInt(this.novoIdGerencia);
                    this.setor.nucleo = parseInt(this.novoIdNucleo);
                    this.setor.idLogado = this.usuarioLogado.id
                    let idLogado = this.usuarioLogado.id
                    console.log("PASSEI POR AQUI", this.setor)
                     if (!this.setor.nucleo) {
                            this.setor.nucleo = 0;
                    }

                    setorService
                        .salvarSetor(this.setor, idLogado)
                        .then((res) => {
                            console.log(res);
                            this.setorClonado = JSON.parse(JSON.stringify(this.setor));
                            
                            this.listarSetor();
                            document.getElementById("btn-salvar").textContent = "Alterar";

                            this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                        })
                        .catch((erro) => {
                            console.log(erro);
                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }
        },
        listarGerencia() {
            let diretoria = 1;
            // como só existe uma diretoria, colocamos o código de forma manual -> o código 1 representa a divep
            gerenciaService
                .listarGerencia(diretoria).then((res) => {
                    this.itemsGerencia = res;
                    console.log("Gerencia é", this.itemsGerencia)
                });
        },
        listarNucleo(idGerencia) {
            // let idGerencia = this.idGerencia;  //document.getElementById("idGerencia").value;
            nucleoService
                .listarNucleo(idGerencia).then((res) => {
                    this.itemsNucleo = res;
                    console.log("nucleo", this.itemsNucleo)
                }).catch(() => {
                    this.itemsNucleo = {};

                });
        },
        listarSetor(idDiretoria, idGerencia, idNucleo) {


            idGerencia = document.getElementById("selectGerencia").value;
            idNucleo = document.getElementById("selectNucleo").value;

            idDiretoria = this.usuarioPagina.vinculos[0].setor.lotacao.diretoria;
            if (!idGerencia) {
                idGerencia = 0;

            }
            if (!idNucleo) {
                idNucleo = 0;
            }

            setorService
                .listarSetor(idDiretoria, idGerencia, idNucleo).then((res) => {


                    if (res.length < 0) {
                        this.itemsSetor = {};

                    } else {
                        this.itemsSetor = res;
                    }
                }).catch((erro) => {
                    if (erro.response && erro.response.status == 404) {
                        this.itemsSetor = {};
                    }

                });
        },
    },
}

</script>
 <style scoped>



</style> 
