<template>

  <div class="container-login col-md-12">
    <div class="card-login col-md-3 mt-5">
      <div class="row">
      
        <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
        <div class="col-12">
          <h1 class="h3 mb-3 font-weight-normal">Login SIGEPI</h1>

          <label for="inputEmail" class="sr-only">CPF<strong style="color:red">*</strong></label>
          <input  v-mask="'###.###.###-##'" class="form-control"
            placeholder="Informe o CPF" required="" autofocus="" v-model="cpf">
          <label for="inputPassword" class="sr-only">Senha<strong style="color:red">*</strong></label>
          <input type="password" id="inputPassword" class="form-control" placeholder="Informe a Senha" required=""
            v-model="senha">
          <div class="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me"> Lembre-me
            </label>
          </div>
          <div id="alerta-mensagem" class="d-none alert alert-warning alert-dismissible fade show" role="alert">
            <strong>Falha de Login!</strong> Usuário ou senha estão incorretos.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <button class="btn btn-lg btn-primary btn-block" type="button" @click="RetiraMascara()">Entrar</button><br><br>
           <router-link
                                        :to="{ name: 'PreCadastroServidor'}">
                                        Primeiro acesso</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Header from '@/partials/template/Header.vue';
import { loginService } from "@/service/loginService";

import { mask } from "vue-the-mask";

export default {
  directives: { mask },

  beforeUpdate() {
    //this.$store.commit('setLayout', { Header: true })
  },

  data() {
    return {
      //valor: "<Header /> ",
      usuarioLogado: {},
      cpf: "",
      senha: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alert: {
                mensagem: "",
                variant: ""
            },
    }
  },
  computed: {

  },
  component: {
    Header
  },
  mounted() {
    localStorage.clear();
  },
  methods: {
    ountDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    validar() {
      this.alert = {};
      loginService.loginAcesso(this.cpf, this.senha).then((res) => {
        this.usuarioLogado = res;        

        localStorage.setItem("usuarioLogado", JSON.stringify(this.usuarioLogado))
        this.$router.push({
          path: "inicio",
        });
        console.log("USUARIO", this.usuarioLogado);
      }).catch((error) => {
        console.log("qual erro",error.response.data.mensagem),
        this.alert.mensagem = error.response.data.mensagem;
        this.alert.variant = "danger";
        this.showAlert()

      }).finally(() => { });
    },
    RetiraMascara() {
      let cpf = this.cpf;
      if (cpf.length == 14) {
        cpf = cpf.charAt(0) + cpf.charAt(1) + cpf.charAt(2) +
          cpf.charAt(4) + cpf.charAt(5) + cpf.charAt(6) +
          cpf.charAt(8) + cpf.charAt(9) + cpf.charAt(10) +
          cpf.charAt(12) + cpf.charAt(13);

        this.cpf = cpf;

        this.validar();
      } else {
        this.showAlert();
      }
    }

  }
}
</script>
