import axios from "axios";

export const experienciaProfissionalService = {
  salvarExperienciaProfissional(experiencia) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/experiencia`,
          experiencia,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarExperienciaProfissionalService(id, experiencia) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/experiencia/${id}`,
          experiencia,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarExperienciaProfissional(idexperiencia) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/experiencia/listar/${idexperiencia}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
 
};
