import { render, staticRenderFns } from "./InstituicaoEnsino.vue?vue&type=template&id=3d44d07c&scoped=true&"
import script from "./InstituicaoEnsino.vue?vue&type=script&lang=js&"
export * from "./InstituicaoEnsino.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d44d07c",
  null
  
)

export default component.exports