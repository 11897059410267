<template>
    <div class="container">

        <div class="card">
            <h2 class="card__titulo"> Carreira</h2>
          <b-alert :show="dismissCountDown" :variant="alert.variant" @dismissed="dismissCountDown = 0">
                <p>{{ alert.mensagem }}</p>
                <b-progress :variant="alert.variant" :max="dismissSecs" :value="dismissCountDown" height="4px">
                </b-progress>
            </b-alert>
            <div class="row">
               
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4" role="group">

                            <div role="group">
                                <label for="input-live">Origem<strong style="color:red">*</strong></label>
                                
                                <select class="form-control mb-3 input-select" id="refOrigemDrop"
                                    :state="stateValida.origem"
                                    v-on:change="validaCampo, listarCarreira(), habilitarCampo()"
                                    v-model="carreira.origem">
                                    <option value="0"></option>
                                    <option v-for="origem in tipoOrigem" :key="origem.id" :value="origem.id">{{
                                            origem.sigla
                                    }}

                                    </option>

                                </select>
                            </div>
                            <div class="invalid-feedback">Campo origem é obrigatório!</div>

                        </div>
                        <div class="col-lg-4" role="group">
                            <label for="input-live">Nome<strong style="color:red">*</strong></label>
                            <b-form-input id="nomeCarreira" :state="stateValida.nome" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe o nome da Carreira" v-model="carreira.nome" SIZE="60" MAXLENGTH = "60" disabled></b-form-input>
                            <div class="invalid-feedback">Campo nome é obrigatório!</div>
                        </div>
                        <div class="col-lg-4" role="group">
                            <label for="input-live">Sigla<strong style="color:red">*</strong></label>
                            <b-form-input id="siglaCarreira" :state="stateValida.sigla" v-on:change="validaCampo"
                                type="text" aria-describedby="input-live-help input-live-feedback"
                                placeholder="Informe a sigla da Carreira" v-model="carreira.sigla" SIZE="20" MAXLENGTH = "20" disabled></b-form-input>
                            <div class="invalid-feedback">Campo sigla é obrigatório!</div>
                        </div>

                    </div>
                    <fieldset class="fieldset " >  
                        <div class="row">
                        <div class="btn-group" role="group">
                            <div class="container-buttons-salvar">
                                <b-button @click="adicionarCarreira" variant="outline-success" id="btn-salvar">Salvar
                                </b-button>
                            </div>

                            &nbsp;
                            <div class="container-buttons-salvar">

                                <b-button variant="outline-success" id="btn-voltar" @click="limparObjetoCarreira()">Novo
                                </b-button>

                            </div>
                            &nbsp;
                            <div class="container-buttons-salvar">
                                <router-link to="/inicio">
                                    <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                                </router-link>
                            </div>
                        </div>

                    </div>
                    </fieldset>

                    

                </div>
            </div>
        </div>


        <!-- Tabela de ferias -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <h2 class="card__titulo"> Listas das Carreiras</h2>

                   

                    <table class="table table-striped">

                        <thead>
                            <tr>

                                <th>Nome</th>
                                <th>Sigla</th>
                                <th>Ação</th>


                            </tr>
                        </thead>
                        <tbody>

                            
                            <tr v-for="c in itemsCarreira" :key="c.id">

                                <td>{{ c.nome }}</td>
                                <td>{{ c.sigla }}</td>



                                <td>
                                    <div>
                                        <b-button type="button" variant="outline-success"
                                            @click="carreira = JSON.parse(JSON.stringify(c)), editarCarreira()">
                                            Editar</b-button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { carreiraService } from "@/service/carreiraService";
import { origemService } from "@/service/origemService";

export default {
    data() {
        return {
            infoCarreira: {},
            infoOrigem: {},
            usuarioLogado: {},
            usuarioPagina: {},
            vinculoSelecionado: {},
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            carreiraErro: false,
            stateValida: {
                nome: null,
                sigla: null,
                origem: "",
            },
            listaCarreiraAuxiliar: [],

            carreira: {
                nome: "",
                sigla: "",
                origem: "",
                idLogado: "",
            },
                alert: {
                mensagem: "",
                variant: ""
            },
            carreiraClonado: {},
            idCarreiraClonado: "",
            stateNome: null,
            stateSigla: null,
            stateOrigem: null,
            listaDiretorias: [],            
            tipoOrigem: [],
            itemsCarreira: [],
            validado:"",

        }
    },
    mounted() {
        this.inicialiazar();

    },
    methods: {
        async inicialiazar() {
            await this.verificaLocalStore();

            this.listarCarreira()
            this.listarOrigem();
            this.alerta = {};
            
        },
        limparObjetoCarreira() {
        this.alerta = {};
       
        this.carreiraClonado= {};
        this.idCarreiraClonado= "";
            setTimeout(() => {
                document.getElementById("btn-salvar").textContent = "Salvar"
            }, 200)
            this.carreira = {};
        },
        alterarCarreira() {       
            this.alert = {}; 

         for (let index = 0; index < this.itemsCarreira.length; index++) {

            if(this.carreiraClonado.nome === this.itemsCarreira[index].nome){
                this.idCarreiraClonado = this.itemsCarreira[index].id
            console.log(this.idCarreiraClonado)
                break;
            }
               
                
          }

        
        if(this.idCarreiraClonado){ 
            this.carreira.id = this.idCarreiraClonado;
            
        } 


            if (this.validaCampo() == true) {
                 this.carreira.idLogado = this.usuarioLogado.id
                carreiraService
                    .alterarCarreira(this.carreira.id, this.carreira)
                    .then((res) => {

                        this.alert.mensagem = res.mensagem;
                     this.alert.variant = "success";
                     this.showAlert()
                       
                        this.listarCarreira();
                    })
                    .catch(() => {
                    });
            }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
        },

        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
            if (localStorage.getItem("usuarioPagina")) {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioPagina"));

            } else {
                this.usuarioPagina = JSON.parse(localStorage.getItem("usuarioLogado"));
            }
            // Carrega o vinculo
            if (!localStorage.getItem("vinculoSelecionado")) { // Carrega o vinculo na primeira vez
                this.vinculoSelecionado.indexVinculo = 0;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].id;
                this.vinculoSelecionado.idVinculo = this.usuarioPagina.vinculos[0].matricula;
                localStorage.setItem("vinculoSelecionado", JSON.stringify(this.vinculoSelecionado));
            } else {
                this.vinculoSelecionado = JSON.parse(localStorage.getItem("vinculoSelecionado"))
            }
        },
       
        async buscaCarreira(idCarreira) {

            await carreiraService
                .pegaCarreira(idCarreira)
                .then((res) => {

                    this.infoCarreira = res;
                    console.log("objeto é ", this.infoCarreira)


                })
                .catch(() => {

                });
        },
        async buscaOrigem(idOrigem) {

            await origemService
                .pegaOrigem(idOrigem)
                .then((res) => {

                    this.infoOrigem = res;
                    

                })
                .catch(() => {

                });
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        editarCarreira() {
        this.alerta = {};
        this.carreiraClonado= {};
        this.idCarreiraClonado= "";
            document.getElementById("btn-salvar").textContent = "Alterar"
            

        },

        validaCampo() {

            if (this.carreira.nome) {
                this.stateValida.nome = true;
                this.validado =  true;

            }
            else if (!this.carreira.nome) {
                this.stateValida.nome = false;
                this.validado =  false;
            }
            if (this.carreira.sigla) {
                this.stateValida.sigla = true;
                this.validado =  true;

            }
            else if (!this.carreira.sigla) {
                this.stateValida.sigla = false;
                this.validado =  false;
            }
            if (this.carreira.origem) {
                this.stateValida.origem = true;
                this.validado =  true;

            }
            else if (!this.carreira.origem) {
                this.stateValida.origem = false;
                this.validado =  false;
            }
            return this.validado


        },

        toDDMMYYYY(strData) {
            let dt = strData.split("-");
            return dt[2] + "/" + dt[1] + "/" + dt[0];
        },
        parseDataBr(dateString) {
            let dateParser = /(\d{2})\/(\d{2})\/(\d{4})/;
            var match = dateString.match(dateParser);
            var date = new Date(match[3], // year
                match[2] - 1, // monthIndex
                match[1]); // day
            return date;
        },
        formatarObjetoData(d) {
            if (!d) return d
            let data = new Date(d)
            if (!(data instanceof Date) || isNaN(data.valueOf()))
                return d

            return data.toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo" })
        },
       
        adicionarCarreira() {    
      
        this.alert = {};

            if (document.getElementById("btn-salvar").textContent == "Alterar") {
                this.alterarCarreira();
            } else {
                if (this.validaCampo() == true) {
                    this.carreira.idLogado = this.usuarioLogado.id


                    carreiraService
                        .salvarCarreira(this.carreira)
                        .then((res) => {

                            
                            this.alert.mensagem = res.mensagem;
                             this.alert.variant = "success";
                             this.showAlert()

                            this.carreiraClonado = JSON.parse(JSON.stringify(this.carreira));                           
                            document.getElementById("btn-salvar").textContent = "Alterar"
                            this.listarCarreira(this.carreira.origem);
                            this.listarOrigem();

                        })
                        .catch(() => {

                        });
                }else {
              this.alert.mensagem = "Favor preencher os campos obrigatórios(*)." 
              this.alert.variant = "danger";
              this.showAlert();
            }
            }

        },
        listarOrigem() {            
            origemService
                .listarOrigem().then((res) => {
                    this.tipoOrigem = res;
                                    

                });
        },
        async listarCarreira(idOrigem) {
                     
            if (!idOrigem) {
                idOrigem = document.getElementById("refOrigemDrop").value
               
                if (idOrigem != 0) {
                    await carreiraService
                        .listarCarreira(idOrigem).then((res) => {
                            
                        
                        if(res.length > 0) {
                             this.itemsCarreira = res;

                        } else {
                            this.itemsCarreira = {}
                        }
                           


                        }).catch((erro) => {
                            console.log(erro)
                             this.itemsCarreira = {}


                        });

                } else {
                    setTimeout(() => {
                        this.itemsCarreira = {};
                        this.carreira.nome = "";
                        this.carreira.sigla = "";
                        document.getElementById('nomeCarreira').disabled = true;
                        document.getElementById('siglaCarreira').disabled = true;

                    }, 200)
                }

            } else {
                await carreiraService
                    .listarCarreira(idOrigem).then((res) => {
                        
                        this.itemsCarreira = res;
                       // idOrigem = "";

                    }).catch((erro) => {
                        console.log(erro)
                    });
            }

        },
        habilitarCampo() {
            if (this.carreira.origem) {
                document.getElementById('nomeCarreira').disabled = false;
                document.getElementById('siglaCarreira').disabled = false;
                 this.carreira.nome = "";
                this.carreira.sigla = "";
                document.getElementById("btn-salvar").textContent = "Salvar"

            } else {
                this.carreira.nome = "";
                this.carreira.sigla = "";
                this.itemsCarreira = {};
                document.getElementById('nomeCarreira').disabled = true;
                document.getElementById('siglaCarreira').disabled = true;
            }

        },

    },


}

</script>
<style scoped>

</style>
