<template>
    <div class="container- col-md-12">
        <div class="card-login col-md-3 mt-5">
            <div class="row">
                <div class="ccol">
                    <h1 class="h3 mb-3 font-weight-normal">Alterar Foto</h1>

                    
                    <img id="photo-carregada" class="perfil" type="file" width="150" height="150" alt="logo maujor"
                        accept="image/png, image/jpeg">
                </div>

            </div>
            <div class="row">

                <div class="container-buttons-salvar">
                    <b-button variant="outline-success" id="select-image">
                        <input type="file" name="file" multiple ref="files" id="photo-file">
                        Carregar Foto
                    </b-button>

                    <b-button variant="outline-success" id="btn-salvar" @click="salvarFoto">Salvar</b-button>
                    <router-link to="/inicio">
                        <b-button variant="outline-success" id="btn-voltar">Voltar</b-button>
                    </router-link>
                </div>


            </div>
        </div>
    </div>
</template>
  
  <script>

import { usuarioService } from "@/service/usuarioService";
export default {
    data() {
        return {
            usuarioLogado: {},
        }
    },
    mounted() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            await this.verificaLocalStore()
            await this.ativarCarregaFoto();
            await this.mostrarFoto();
        },
        verificaLocalStore() {
            this.usuarioLogado = JSON.parse(localStorage.getItem("usuarioLogado"));
           
        },
        mostrarFoto() {
            document.querySelector("#photo-carregada").src = "data:image/gif;base64," + this.usuarioLogado.foto;
        },
        async ativarCarregaFoto() {
            console.log("Oi")
            const photoFile = document.getElementById('photo-file');

            document.getElementById('select-image').onclick = await function () {
                photoFile.click()
            }

            await window.addEventListener('DOMContentLoaded', () => {
                photoFile.addEventListener('change', () => {
                    let file = photoFile.files.item(0)

                    // ler um arquivo
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function (event) {
                        let image = document.getElementById('photo-carregada')
                        image.src = event.target.result

                      

                    }
                })
            })
        },
        salvarFoto() {
            let dataForm = new FormData();
            for (let file of this.$refs.files.files) {
                dataForm.append(`foto`, file);
            }
            usuarioService.alterarFoto(1, 1, dataForm).then((res) => {
                console.log(res)

            }).catch((erro) => {
                console.log(erro)
            });
        },
    }
}
</script>
  <style scoped>
  #photo-file {
      display: none;
  }
  
  #photo-carregada {
      margin: .8rem 0;
  
  }
  
  .perfil {
      border-radius: 50%;
  }
  </style>