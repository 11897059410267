import axios from "axios";

export const idiomaService = {
  salvarIdioma(idioma) {
    return new Promise((resolve, reject) => {
    
      axios
        .post(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/idioma`,
          idioma,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  alterarIdioma(id, idioma) {
    return new Promise((resolve, reject) => {
    
      axios
        .put(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/idioma/${id}`,
          idioma,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  listarIdioma(idIdioma) {
    return new Promise((resolve, reject) => {
     
      axios
        .get(
          `${process.env.VUE_APP_URL_BASE_BACK_END}/idioma/listar/${idIdioma}`,
          
        )
        .then((ret) => {
          resolve(ret.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
