import Vue from "vue"
import VueRouter from "vue-router"


import Inicio from '@/pages/Inicio'
import LoginUsuario from '@/pages/LoginUsuario'
import FeriasServidor from '@/pages/FeriasServidor'
import AbonoServidor from '@/pages/AbonoServidor'
import AfastamentoServidor from '@/pages/AfastamentoServidor'
import InfoPessoais from '@/pages/DadosPessoais'
import InformacoesFuncionais from '@/pages/InformacoesFuncionais'
import Diretoria from '@/pages/Diretoria'
import Gerencia from '@/pages/Gerencia'
import Nucleo from '@/pages/Nucleo'
import Setor from '@/pages/Setor'
import InstituicaoEnsino from '@/pages/InstituicaoEnsino'
import Curso from '@/pages/Curso'
import PreCadastroServidor from '@/pages/PreCadastroServidor'
import AprovacaoCadastro from '@/pages/AprovacaoCadastro'
import AlterarSenha from '@/pages/AlterarSenha'
import AlterarFoto from '@/pages/AlterarFoto'
import Origem from '@/pages/Origem' 
import Carreira from '@/pages/Carreira' 
import Cargo from '@/pages/Cargo' 
import Idioma from '@/pages/Idioma' 
import ExperienciaProfissional from '@/pages/ExperienciaProfissional' 
import Agravo from '@/pages/Agravo' 
import RegionalSaude from '@/pages/RegionalSaude' 
import Publicacao from '@/pages/Publicacao' 
import RegiaoAdministrativa from '@/pages/RegiaoAdministrativa' 
import Praticas from '@/pages/Praticas' 
import DonsArtisticos from '@/pages/DonsArtisticos'
import Competencia from '@/pages/Competencia' 
import Visita from '@/pages/Visita' 
import Preceptoria from '@/pages/Preceptoria' 
import Evento from '@/pages/Evento' 
import Mentoria from '@/pages/Mentoria' 
import Projeto from '@/pages/Projeto' 
import RelatorioVisitaTecnica from '@/pages/RelatorioVisitaTecnica' 


 

Vue.use(VueRouter)

export default new VueRouter({
    mode: "history",
    routes: [
        {
            path: '/inicio',
            name:"inicio",
            component: Inicio,
           
        },
        {
            path: '/',
            name:"login",
            component: LoginUsuario,
          
        },
        {
            path: '/ferias',
            name:'ferias',
            component: FeriasServidor,
            
        },
        {
            path:'/abono',
            name:'abono',
            component: AbonoServidor
        },
        {
            path:'/afastamento',
            name:'afastamento',
            component: AfastamentoServidor
        },
        {
            path:'/infoPessoais',
            name:'infoPessoais',
            component: InfoPessoais
        },
        {
            path:'/informacoesFuncionais',
            component: InformacoesFuncionais
        },
        {
            path:'/diretoria',
            component: Diretoria
        },
        {
            path:'/gerencia',
            component: Gerencia,
            props:true
        },
        {
            path:'/nucleo',
            component: Nucleo
        },
        {
            path:'/setor',
            component: Setor
        },
        {
            path:'/instituicao',
            component: InstituicaoEnsino
        },
        {
            path:'/curso',
            component: Curso
        },    
        {
            path:'/preCadastro',
            name:'PreCadastroServidor',
            component: PreCadastroServidor
        }, 
        { 
            path:'/aprovacaoCadastro',
            component: AprovacaoCadastro
        }, 
        { 
            path:'/alterarSenha',
            component: AlterarSenha
        }, 
        { 
            path:'/alterarFoto',
            component: AlterarFoto
        }, 
        { 
            path:'/origem',
            component: Origem
        }, 
        { 
            path:'/carreira',
            component: Carreira
        }, 
        { 
            path:'/cargo',
            component: Cargo
        },
        { 
            path:'/idioma',
            component: Idioma
        },
        { 
            path:'/experienciaProfissional',
            component: ExperienciaProfissional
        },
        { 
            path:'/agravo',
            component: Agravo
        },
        { 
            path:'/regionalSaude',
            component: RegionalSaude
        },
        { 
            path:'/publicacao',
            component: Publicacao
        },
        { 
            path:'/regiaoAdministrativa',
            component: RegiaoAdministrativa
        },
        { 
            path:'/praticas',
            component: Praticas
        },
        { 
            path:'/donsArtisticos',
            component: DonsArtisticos
        },
        { 
            path:'/competencia',
            component: Competencia
        },
        { 
            path:'/visita',
            component: Visita
        }, 
        { 
            path:'/preceptoria',
            component: Preceptoria
        }, 
        { 
            path:'/evento',
            component: Evento
        }, 
        { 
            path:'/mentoria',
            component: Mentoria
        }, 
        { 
            path:'/projeto',
            component: Projeto
        }, 
        { 
            path:'/relatorioVisitaTecnica',
            component: RelatorioVisitaTecnica
        }, 
        
        
      
    ]
  })

